import React from "react";
import {Form} from "educat-common-web";
import {registrationFormConfig} from "./registrationFormConfig";
import {MentorRegistrationSteps} from "../../../../../service/mentorRegistrationService";


interface IConnectedFormRegistrationInformationProps {
}

interface IExternalFormRegistrationInformationProps {
    readonly mentor: any;
    readonly onFormUpdate: any;
    readonly updateValidation: any;
}

interface IFormRegistrationInformationProps extends IConnectedFormRegistrationInformationProps, IExternalFormRegistrationInformationProps {
}

interface IFormRegistrationInformationState {
    stepValue: any;
    stepName: string;
    isStepValid: boolean;
}

class FormRegistrationInformation extends React.Component<IFormRegistrationInformationProps, IFormRegistrationInformationState> {

    constructor(props: any) {
        super(props);
        this.state = {
            stepValue: null,
            stepName: 'registration',
            isStepValid: true
        }
    }

    componentDidMount() {
        this.setState({stepValue: this.state.stepValue})
    }

    render() {
        return <Form
            config={registrationFormConfig(this.props.mentor.account)}
            onValueStateChange={this.formChangeHandler}
            controlName={this.state.stepName}
            value={this.state.stepValue}
            onValidationStateChange={this.formValidityChange}
        />;
        // submitForm={this.register}
    }

    private formChangeHandler = (controlName: MentorRegistrationSteps, value: any, changeType: string) => {
        this.setState({stepName: controlName, stepValue: value});
        this.props.onFormUpdate(value, 'registration_information');
    };

    private formValidityChange = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
            this.props.updateValidation(isValid);
        }
    };

}

export default FormRegistrationInformation;