import React from "react";
import App from "../App";
import {Route} from "react-router";
import PanelHost from "../components/PanelHost";
import {NotFound, PrivateRoute} from "educat-common-web";
import {AuthPanel} from "educat-common-web";
import {UserRole} from "educat-common-web";
import {loginFormConfig} from "./loginFormConfig";

const routes = [
    <Route path="/" component={App} exact key="home" />,
    <PrivateRoute path="/panel/" component={() => <PanelHost envData={process.env} />} key="panel" />,
    <Route path="/auth/:type"
           component={() => <AuthPanel formConfig={{login: loginFormConfig}}
                                       envData={process.env}
                                       userRole={UserRole.ADMIN}/>}

           key="auth"
    />,
    <Route key="not-found" component={NotFound} />
];
export default routes;
