import {authTokenSelector, Loader, refreshTokenSelector, Translation} from "educat-common-web";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import styles from "./styles.module.scss";
import {MentorProps, MentorStatus} from "../../../../constants/MentorStatus";
import {UserActiveStatus, UserProps} from "../../../../constants/UserActiveStatus";
import {getAccountAccessAPI} from "../../../../api/getAccountAccess";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";

interface IApplicantsTableConnectedProps {
    refreshToken: string;
    authToken: string;
}

interface IApplicantsTableExternalProps {
    mentors: any[];
    isProcessing: boolean;
    acceptMentorRegistration: (mentorId: string) => void;
    acceptMentorOnboarding: (mentorId: string) => void;
    envData: any;
}

interface IApplicantsTableProps extends IApplicantsTableConnectedProps,
    IApplicantsTableExternalProps,
    WithTranslation {
}



class MentorsTable extends React.Component<IApplicantsTableProps> {

    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IApplicantsTableProps) {
        super(props);

        fixInjectedProperties(this);
    }

    render() {
        if ((!this.props.mentors || !Array.isArray(this.props.mentors)) ||
            (this.props.mentors.length === 0 && !this.props.isProcessing)) {
            return <p>
                <Translation text={'mentors.mentorList.table.noData'}/>
            </p>;
        }


        return (
            <React.Fragment>
                <Loader showLoader={this.props.isProcessing}/>
                <table className="data-table default-table">
                    <thead>
                    <tr>
                        <th className="highlight-cell">
                            <Translation text={'mentors.mentorList.table.mentorName'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'mentors.mentorList.table.contact'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'mentors.mentorList.table.status'}/>
                        </th>
                        <th className="highlight-cell w-25">
                            <Translation text={'mentors.mentorList.table.actionButtons.title'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    private renderTableRows() {
        const rows: any[] = [];

        this.props.mentors.map((item: any) => {
            const activeStatus = UserProps.getStatus(item?.account?.user);
            const status = activeStatus === UserActiveStatus.deactivated
                ? 'mentorDeactivated'
                : MentorProps.getStatus(item);
            return rows.push((
                <tr key={item.id}>
                    <td className="highlight-cell">
                        <div className={styles.aHref} onClick={()=>this.enterAccount(item.account)}>
                            {item.account.firstName} {item.account.lastName}
                        </div>
                    </td>
                    <td>
                        <div>{item.account.user.login}</div>
                        <div className="">{item.account.phone}</div>
                    </td>
                    <td>
                        <span className={`badge ${status}`}>
                            <Translation text={`mentors.status.${status}`}/>
                        </span>
                    </td>
                    <td className="borderless">
                        <div className={styles.buttonWrapper + ' w-100'}>

                            { this._renderRegistrationAcceptBtn(item) }

                            { this._renderOnboardingAcceptBtn(item) }

                            <Link  to={`/panel/mentors/${item.id}`}
                                    className="btn btn-theme btn-small w-100">
                                <Translation text={'mentors.mentorList.table.actionButtons.details'}/>

                            </Link>
                        </div>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private _renderRegistrationAcceptBtn(item: any) {
        return (
            <button type="button"
                    className="btn btn-theme btn-small w-100 mb-1"
                    disabled={MentorProps.getStatus(item) !== MentorStatus.registrationSurveyApproval}
                    onClick={() => this.props.acceptMentorRegistration(item.id)}>
                <Translation text={'mentors.mentorList.table.actionButtons.acceptRegistration'}/>

            </button>
        )
    }

    private _renderOnboardingAcceptBtn(item: any) {
        return (
            <button type="button"
                    className="btn btn-theme btn-small w-100 mb-1"
                    disabled={MentorProps.getStatus(item) !== MentorStatus.onboardingSurveyApproval}
                    onClick={() => this.props.acceptMentorOnboarding(item.id)}>
                <Translation text={'mentors.mentorList.table.actionButtons.acceptOnboarding'}/>

            </button>
        )
    }


    private enterAccount(account: any) {
        if (!account?.user?.active) {
            this.alertManager.addAlert('Aby wejść w tryb impersonifikacji, użytkownik musi być aktywny.');
            return;
        }
        getAccountAccessAPI(this.props.authToken, account.id).subscribe((resp:any)=>{
            console.log(resp)
            const accessedAccountToken = resp.refreshToken;
            const adminToken = this.props.refreshToken;
            window.location.replace(this.props.envData.REACT_APP_MENTOR_URL + `/auth/login/${accessedAccountToken}/${adminToken}`);
        })
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        refreshToken: refreshTokenSelector(state)
    })
)(withTranslation()(MentorsTable));
