import React from "react";
import {authTokenSelector, Form, Loader, Translation} from "educat-common-web";
import {
    mentorOnboardingInformationFormConfig
} from "./mentorOnboardingInformationFormConfig";
import {
    IOnboardingHelperService,
    MentorOnboardingSteps
} from "../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";
import {sendMentorOnboardingSurveyAPI} from "../../../../api/sendMentorOnboardingSurvey";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {MentorAccount} from "../../../../service/mentorRegistrationService";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {RootState} from "../../../../store/reducers";
import {connect} from "react-redux";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import styles from "../../../Shared/UserListStyles/styles.module.scss";

class OnboardingInformationEditor extends React.Component<any, any>{

    private readonly subscriptions: Subscription[] = [];
    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            formConfig: mentorOnboardingInformationFormConfig(this.props.stepData),
            stepValue: null,
            stepName: MentorOnboardingSteps.ONBOARDING_INFORMATION,
        };
        fixInjectedProperties(this);
    }


    render() {

        return <div className="d-flex flex-column">
            <Loader showLoader={this.state.isProcessing}/>


            <div className="d-flex justify-content-between fs-2 fw-bold mb-5">
                <h2 className={styles.title}>
                    <Translation text={'mentors.mentorView.onboardingInformation.title'} />
                </h2>
            </div>

            <Form
                config={mentorOnboardingInformationFormConfig(this.props.mentor.mentorOnboarding)}
                onValueStateChange={this.formChangeHandler}
                onValidationStateChange={this.formValidityChange}
                value={this.state.stepValue}
            />
            <button
                type="submit"
                disabled={!this.state.isStepValid}
                onClick={() => this.updateMentor()}
                className="btn btn-theme btn-rounded align-self-end"
            >
                <Translation text="buttons.save"/>
            </button>
        </div>

    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any, changeType: string) => {
        if (changeType !== 'init') {
            this.setState({stepName: controlName, stepValue: value});
        }
    };

    updateMentor(){
        const mentorSurveyPayload = this.onboardingHelperService.convertRawDataToPayload(
                this.state.stepValue,
                this.props.mentor.id,
                MentorOnboardingSteps.ONBOARDING_INFORMATION,
                {mentorFee: this.props.mentor.feePercent, trialFee: this.props.trialFee},
            ),
            mentorId = this.props.mentor.id;
        this.sendOnboardingData(mentorId, mentorSurveyPayload);
    }

    private sendOnboardingData = (mentorId: string, mentorSurveyPayload: any): void => {
        this.setState({isProcessing: true});

        this.subscriptions.push(
            sendMentorOnboardingSurveyAPI(this.props.authToken, mentorId, mentorSurveyPayload)
                .pipe(
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.onboardingSendingFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: MentorAccount) => {
                        if (response) {
                            this.setState({
                                isProcessing: false,
                                currentStep: MentorOnboardingSteps.THANK_YOU,
                            });
                        }
                    })
                )
                .subscribe({
                    next: (res: any) => {
                        this.alertManager?.addAlert('alerts.saveSuccessful');
                        this.props.onFinish(res);
                    },
                    error: () => {}

                })
        );
    };

    private formValidityChange = (controlName: string, isValid: boolean, errorMessages: ReadonlyArray<string>) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };

}

export default connect(
    (state: RootState) => ({
        // account: accountSelector(state),
        authToken: authTokenSelector(state),
    }),
    {}
)(OnboardingInformationEditor);