import React from 'react';
import './App.scss';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';


interface IAppProps extends RouteComponentProps {
}

interface IAppState {
    isAuthenticated: boolean;
}

class App extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps) {
        super(props);
        this.state ={
            isAuthenticated: true
        }
    }

    render() {
        const path = this.state.isAuthenticated ? '/panel/mentors' : '/auth/login';

        return (
            <div className="App">
                <Redirect push to={path}/>
            </div>
        );
    }
}

export default withRouter(App);
