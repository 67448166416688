import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {MentorServiceDefinitionPayload} from "../components/OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";


export function updateMentorServiceDefinitionsAPI(authToken: string | null, payload: MentorServiceDefinitionPayload, mentorServiceDefinitionId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `mentor_service_definitions/${mentorServiceDefinitionId}`,
        payload,
        new RestQueryParams(),
        headers
    );
}
