const navigation = {
    items: [
        {
            id: 'Dashboard',
            title: 'Insurance Cover',
            type: 'group',
            icon: '',
            children: [
                {
                    id: 'applicants',
                    title: 'sidebar.applicants',
                    type: 'item',
                    url: '/panel/applicants',
                    icon: 'menu-icon user',
                },
                {
                    id: 'mentors',
                    title: 'sidebar.mentors',
                    type: 'item',
                    url: '/panel/mentors',
                    icon: 'menu-icon file-text',
                },
                {
                    id: 'tutors',
                    title: 'sidebar.tutors',
                    type: 'item',
                    url: '/panel/tutors',
                    icon: 'menu-icon book',
                },
                {
                    id: 'sales',
                    title: 'sidebar.sales',
                    type: 'item',
                    url: '/panel/sales',
                    icon: 'menu-icon sales',
                },
                {
                    id: 'pricing',
                    title: 'sidebar.pricing',
                    type: 'item',
                    url: '/panel/pricing',
                    icon: 'menu-icon pricing',
                },
                {
                    id: 'insurances',
                    title: 'sidebar.inquiries',
                    type: 'item',
                    url: '/panel/insurances',
                    icon: 'menu-icon insurances',
                },
            ]
        },
    ]
};
export default navigation;
