import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from "educat-common-web";

export interface InquiryValues {
    [key: string]: boolean;
}

export interface ISettleInquiryPayload {
    values: InquiryValues;
}

export function settleMultipleInquiriesAPI(authToken: string | null, payload: ISettleInquiryPayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.put(
        `inquiries/batch-update-settled`,
        payload,
        new RestQueryParams(),
        headers
    );
}