import {isNotEmpty, Translation} from 'educat-common-web';
import React from 'react';
import styles from '../UserListStyles/styles.module.scss';

interface IExternalInformationRowProps {
    readonly keyName: string;
    readonly value: any;
    readonly keyNameOption?: any;
    readonly translateValue?: boolean;
}

interface IInformationRowProps extends IExternalInformationRowProps {
}

class InformationRow extends React.Component<IInformationRowProps> {

    render() {
        return (
            <tr>
                <td className="data-table-title">
                    <Translation text={this.props.keyName} config={this.props.keyNameOption}/>
                </td>
                <td>
                    {isNotEmpty(this.props.value) ?
                        this.props.translateValue
                            ? <Translation text={this.props.value}/>
                            : this.props.value
                        :
                        <p className={styles.notSelected}>
                            <Translation text="mentors.mentorView.generalInformation.notSelected"/>
                        </p>
                    }
                </td>
            </tr>
        );
    }
}

export default InformationRow;
