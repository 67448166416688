import {authTokenSelector, CustomCard, Translation} from 'educat-common-web';
import React from 'react';
import styles from "../../../Shared/UserListStyles/styles.module.scss";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {AlertType, IAlertManagerService} from "../../../../service/alertManagerService";
import {UserActiveStatus, UserProps} from "../../../../constants/UserActiveStatus";
import {updateUserActiveStatusAPI} from "../../../../api/updateUserActiveStatus";


interface IExternalAccountSettingsProps {
    readonly isLoading: boolean;
    readonly mentor: any;
    readonly reloadUserData: any;
}

interface IAccountSettingsProps extends IExternalAccountSettingsProps {
    authToken: any;
}

interface IAccountSettingsState {
    isProcessing: boolean;
}


class AccountSettings extends React.Component<IAccountSettingsProps, IAccountSettingsState> {

    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            isProcessing: false
        }
        fixInjectedProperties(this);

    }

    render() {
        const mentor = this.props.mentor
        return (
            <CustomCard showLocalLoader={this.props.isLoading || this.state.isProcessing}>
                <CustomCard.Header>
                    <div className={'d-flex justify-content-between'}>
                        <h2 className={`d-inline ${styles.title}`}>
                            <Translation text={'mentors.mentorView.accountSettings.title'} />
                        </h2>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div>
                        <button type="button"
                                className="btn btn-theme-arrowless btn-small mb-lg-auto position-relative w-100"
                                onClick={() => this.changeUserAccountStatus()}
                        >
                            <Translation text={UserProps.getStatus(mentor?.account?.user) === UserActiveStatus.active
                                ? 'buttons.deactivate'
                                : 'buttons.activate'}/>
                        </button>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );

    }


    private changeUserAccountStatus() {
        this.setState({isProcessing: true})
        updateUserActiveStatusAPI(this.props.authToken, this.props.mentor.account.user.id)
            .subscribe({
                next: () => {
                    this.alertManager?.addAlert('alerts.saveSuccessful');
                    this.props.reloadUserData();
                    this.setState({isProcessing: false})
                },
                error: (err) => {
                    if (err.status === 405){
                        this.setState({isProcessing: false})
                        this.alertManager?.addAlert(
                            'Inne konto z tym samym adresem email jest już aktywne. W celu aktywacji tego konta najpierw dezaktywuj drugie konto.',
                            AlertType.ERROR, null, false);
                    }

                }
            })
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {}
)(AccountSettings);
