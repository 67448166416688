import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {BehaviorSubject, forkJoin, Observable, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    changeBreadcrumbs, deepCloneObject, getLanguagesAPI,
    getMentorTaskTypesAPI
} from 'educat-common-web';
import {catchError, filter, map, tap} from "rxjs/operators";
import GeneralInformation from './GeneralInformation';
import OnboardingInformation from './OnboardingInformation';
import AccountImage from '../../Shared/AccountImage';
import PlatformPrices from './PlatformPrices';
import Modal, {Styles} from 'react-modal';
import GeneralInformationEditor from "./GeneralInformationEditor";
import OnboardingInformationEditor from "./OnboardingInformationEditor";
import {getRealmsAPI} from "../../../api/getRealms";
import {getCountriesAPI} from "../../../api/getCountries";
import {getTutoringScopeTypesAPI} from "../../../api/getTutoringScopeTypes";
import {TutoringSubject} from "../../../api/provider/educatTutoringScopeTypeAPI";
import {TaskTypeList} from "../../../service/mentorRegistrationService";
import {WithTranslation, withTranslation} from "react-i18next";
import AccountImageEditor from "./AccountImageEditor";
import PlatformPricesEditor from "./PlatformPricesEditor";
import {getServiceApplicationElementsAPI} from "../../../api/getServiceApplicationElements";
import {getServiceApplicationPackagesAPI} from "../../../api/getServiceApplicationPackages";
import {getServiceConsultationPackagesAPI} from "../../../api/getServiceConsultationPackages";
import {getServiceConsultationsAPI} from "../../../api/getServiceConsultations";
import {CalculatedPrices} from "../../../api/provider/educatCalculatedPricesAPI";
import {
    IOnboardingHelperService,
    ServiceApplicationPackage
} from "../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";
import {getMentorAPI} from "../../../api/getMentor";
import AccountSettings from "./AccountSettings";
import {getTrialMarginAPI} from "../../../api/getTrialMargin";


interface MatchProcessParams {
    id?: string;
}

interface IConnectedMentorsProps {
    readonly authToken: string;
}

interface IMentorViewProps extends
    IConnectedMentorsProps,
    RouteComponentProps<MatchProcessParams>,
    WithTranslation {
}

interface IMentorViewState {
    mentor: any;
    trialFee: any;
    isProcessing: boolean;
    isModalOpen: boolean;
    modalType: string;
    countryList: { [key: string]: any }[];
    schoolList: { [key: string]: any }[];
    realmList: { [key: string]: any }[];
    fieldOfStudyList: { [key: string]: any }[];
    tutoringScopesList: {
        multiselectOptions: { [key: string]: any }[];
        subjectsArray: TutoringSubject[];
    };
    languageList: { [key: string]: any }[];
    taskTypeList: TaskTypeList;

    serviceApplicationElementsList: any[];
    serviceAllApplicationElementsList: any[];
    serviceApplicationPackagesList: any[];
    serviceConsultationPackagesList: any[];
    freeServiceConsultationPackageList: any[];
    serviceConsultationsList: any[];
    calculatedPackagePrices: CalculatedPrices;
}


class MentorView extends React.Component<IMentorViewProps, IMentorViewState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;
    private mentorAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(props: IMentorViewProps) {
        super(props);

        this.state = {
            mentor: null,
            trialFee: 0,
            isProcessing: true,
            isModalOpen: false,
            modalType: '',
            taskTypeList: { taskTypeGroups: [], taskTypesWithoutGroups: [] },
            countryList: [],
            schoolList: [],
            realmList: [],
            fieldOfStudyList: [],
            tutoringScopesList: { multiselectOptions: [], subjectsArray: [] },
            languageList: [],

            serviceAllApplicationElementsList: [],
            serviceApplicationElementsList: [],
            serviceApplicationPackagesList: [],
            serviceConsultationPackagesList: [],
            serviceConsultationsList: [],
            freeServiceConsultationPackageList: [],
            calculatedPackagePrices: {serviceApplicationPackagePrices:[], serviceConsultationPackagePrices:[]}
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getMentor();
        this.fetchMentorData();
        getTrialMarginAPI(this.props.authToken).pipe(
            tap((result: any) => {
                this.setState({trialFee: result['hydra:member'].join()})
            })
        ).subscribe();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    closeModal(){
        this.setState({isModalOpen: false, modalType: ''});
    }

    openModal(modalType: string){
        this.setState({isModalOpen: true, modalType: modalType});
    }

    private _renderDataEditForm() {
        if (this.state.modalType === 'general'){
            return <GeneralInformationEditor
                taskTypeList={this.state.taskTypeList}
                mentor={this.state.mentor}
                tutoringScopesList={this.state.tutoringScopesList}
                languageList={this.state.languageList}
                countryList={this.state.countryList}
                realmList={this.state.realmList}
                authToken={this.props.authToken}
                serviceApplicationElementsList={this.state.serviceAllApplicationElementsList}
                serviceConsultationsList={this.state.serviceConsultationsList}
            ></GeneralInformationEditor>
        }
        if (this.state.modalType === 'onboarding'){
            return <OnboardingInformationEditor
                mentor={this.state.mentor}
                onFinish={(mentorData:any)=>{this.closeModal(); this.setState({mentor: mentorData})}}
            />
        }
        if (this.state.modalType === 'pricing'){
            return <PlatformPricesEditor
                serviceApplicationElementsList={this.state.serviceApplicationElementsList}
                serviceApplicationPackagesList={this.state.serviceApplicationPackagesList}
                serviceConsultationPackagesList={this.state.serviceConsultationPackagesList}
                serviceConsultationsList={this.state.serviceConsultationsList}
                freeServiceConsultationPackageList={this.state.freeServiceConsultationPackageList}
                mentor={this.state.mentor}
                trialFee={this.state.trialFee}
                onFinish={(mentorData:any)=>{this.closeModal(); this.setState({mentor: mentorData})}}
                authToken={this.props.authToken}
                reloadUserData={()=>{this.getUserData()}}
            />
        }
        if (this.state.modalType === 'image'){
            return <AccountImageEditor
                mentor={this.state.mentor}
                onFinish={(mentorData:any)=>{this.closeModal(); this.setState({mentor: mentorData})}}
            />
        }
    }

    render() {
        return (
            <>
                <section className={`row`}>
                    <div className="col-7">
                        <GeneralInformation isLoading={this.state.isProcessing} mentor={this.state.mentor}
                                            modalTrigger={()=>this.openModal('general')} />
                        <OnboardingInformation isLoading={this.state.isProcessing} mentor={this.state.mentor}
                                               modalTrigger={()=>this.openModal('onboarding')} />
                    </div>
                    <div className="col-5">
                        <AccountSettings isLoading={this.state.isProcessing} mentor={this.state.mentor}
                                         reloadUserData={()=>{this.getUserData()}}/>
                        <AccountImage type={'largeThumb'} account={this.state.mentor?.account} isMentor={true}
                                      mentor={this.state.mentor} modalTrigger={()=>this.openModal('image')}/>
                        <PlatformPrices isLoading={this.state.isProcessing} mentor={this.state.mentor}
                                        services={this.state.mentor?.mentorServiceDefinitions}
                                        modalTrigger={()=>this.openModal('pricing')}
                                        serviceApplicationElementsList={this.state.serviceApplicationElementsList}
                        />
                    </div>
                </section>
                <Modal isOpen={this.state.isModalOpen} onRequestClose={() => this.closeModal()}
                       style={this.styles()}
                >
                    {this._renderDataEditForm()}
                </Modal>
            </>
        );
    }

    private styles() : Styles {
        let height = this.state.modalType === 'general' || this.state.modalType === 'pricing' ? 'auto' : 'fit-content';
        return {
            content: {justifyContent: "center", display:'flex', flexDirection:'column', height: height, width:'70%', marginLeft:'auto', marginRight:'auto'}
        };
    }



    private getMentor = () => {
        const mentorId = this.props.match.params.id;
        return this.subscriptions.push(
            getMentorAPI(mentorId, this.props.authToken).pipe(
                map((resp: any) => {
                    if (resp) {
                        this.setState({
                            mentor: resp
                        });
                    }
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of(error);
                })
            ).subscribe(()=> {
                this.mentorAvailable.next(true)
            })
        )
    };


    private fetchMentorData() {
        const mentorServiceDataArray: any[] = [
            this.retrieveListData(getServiceApplicationElementsAPI(this.props.authToken),'serviceApplicationElementsList', true),
            this.retrieveListData(getServiceApplicationPackagesAPI(this.props.authToken),'serviceApplicationPackagesList', true),
            this.retrieveListData(getServiceConsultationPackagesAPI(this.props.authToken),'serviceConsultationPackagesList', true),
            this.retrieveListData(getServiceConsultationsAPI(this.props.authToken), 'serviceConsultationsList', true),
            this.retrieveListData(getRealmsAPI(this.props.authToken), "realmList", false),
            this.retrieveListData(getCountriesAPI(this.props.authToken), "countryList", false),
            this.retrieveListData(getLanguagesAPI(this.props.authToken), "languageList", false),
            this.retrieveTutoringScopeListData(getTutoringScopeTypesAPI(this.props.authToken)),
            this.retrieveTaskTypeListData(getMentorTaskTypesAPI(this.props.authToken)),
        ];
        this.setState({ isProcessing: true });
        this.subscriptions.push(
            forkJoin(mentorServiceDataArray)
                .pipe(
                    tap(() => {
                        this.setState({ isProcessing: false });
                        this.subscriptions.push(this.mentorAvailable.subscribe(
                            (v) => {
                                if (v) this.attachMentorServiceDefinitionDataToList()
                            }));
                    })
                )
                .subscribe()
        );
    }

    private retrieveListData(api: Observable<any>, listName: string, isMentorServiceData: boolean) {
        const {t} = this.props;
        return api.pipe(
            filter((response) => !!response),
            tap((resp: any) => {
                if (resp["hydra:member"]) {
                    if (isMentorServiceData){
                        let dataList = resp['hydra:member'];
                        const updatedState: any = {};
                        if (listName === 'serviceApplicationPackagesList') {
                            updatedState['freeServiceConsultationPackageList'] = dataList.filter((dataItem: ServiceApplicationPackage) => dataItem.free);
                            dataList = dataList.filter((dataItem: ServiceApplicationPackage) => !dataItem.free);
                        }
                        updatedState[listName] = dataList;
                        this.setState(updatedState);

                    } else {
                        const multiselectOptions: { [key: string]: any }[] = (resp["hydra:member"] || []).map(
                            (option: { [key: string]: any }) => {
                                let label = option.name ? option.name : option.id;
                                if (listName === 'countryList' || listName === 'languageList') {
                                    label = t(`${listName === 'countryList' ? 'country' : 'language'}.${option.id}`);
                                }
                                return ({
                                    value: option.id,
                                    label: label,
                                })}
                        );
                        let updatedState = deepCloneObject(this.state);
                        updatedState[listName] = multiselectOptions;
                        this.setState(updatedState);
                    }

                }
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                return of();
            })
        );
    }

    private retrieveTutoringScopeListData(api: Observable<any>) {
        return api.pipe(
            filter((response) => !!response),
            tap((resp: TutoringSubject[]) => {
                const multiselectOptions: { [key: string]: any }[] = (resp || []).map(
                    (option: { [key: string]: any }) => ({
                        value: option.id,
                        label: option.name ? option.name : option.id,
                    })
                );
                let updatedState = deepCloneObject(this.state);
                updatedState["tutoringScopesList"] = {
                    multiselectOptions: multiselectOptions,
                    subjectsArray: resp,
                };

                this.setState(updatedState);
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                return of();
            })
        );
    }

    private retrieveTaskTypeListData(api: Observable<any>) {
        return api.pipe(
            filter((response) => !!response),
            tap((resp: any) => {
                if (resp["hydra:member"]) {
                    const response = resp["hydra:member"];
                    let mappedTaskTypeList = deepCloneObject(response),
                        taskTypeGroups: any[] = [],
                        taskTypesWithoutGroup: any[] = [];
                    mappedTaskTypeList = mappedTaskTypeList.filter((taskType: any) => taskType.for !== "scholar");
                    mappedTaskTypeList.forEach((taskType: any) => {
                        if (taskType.mentorTaskTypeGroup !== null) {
                            if (
                                !taskTypeGroups.some(
                                    (taskTypeGroup: any) => taskTypeGroup.id === taskType.mentorTaskTypeGroup.id
                                )
                            ) {
                                const taskTypeGroup = {
                                    name: taskType.mentorTaskTypeGroup.name,
                                    id: taskType.mentorTaskTypeGroup.id,
                                    taskTypes: [],
                                };
                                taskTypeGroups.push(taskTypeGroup);
                            }
                        }
                    });
                    taskTypeGroups.forEach((taskTypeGroup: any) => {
                        mappedTaskTypeList.forEach((taskType: any) => {
                            const taskTypeItem = {
                                id: taskType.id,
                                name: taskType.name,
                            };
                            if (taskType.mentorTaskTypeGroup?.id === taskTypeGroup.id) {
                                taskTypeGroup.taskTypes.push(taskTypeItem);
                            }
                            if (
                                !taskType.mentorTaskTypeGroup &&
                                !taskTypesWithoutGroup.includes((taskType: any) => taskType.id === taskTypeItem.id)
                            ) {
                                taskTypesWithoutGroup.push(taskTypeItem);
                            }
                        });
                    });

                    this.setState({
                        taskTypeList: {
                            taskTypeGroups: taskTypeGroups,
                            taskTypesWithoutGroups: taskTypesWithoutGroup,
                        },
                    });
                }
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                return of();
            })
        );
    }


    private getUserData() {
        if (!this.props.authToken || !this.state.mentor.id) {
            return;
        }
        this.subscriptions.push(
            getMentorAPI(this.state.mentor.id, this.props.authToken)
                .pipe(
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.registration.mentor.alerts.fetchMentorError');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: any) => {
                        if (response) {
                            this.setMentorData(response);
                        }
                    })
                )
                .subscribe()
        );
    }

    private setMentorData(mentor: any) {
        this.setState({mentor: mentor});
    }

    private setMentorAccountData(accountData: any){
        let mentor = this.state.mentor;
        mentor.account = accountData;
    }

    private attachMentorServiceDefinitionDataToList() {

        const updatedConsultationsList = this.getUpdatedList(this.state.serviceConsultationsList);
        const updatedConsultationsPackageList = this.getUpdatedList(this.state.serviceConsultationPackagesList);
        const updatedAppElementsList = this.getUpdatedList(this.state.serviceApplicationElementsList);
        const updatedApplicationPackageList = this.getUpdatedList(this.state.serviceApplicationPackagesList);
        const yetUpdatedAppElementsList = this.onboardingHelperService.filterServiceApplicationElements(updatedAppElementsList, this.state.mentor.mentorTaskTypes);

        this.setState({
            serviceAllApplicationElementsList: updatedAppElementsList,
            serviceConsultationsList: updatedConsultationsList,
            serviceApplicationElementsList: yetUpdatedAppElementsList,
            serviceApplicationPackagesList: updatedApplicationPackageList,
            serviceConsultationPackagesList: updatedConsultationsPackageList
        })
    }

    private getUpdatedList(list: any[]) {
        return list.map((consultation: any) => {
            const mentorServiceDefinitionId = this.state.mentor.mentorServiceDefinitions.find((def: any) => {
                const serviceKeys = Object.keys(def).filter(key => key.startsWith('service'));
                for (const serviceKey of serviceKeys) {
                    if (def[serviceKey]?.id === consultation.id) {
                        return true;
                    }
                }
                return false;
            })?.id;

            consultation.mentorServiceDefinitionId = mentorServiceDefinitionId;
            return consultation;
        });
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withTranslation()(withRouter(MentorView)));
