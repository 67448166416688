import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    InputType,
    MultiselectMenuPlacement,
    MultiSelectType,
    ValidationRules,
} from 'educat-common-web';
import {TFunction} from 'react-i18next';
import {IMentorStudyInformationFormInstanceConfig} from './types';


export const mentorStudyInformationFormConfig = (translate: TFunction,
                                                 instancesData: {[key: string]: IMentorStudyInformationFormInstanceConfig}[] = [],
                                                 countryList: any[] = [],
                                                 value?: any,
                                                 onRemove?: (index: number) => void,
                                                 handleMultiselectInputChange?: any): typeof IFormConfig => {
    return {
        controlType: 'form',
        class: 'onboarding-form',
        touched: true,
        controls: [
            {
                key: `studyFieldNotFound`,
                hostClass: 'row row-study-information col-xl-6',
                controlType: 'control',
                defaultValue: false,
                formControlType: FormControlType.CHECKBOX,
                validationRules: [],
                placeholder: '',
                label: 'mentorOnboarding.registration.study_information.formControls.studyFieldNotFound',
                isLabelHidden: false,
                isCheckboxLabelRaw: false,
                checkboxLabel: ``,
            },
            ...instancesData.map((instanceData, i) => {
                const instanceValue = value?.[`study_information_${i}`];

                return formInstance(translate, i, countryList, instanceData, instanceValue, onRemove, handleMultiselectInputChange);
            }),
        ],
    };
};

const formInstance = (
    translate: TFunction,
    index: number, countryList: any[],
    instanceData: {[key: string]: IMentorStudyInformationFormInstanceConfig},
    value: any,
    onRemove?: (index: number) => void,
    handleMultiselectInputChange?: any) => {

    const fieldOfStudyList = instanceData?.[`study_information_${index}`]?.fieldOfStudyValues;
    const schoolList = instanceData?.[`study_information_${index}`]?.schoolValues;
    const realmsList = instanceData?.[`study_information_${index}`]?.realmValues;
    const yearOfStudyField: any = {};
    const removeStudySubjectField: any = {};

    if ('student' === value?.status) {
        yearOfStudyField.yearOfStudies = {
            hostClass: `offset-lg-6 col-xl-6 form-row form-row-edit study-year-control`,
            controlType: 'control',
            formControlType: FormControlType.QUANTITY_INPUT,
            validationRules: [{name: ValidationRules.IS_REQUIRED}],
            labelHidden: true,
            placeholder: '',
            customIncrementButtons: true,
            defaultValue: value.statusDescription,
            value: value.statusDescription,
            maxValue: 10,
            minValue: 0,
            label: 'mentorOnboarding.registration.study_information.formControls.status.yearOfStudy',
            type: InputType.NUMBER,
            inputDataMapper: (data: any) => {
                if (!data) {
                    return null;
                }

                return Number(data);
            },
            outputDataMapper: (data: any) => {
                return Number(data);
            },
            useWholePathAsName: true,
        };
    }
    removeStudySubjectField.removeStudyField = {
        hostClass: 'remove-study-subject',
        controlType: 'control',
        formControlType: FormControlType.BUTTON,
        buttonType: 'button',
        inputStyles: 'btn-remove-subject btn-remove-study-subject',
        defaultContainerStyles: '',
        containerStyles: '',
        defaultValue: null,
        buttonDisabled: false,
        btnText: 'x',
        btnHasTooltip: true,
        btnTooltipText: 'mentorOnboarding.registration.study_information.formControls.removeStudyInformation',
        customClickHandler: () => {
            if (onRemove) {
                onRemove(index);
            }
        }
    };

    return {
        key: `study_information_${index}`,
        controlType: 'group',
        class: 'row m-row-study-information',
        controls: {
            studyLevel: {
                hostClass: 'col-xl-12 form-row form-row-edit study-level-control',
                controlType: 'control',
                formControlType: FormControlType.RADIO,
                options: [
                    {value: 'bachelor', displayValue: 'Licencjackie'},
                    {value: 'master', displayValue: 'Magisterskie'},
                ],
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'mentorOnboarding.registration.study_information.formControls.placeholder',
                label: 'mentorOnboarding.registration.study_information.formControls.studyLevel.label',
                type: InputType.RADIO,
                useWholePathAsName: true,
            },
            ...removeStudySubjectField,
            country: {
                controlType: 'control',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.SINGLE,
                multiselectOptions: countryList,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'mentorOnboarding.registration.study_information.formControls.placeholder',
                label: 'mentorOnboarding.registration.study_information.formControls.studyCountry.label',
                hostClass: 'col-xl-12 form-row form-row-edit',
                inputDataMapper: collectionInputDataMapper,
                outputDataMapper: collectionOutputDataMapper,
                menuPlacement: MultiselectMenuPlacement.BOTTOM,
                isCustomMultiValueContainer: true,
                disabled: instanceData?.[`study_information_${index}`]?.loading,
                useWholePathAsName: true,
            },
            school: {
                controlType: 'control',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.SINGLE,
                multiselectOptions: schoolList,
                disabled: instanceData?.[`study_information_${index}`]?.loading || 0 === schoolList?.length,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'schedules.addSchedule.form.placeholders.typeOption',
                label: 'mentorOnboarding.registration.study_information.formControls.school.label',
                hostClass: 'col-xl-12 form-row form-row-edit',
                inputDataMapper: collectionInputDataMapper,
                outputDataMapper: collectionOutputDataMapper,
                menuPlacement: MultiselectMenuPlacement.BOTTOM,
                isCustomMultiValueContainer: true,
                useWholePathAsName: true,
                handleMultiselectInputChange: (value: any) => {
                    if (handleMultiselectInputChange) {
                        return handleMultiselectInputChange(value, 'school', index);
                    }
                }
            },
            fieldOfStudy: {
                controlType: 'control',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.SINGLE,
                multiselectOptions: fieldOfStudyList,
                validationRules: [{name: ValidationRules.IS_REQUIRED}],
                disabled: instanceData?.[`study_information_${index}`]?.loading || 0 === fieldOfStudyList?.length,
                placeholder: 'mentorOnboarding.registration.study_information.formControls.placeholder',
                label: translate('mentorOnboarding.registration.study_information.formControls.fieldOfStudy.label', {index: index + 1}),
                hostClass: 'col-xl-12 form-row form-row-edit',
                inputDataMapper: collectionInputDataMapper,
                outputDataMapper: collectionOutputDataMapper,
                useWholePathAsName: true,
            },
            realm: {
                controlType: 'control',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.SINGLE,
                multiselectOptions: realmsList,
                validationRules: [],
                placeholder: 'mentorOnboarding.registration.study_information.formControls.placeholder',
                label: translate('mentorOnboarding.registration.study_information.formControls.realm.label', {index: index + 1}),
                disabled: true,
                hostClass: 'col-xl-12 form-row form-row-edit d-none',
                inputDataMapper: collectionInputDataMapper,
                outputDataMapper: collectionOutputDataMapper,
                useWholePathAsName: true,
            },
            status: {
                hostClass: 'col-xl-12 form-row form-row-edit',
                controlType: 'control',
                formControlType: FormControlType.RADIO,
                options: [
                    {value: 'graduate', displayValue: 'Absolwent'},
                    {value: 'student', displayValue: 'Student'},
                ],
                validationRules: [{name: ValidationRules.IS_REQUIRED}],
                placeholder: 'mentorOnboarding.registration.study_information.formControls.placeholder',
                label: 'mentorOnboarding.registration.study_information.formControls.status.label',
                type: InputType.RADIO,
                useWholePathAsName: true,
            },
            ...yearOfStudyField,
        },
    };
};
