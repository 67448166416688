import {
    accountSelector, IAccount, initLogout,
    NavBar, NotFound, Sidebar,
    Toast, Translation, usernameSelector, UserRole,
    authTokenSelector, getCurrentYear
} from 'educat-common-web';
import React from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import ApplicantList from '../Applicants/ApplicantList';
import ApplicantView from '../Applicants/ApplicantView';
import MentorList from '../Mentors/MentorList';
import {Subscription} from 'rxjs';
import MentorView from '../Mentors/MentorView';
import Profile from '../Profile';
import Sales from '../Sales';
import Tutors from '../Tutors';
import navigation from './menu-items';
import Pricing from "../Pricing";
import InquiryList from "../Inquiries/InquiryList";

interface IConnectedPanelHostProps {
    readonly account: typeof IAccount;
    readonly username: string;
    readonly initLogout: typeof initLogout;
    readonly authToken: string;
}

interface IExternalPanelHostProps {
    envData: any;
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps,
    IExternalPanelHostProps,
    RouteComponentProps {
}

interface IPanelHostState {
    isSidebarCollapsed: boolean;
    applicantList: {[key: string]: any}[] | [];
    listMetadata: {[key: string]: any} | null;
    isProcessing: boolean;
}


class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    private navMenu: any[];
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            isSidebarCollapsed: false,
            applicantList: [],
            listMetadata: null,
            isProcessing: false
        };

        this.navMenu = [
            {
                url: '#',
                icon: 'icon-power',
                title: 'Wyloguj',
                onClick: this.onLogout
            }
        ];
        fixInjectedProperties(this);
    }
    componentDidMount(): void {

    }
    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const collapsed = this.state.isSidebarCollapsed ? 'collapsed' : '';

        return (
            <div className="dashboard-view">
                <div className="main-container-wrapper">
                    <Sidebar navigation={navigation} collapseMenu={this.state.isSidebarCollapsed}/>

                    <div className={`main-container ${collapsed}`}>
                        <NavBar
                            onToggleNavigation={this.toggleNavigation}
                            onLogout={this.onLogout}
                            isDropdownVisible={false}
                            isSearchVisible={false}
                            generalNotificationsDisplayed={false}
                            profileNotificationsDisplayed={false}
                            navMenu={this.navMenu}
                            username={'Admin'}
                            userImageUrl={this.props.account?.avatar?.tinyThumb}
                            userRole={UserRole.ADMIN}
                            navigation={navigation.items}
                            collapseMenu={this.state.isSidebarCollapsed}
                        />
                        <div className="content-wrapper">
                            <main className="content">
                                <Switch>
                                    <Route path="/panel/mentors" component={() => <MentorList envData={this.props.envData}/>} exact
                                           key="mentorList"/>
                                    <Route path="/panel/mentors/:id" component={() => <MentorView/>} key="mentorView"/>
                                    <Route path="/panel/applicants" component={() => <ApplicantList envData={this.props.envData}/>} exact key="applicantList"/>
                                    <Route path="/panel/applicants/:id" component={() => <ApplicantView/>} key="applicantView"/>
                                    <Route path="/panel/insurances" component={() => <InquiryList envData={this.props.envData}/>} exact key="insuranceList"/>

                                    <Route path="/panel/tutors" component={Tutors} exact key="tutors"/>
                                    <Route path="/panel/profile" component={Profile} exact key="profile"/>
                                    <Route path="/panel/sales" component={Sales} exact key="sales"/>
                                    <Route path="/panel/pricing" component={() => <Pricing/>} exact key="pricing"/>
                                    <Route key="not-found" component={NotFound}/>
                                </Switch>
                            </main>
                            <footer className="footer">
                                <Translation text="footer.copyright" config={{year: getCurrentYear()}}/>
                            </footer>
                        </div>
                    </div>
                </div>
                <Toast/>
            </div>
        );
    }



    toggleNavigation = (): void => {
        this.setState({isSidebarCollapsed: !this.state.isSidebarCollapsed});
    };


    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
    };
}

export default connect(
    (state: RootState) => ({
        account: accountSelector(state),
        username: usernameSelector(state),
        authToken: authTokenSelector(state)
    }),
    {
        initLogout,
    }
)(withRouter(PanelHost));
