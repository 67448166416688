import moment from "moment";
import {IEditInsuranceFormValue} from "./index";

import {TripReason, FormControlType, InputType, ValidationRules} from "educat-common-web";

const getStatusTranslationLabel = (value: typeof TripReason) => `inquiries.inquiryList.editModal.formControls.statuses.${value}`;

export const editInquiryFormConfig: any = (value: IEditInsuranceFormValue) => {
    return {
        controlType: 'form',
        class: 'insurance-data-form p-3',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'subject_name',
                controlType: 'group',
                class: 'row multiple-controls-row',
                controls: {
                    firstName: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiries.inquiryList.editModal.formControls.placeholders.firstName',
                        isLabelHidden: false,
                        label: 'inquiries.inquiryList.editModal.formControls.labels.firstName',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    },
                    lastName: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiries.inquiryList.editModal.formControls.placeholders.lastName',
                        isLabelHidden: false,
                        label: 'inquiries.inquiryList.editModal.formControls.labels.lastName',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    }
                }
            },
            {
                key: 'subject_birthdate',
                controlType: 'group',
                class: 'row multiple-controls-row',
                controls: {
                    birthDate: {
                        controlType: 'control',
                        additionalStyles: '',
                        formControlType: FormControlType.DATE,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiries.inquiryList.editModal.formControls.placeholders.birthDate',
                        isLabelHidden: false,
                        label: 'inquiries.inquiryList.editModal.formControls.labels.birthDate',
                        type: InputType.DATE,
                        dateFormat: 'dd/MM/yyyy',
                        // minDate: new Date(1920, 1, 1),
                        maxDate: moment().format('YYYY-MM-DD'),
                        openToDate: value.birthDate ? value.birthDate : moment('01/01/2000').format('YYYY-MM-DD'),
                        hostClass: 'form-control required',
                    },
                    school: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.INPUT,
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: 'inquiries.inquiryList.editModal.formControls.placeholders.school',
                        isLabelHidden: false,
                        label: 'inquiries.inquiryList.editModal.formControls.labels.school',
                        type: InputType.TEXT,
                        hostClass: 'form-control required',
                    },
                }
            },
            {
                key: 'subject_address',
                controlType: 'group',
                class: 'row multiple-controls-row',
                controls: {
                    status: {
                        hostClass: 'col-xl-12 form-row required',
                        controlType: 'control',
                        formControlType: FormControlType.RADIO,
                        options: [
                            {value: TripReason.BACHELOR_DEGREE_STUDIES, displayValue: getStatusTranslationLabel(TripReason.BACHELOR_DEGREE_STUDIES)},
                            {value: TripReason.ENGINEERING_STUDIES, displayValue: getStatusTranslationLabel(TripReason.ENGINEERING_STUDIES)},
                            {value: TripReason.MASTER_DEGREE_STUDIES, displayValue: getStatusTranslationLabel(TripReason.MASTER_DEGREE_STUDIES)},
                            {value: TripReason.DOCTORAL_STUDIES, displayValue: getStatusTranslationLabel(TripReason.DOCTORAL_STUDIES)},
                            {value: TripReason.MBA_STUDIES, displayValue: getStatusTranslationLabel(TripReason.MBA_STUDIES)},
                            {value: TripReason.HIGH_SCHOOL_ABROAD, displayValue: getStatusTranslationLabel(TripReason.HIGH_SCHOOL_ABROAD)},
                            {value: TripReason.ERASMUS_OR_EXCHANGE, displayValue: getStatusTranslationLabel(TripReason.ERASMUS_OR_EXCHANGE)},
                            {value: TripReason.OTHER, displayValue: getStatusTranslationLabel(TripReason.OTHER)}
                        ],
                        validationRules: [
                            {name: ValidationRules.IS_REQUIRED},
                        ],
                        placeholder: '',
                        label: 'inquiries.inquiryList.editModal.formControls.labels.status',
                        type: InputType.RADIO,
                        isLabelTranslated: true,
                    }
                }
            },
            {
                controlType: 'group',
                key: 'subject_submit',
                class: 'd-flex justify-content-end mt-2',
                controls: {
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'submit',
                        inputStyles: 'btn-theme w-auto btn-small',
                        defaultContainerStyles: '',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: 'inquiries.inquiryList.editModal.formControls.buttons.save',
                        buttonDisabled: (mappedOutputValue: IEditInsuranceFormValue, isFormValid: boolean) => {
                            return !isFormValid;
                        }
                    },
                },
            },
        ]
    }
};
