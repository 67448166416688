import moment from "moment/moment";
import {RestQueryParams} from "educat-common-web";

export interface IPaginationData {
    page: number,
    itemsPerPage: number,
}

export interface IInquiryListFilters {
    createdAt?: Date[] | null
    reference?: string | null;
    statuses?: string[] | null;
}

export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 10;

export const getInquiryListFiltersParams = (
    filters: IInquiryListFilters,
    paginationData: IPaginationData
): typeof RestQueryParams => {
    let params = new RestQueryParams()
        .add('page', paginationData.page)
        .add('itemsPerPage', paginationData.itemsPerPage)
        .add('order[createdAt]', 'desc');

    return getInquiryFiltersParams(params, filters);
}

export const getInquirySummaryParams = (filters: IInquiryListFilters | null): typeof RestQueryParams => {
    let params = new RestQueryParams();
    return getInquiryFiltersParams(params, filters);
}

export const getInquiryCSVFiltersParam = (filters: IInquiryListFilters | null): string => {
    let params = new RestQueryParams();
    params = getInquiryFiltersParams(params, filters);

    let paramStr: string = '';
    params._params.forEach((param: any, index: number) => {
        paramStr += index === 0 ? '?' : '&';
        paramStr +=`${param.path}=${param.val}`;
    })

    return paramStr;
}

export const getInquiryFiltersParams = (params: typeof RestQueryParams, filters: IInquiryListFilters | null): typeof RestQueryParams => {
    if (!filters) return params;

    const currentDate = new Date(),
        defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        defaultEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    if (filters.createdAt && Array.isArray(filters.createdAt) && filters.createdAt.length === 2) {
        params = params
            .add('createdAt[after]', moment(filters.createdAt[0]).startOf('day').toISOString())
            .add('createdAt[before]', moment(filters.createdAt[1]).endOf('day').toISOString());
    } else {
        params = params
            .add('createdAt[after]', moment(defaultStartDate).startOf('day').toISOString())
            .add('createdAt[before]', moment(defaultEndDate).endOf('day').toISOString());
    }

    if (filters?.statuses && Array.isArray(filters?.statuses) && filters?.statuses.length) {
        filters?.statuses.map((item: string) => params = params.add('offer_status[]', item))
    }

    if (filters.reference) params = params.add('acceptedOffer.reference', filters.reference);

    return params;
}