import {FormControlType, IFormConfig, InputType, isNotNullOrUndefined, isNullOrUndefined, ValidationRules} from 'educat-common-web';
import {injectable} from 'inversify';
import {CalculatedPriceDTO} from '../../../../../api/provider/educatCalculatedPricesAPI';
import {fixInjectedProperties} from '../../../../../ioc';

export const serviceApplicationElementGroup: typeof IFormConfig = (groupName: string, controls: any, isFieldset: boolean) => ({
    key: `${groupName}`,
    controlType: isFieldset ? 'fieldset' : 'group',
    fieldsetTitle: isFieldset ? groupName : null,
    class: isFieldset ? 'row help-range' : 'row',
    controls: controls,
});

export const mentorOnboardingSurvey: MentorOnboardingSurvey = {
    nationality: null,
    residency: null,
    businessOwner: null,
    over26: null,
    student: null,
    otherEmployment: null,
    bankAccountNumber: null,
    bankData: null,
};

export enum MentorOnboardingSteps {
    ONBOARDING_INFORMATION = 'ONBOARDING_INFORMATION',
    HOURLY_RATES = 'HOURLY_RATES',
    APPLICATION_EXAM_PRICES = 'APPLICATION_EXAM_PRICES',
    HOUR_PACKAGES = 'HOUR_PACKAGES',
    FREE_HELP_PACKAGES = 'FREE_HELP_PACKAGES',
    SERVICE_PACKAGES = 'SERVICE_PACKAGES',
    THANK_YOU = 'THANK_YOU',
}

export const onboardingStepsArray: ReadonlyArray<{ readonly stepName: MentorOnboardingSteps }> = [
    {stepName: MentorOnboardingSteps.ONBOARDING_INFORMATION},
    {stepName: MentorOnboardingSteps.HOURLY_RATES},
    {stepName: MentorOnboardingSteps.APPLICATION_EXAM_PRICES},
    {stepName: MentorOnboardingSteps.HOUR_PACKAGES},
    {stepName: MentorOnboardingSteps.SERVICE_PACKAGES},
    {stepName: MentorOnboardingSteps.FREE_HELP_PACKAGES},
    {stepName: MentorOnboardingSteps.THANK_YOU},
];

export type MentorOnboardingSurvey = {
    readonly nationality: string | null;
    readonly residency: string | null;
    readonly businessOwner: boolean | null;
    readonly over26: boolean | null;
    readonly student: boolean | null;
    readonly otherEmployment: string | null;
    readonly bankAccountNumber: string | null;
    readonly bankData: string | null;
};


export enum MentorType {
    MENTOR = 'mentor',
    MENTOR_SCHOLAR = 'mentor_scholar',
    SCHOLAR = 'scholar',
}

export enum ServiceConsultationType {
    CONSULTATION = 'consultation',
    CONSULTATION_TRIAL = 'consultation_trial',
}

export type ItemPrice = {
    readonly amount: number;
    readonly currency: { code: string };
};

export type MentorServiceDefinition = {
    readonly name: string;
    readonly description: string;
    readonly itemPrice: ItemPrice;
    readonly mentorId: string;
    readonly serviceApplicationElement: ServiceApplicationElement | null;
    readonly serviceApplicationPackage: ServiceApplicationPackage | null;
    readonly serviceConsultation: ServiceConsultation | null;
    readonly serviceConsultationPackage: ServiceConsultationPackage | null;
    readonly active: boolean;
};
export type MentorServiceDefinitionPayload = {
    readonly name: string;
    readonly description: string;
    readonly itemPrice: ItemPrice;
    readonly mentorId: string;
    readonly serviceApplicationElementId: string | null;
    readonly serviceApplicationPackageId: string | null;
    readonly serviceConsultationId: string | null;
    readonly serviceConsultationPackageId: string | null;
    readonly educatMargin?: number;
};

export type ServiceApplicationElement = {
    readonly id: string;
    readonly name: string;
    readonly mentorTaskType?: MentorTaskType;
};

export type ServiceApplicationPackage = {
    readonly id: string;
    readonly name: string;
    readonly items: number;
    readonly free: boolean;
};

export type ServiceConsultation = {
    readonly id: string;
    readonly type: ServiceConsultationType;
    readonly name: string;
};

export type ServiceConsultationPackage = {
    readonly id: string;
    readonly items: number;
    readonly discount: number;
    readonly name: string;
};

export type MentorTaskType = {
    readonly for: MentorType;
    readonly id: string;
    readonly name: string;
    readonly mentorTaskTypeGroup: MentorTaskTypeGroup | null;
};

export type MentorTaskTypeGroup = {
    readonly id: string;
    readonly name: string;
};

export type MentorServiceCalculatedPrice = {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly price: string;
    readonly currency: string;
    readonly discount: number;
    readonly discountedPrice: {
        readonly amount: string;
        readonly currency: {
            readonly code: string;
        };
    };
};

export interface ServiceFee {
    mentorFee?: any;
    trialFee?: any;
}

export interface IOnboardingHelperService {
    createFormGroupsFromServiceApplicationElements(groupName: string, serviceApplicationElementArray: ServiceApplicationElement[]): void;

    convertRawDataToPayload(rawData: { [key: string]: any }, mentorId: string, currentStep: MentorOnboardingSteps, mentorFee: ServiceFee, additionalData?: any): any;

    countServicePricesFromInput(priceValue: number, mentorFee: number): { mentorGross: number | null; eduCatCommission: string | null; vat: string | null; grossPrice: string | null };

    updateCurrentServiceApplicationPackages(
        serviceApplicationPackagesList: ServiceApplicationPackage[],
        serviceApplicationPackagePrices: CalculatedPriceDTO[]
    ): any[];

    updateCurrentConsultationPackages(
        serviceConsultationList: ServiceConsultationPackage[],
        serviceConsultationPrices: CalculatedPriceDTO[]
    ): any[];

    convertMentorDataToRawFormData(mentorServiceDefinitions: any, mentorData: any, trialFee?: any): any;

    filterServiceApplicationElements(dataList: any, mentorTaskTypes: any): any[];
}

@injectable()
class OnboardingHelperService implements IOnboardingHelperService {
    constructor() {
        fixInjectedProperties(this);

    }

    public createFormGroupsFromServiceApplicationElements = (
        groupName: string,
        serviceApplicationElementArray: ServiceApplicationElement[]
    ) => {
        if (!serviceApplicationElementArray) {
            return [];
        }
        const controlGroups: any = [],
            formControls = this.createFormControlsFromServiceApplicationElement(serviceApplicationElementArray),
            formControlsGroup = serviceApplicationElementGroup(groupName, formControls, false);
        controlGroups.push(formControlsGroup);

        return controlGroups;
    };

    public convertRawDataToPayload = (
        rawData: { [key: string]: any },
        mentorId: string,
        currentStep: MentorOnboardingSteps,
        mentorFee: ServiceFee,
        additionalData?: any
    ) => {
        let payload;
        switch (currentStep) {
            case MentorOnboardingSteps.ONBOARDING_INFORMATION:
                payload = {
                    nationality: rawData.nationality,
                    residency: rawData.residency,
                    businessOwner: rawData.businessOwner,
                    over26: rawData.over26,
                    student: rawData.student,
                    otherEmployment: rawData.otherEmployment,
                    bankAccountNumber: rawData.bankAccountNumber,
                    bankData: rawData.bankData,
                };
                break;

            case MentorOnboardingSteps.HOURLY_RATES:
                const hourlyRatesData = rawData?.hourly_rates ? rawData.hourly_rates : rawData;
                payload = this.convertRawFormDataToServiceDefinitions(hourlyRatesData, mentorId, mentorFee, additionalData.general);
                break;
            case MentorOnboardingSteps.APPLICATION_EXAM_PRICES:
                const applicationExamRates = rawData?.application_exam ? rawData.application_exam : rawData;
                payload = this.convertRawFormDataToServiceDefinitions(applicationExamRates, mentorId, mentorFee, additionalData.exam);
                break;
            case MentorOnboardingSteps.HOUR_PACKAGES:
                payload = this.convertRawFormDataToServiceDefinitionPackages(MentorOnboardingSteps.HOUR_PACKAGES, rawData, mentorId, additionalData);
                //  TODO: when we have proper prices counted from backend we can post further MentorServiceDefinitions.

                break;
            case MentorOnboardingSteps.SERVICE_PACKAGES:
                payload = this.convertRawFormDataToServiceDefinitionPackages(MentorOnboardingSteps.SERVICE_PACKAGES, rawData, mentorId, additionalData);

                break;
            case MentorOnboardingSteps.FREE_HELP_PACKAGES:
                break;
            case MentorOnboardingSteps.THANK_YOU:
                break;
            default:
                return null;
        }
        return payload;
    };

    public countServicePricesFromInput = (
        priceValue: number,
        mentorFee: number
    ): { mentorGross: number | null; eduCatCommission: string | null; vat: string | null; grossPrice: string | null } => {
        let numReg = new RegExp(/^\+?([1-9]\d*)$/);
        if (Number.isNaN(priceValue) && !numReg.test(String(priceValue).toLowerCase())) {
            return {
                mentorGross: null,
                eduCatCommission: null,
                vat: null,
                grossPrice: null
            }
        }

        const mentorGross = priceValue,
            netPrice = mentorGross * 100 / (100 - mentorFee),
            vatValue = netPrice * 0.23,
            grossPrice = Math.round(netPrice + vatValue),
            finalNetPrice = grossPrice / 1.23,
            finalCommission = finalNetPrice - mentorGross,
            finalVatPrice = grossPrice - finalNetPrice;

        return {
            mentorGross: finalNetPrice,
            eduCatCommission: finalCommission.toFixed(2),
            vat: finalVatPrice.toFixed(2),
            grossPrice: grossPrice.toFixed(2),
        };
    };

    public updateCurrentServiceApplicationPackages(
        serviceApplicationPackagesList: ServiceApplicationPackage[],
        serviceApplicationPackagePrices: CalculatedPriceDTO[]
    ) {
        const updatedConsultationPackageList = serviceApplicationPackagesList.filter((serviceApplication: ServiceApplicationPackage) => {
            const serviceApplicationPricesArray = serviceApplicationPackagePrices.map(
                (serviceApplicationPrice: CalculatedPriceDTO) => serviceApplicationPrice.serviceId
            );
            return serviceApplicationPricesArray.includes(serviceApplication.id);
        });

        return updatedConsultationPackageList.map((updatedPackage: ServiceApplicationPackage) => {
            const consultationPriceItem = serviceApplicationPackagePrices.find(
                (serviceApplicationPrice: CalculatedPriceDTO) => serviceApplicationPrice.serviceId === updatedPackage.id
            );

            return {
                name: updatedPackage.name,
                id: updatedPackage.id,
                price: consultationPriceItem ? consultationPriceItem.price?.amount : '--',
                currency: consultationPriceItem ? consultationPriceItem.price?.currency.code : null,
                discount: consultationPriceItem ? consultationPriceItem.discount : '--',
                discountedPrice: consultationPriceItem ? consultationPriceItem.discountedPrice : null,
                description: consultationPriceItem ? consultationPriceItem.description : null,
            };
        });
    }

    public updateCurrentConsultationPackages(
        serviceConsultationList: ServiceConsultationPackage[],
        serviceConsultationPrices: CalculatedPriceDTO[]
    ): any[] {
        return serviceConsultationList
            .filter((serviceConsultation: ServiceConsultationPackage) => {
                const serviceConsultationPricesArray = serviceConsultationPrices.map(
                    (serviceConsultationPrice: CalculatedPriceDTO) => serviceConsultationPrice.serviceId
                );

                return serviceConsultationPricesArray.includes(serviceConsultation.id);
            })
            .map((updatedPackage: ServiceConsultationPackage) => {
                const consultationPriceItem = serviceConsultationPrices.find(
                    (serviceConsultationPrice: CalculatedPriceDTO) => serviceConsultationPrice.serviceId === updatedPackage.id
                );

                return {
                    name: updatedPackage.name,
                    id: updatedPackage.id,
                    price: consultationPriceItem ? consultationPriceItem.price?.amount : '--',
                    currency: consultationPriceItem ? consultationPriceItem.price?.currency.code : null,
                    discount: consultationPriceItem ? consultationPriceItem.discount : '--',
                    discountedPrice: consultationPriceItem ? consultationPriceItem.discountedPrice : null,
                    description: consultationPriceItem ? consultationPriceItem.description : null,
                };
            });
    }

    public convertMentorDataToRawFormData(mentorServiceDefinitions: any, mentorData: any, trialFee?: any) {
        let updatedRawData: any = {
            [MentorOnboardingSteps.HOURLY_RATES]: {hourly_rates: {}},
            [MentorOnboardingSteps.APPLICATION_EXAM_PRICES]: {application_exam: {}},
            [MentorOnboardingSteps.SERVICE_PACKAGES]: {},
            [MentorOnboardingSteps.HOUR_PACKAGES]: {},
            [MentorOnboardingSteps.FREE_HELP_PACKAGES]: {},
        };
        if (!mentorServiceDefinitions.length) {
            return updatedRawData;
        }
        const withoutFeeFactor = (100 - mentorData.feePercent) / 100;
        const withoutFeeFactorForTrial = (100 - (trialFee ?? mentorData.feePercent)) / 100;

        function isConsultationTrial(msd: MentorServiceDefinition){
            return msd.serviceConsultation?.type === ServiceConsultationType.CONSULTATION_TRIAL;
        }

        mentorServiceDefinitions.forEach((mentorServiceDefinition: MentorServiceDefinition) => {
            let id = null,
                price = Math.round(mentorServiceDefinition.itemPrice.amount * (isConsultationTrial(mentorServiceDefinition) ? withoutFeeFactorForTrial : withoutFeeFactor) / 100);

            if (!isNullOrUndefined(mentorServiceDefinition.serviceApplicationElement)) {
                id = mentorServiceDefinition.serviceApplicationElement?.id;
                updatedRawData[MentorOnboardingSteps.APPLICATION_EXAM_PRICES]['application_exam'] = Object.assign(
                    updatedRawData[MentorOnboardingSteps.APPLICATION_EXAM_PRICES]['application_exam'],
                    {[`${id}`]: price}
                );
            }
            if (!isNullOrUndefined(mentorServiceDefinition.serviceConsultation)) {
                id = mentorServiceDefinition.serviceConsultation?.id;
                updatedRawData[MentorOnboardingSteps.HOURLY_RATES]['hourly_rates'] = Object.assign(
                    updatedRawData[MentorOnboardingSteps.HOURLY_RATES]['hourly_rates'],
                    {
                        [`consultation_${id}`]: price,
                    }
                );
            }
            if (!isNullOrUndefined(mentorServiceDefinition.serviceApplicationPackage)) {
                id = mentorServiceDefinition.serviceApplicationPackage?.id;
                if (id === 'c83af627-0944-4ff7-9402-0a14a38fcb4c') {
                    updatedRawData[MentorOnboardingSteps.FREE_HELP_PACKAGES] = Object.assign(updatedRawData[MentorOnboardingSteps.FREE_HELP_PACKAGES], {
                        selected: true,
                        services: null,
                        maxApplicants: mentorData.serviceConfig.freeServiceInstanceUsedLimit
                    });
                } else {
                    updatedRawData[MentorOnboardingSteps.SERVICE_PACKAGES] = Object.assign(updatedRawData[MentorOnboardingSteps.SERVICE_PACKAGES], {
                        [`${id}`]: {
                            'price': price,
                            'active': mentorServiceDefinition.active
                        }
                    });
                }
            }
            if (!isNullOrUndefined(mentorServiceDefinition.serviceConsultationPackage)) {
                id = mentorServiceDefinition.serviceConsultationPackage?.id;
                updatedRawData[MentorOnboardingSteps.HOUR_PACKAGES] = Object.assign(updatedRawData[MentorOnboardingSteps.HOUR_PACKAGES], {
                    [`${id}`]: {
                        'price': price,
                        'active': mentorServiceDefinition.active
                    }
                });
            }
        });

        return updatedRawData;
    }


    public filterServiceApplicationElements(dataList: any, mentorTaskTypes: any) {
        const present: any = {};

        return mentorTaskTypes
            .map((taskType: any) => dataList.find(
                (serviceApplicationElement: any) => {
                    if (isNotNullOrUndefined(serviceApplicationElement.mentorTaskType?.id) && serviceApplicationElement.mentorTaskType?.id === taskType.id) {
                        return true;
                    }
                    if (isNotNullOrUndefined(serviceApplicationElement.mentorTaskTypeGroup?.id) && serviceApplicationElement.mentorTaskTypeGroup?.id === taskType?.mentorTaskTypeGroup?.id) {
                        return true;
                    }

                    return false;
                }))
            .filter((item: any) => {
                if (isNullOrUndefined(item)) {
                    return false;
                }
                // only unique
                if (true !== present[item.id]) {
                    present[item.id] = true;

                    return true;
                }

                return false;
        });
    }

    private createFormControlsFromServiceApplicationElement = (
        serviceApplicationElementArray: ServiceApplicationElement[] | ServiceConsultation[]
    ) => {
        let controls = {};

        serviceApplicationElementArray.forEach((serviceApplicationElement: any) => {
            const name = serviceApplicationElement.name,
                controlName = serviceApplicationElement.type ? `consultation_${serviceApplicationElement.id}` : serviceApplicationElement.id;
            let tooltipText: string | null = null;
            if (serviceApplicationElement.type && serviceApplicationElement.type === 'consultation_trial') {
                tooltipText = 'mentorOnboarding.onboarding.hourly_rates.tooltip';
            }
            controls = Object.assign(controls, {
                [`label${controlName}`]: {
                    controlType: 'control',
                    formControlType: FormControlType.LABEL,
                    label: name,
                    hostClass: 'col-xl-4 onboarding-table-cell cell-label',
                    tooltipText: tooltipText,
                },
                [`${controlName}`]: {
                    controlType: 'control',
                    // value: false,
                    defaultValue: null,
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_POSITIVE_NUMBER_OR_NULL},
                    ],
                    placeholder: '',
                    labelHidden: false,
                    customIncrementButtons: false,
                    minDate: 0,
                    step: 10,
                    type: InputType.NUMBER,
                    hostClass: 'col-xl-2 onboarding-table-cell hide-label cell-input',
                    outputDataMapper: (data: any) => {
                        if (!data) {
                            return null;
                        }
                        return Number(data * 100);
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return null;
                        }
                        return Number(data / 100);
                    },
                },
                [`educat_commission_${controlName}`]: {
                    hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
                    controlType: 'control',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    labelHidden: true,
                    disabled: true,
                    placeholder: '--',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return null;
                        }
                        return Number(data).toFixed(2);
                    },
                },
                [`vat_${controlName}`]: {
                    hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
                    controlType: 'control',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    labelHidden: true,
                    disabled: true,
                    placeholder: '--',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return null;
                        }
                        return `${Number(data).toFixed(2)} %`;
                    },
                },
                [`platform_price_${controlName}`]: {
                    hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
                    controlType: 'control',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    labelHidden: true,
                    disabled: true,
                    placeholder: '--',
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return null;
                        }
                        return Number(data).toFixed(2);
                    },
                },
            });
        });

        return controls;
    };

    private convertRawFormDataToServiceDefinitionPackages(
        stepName: MentorOnboardingSteps,
        stepData: any,
        mentorId: string,
        additionalData: MentorServiceCalculatedPrice[]
    ): MentorServiceDefinitionPayload[] {
        if (!stepData) {
            return [];
        }

        const selectedServices: any[] = [],
            isHourlyPackages = stepName === MentorOnboardingSteps.HOUR_PACKAGES,
            excludedKeyPrefix = isHourlyPackages ? 'net_price_discount_' : 'services_';

        for (const [key, value] of Object.entries(stepData)) {
            if (
                !key.includes('client_discount_') &&
                !key.includes('net_price_') &&
                !key.includes(excludedKeyPrefix) &&
                !isNullOrUndefined(value) &&
                value === true
            ) {
                selectedServices.push(key);
            }
        }

        return additionalData
            .filter(item => selectedServices.includes(item.id))
            .map(item => ({
                name: item.name,
                description: item.description,
                itemPrice: {
                    amount: Number(item.price),
                    currency: {
                        code: item.currency,
                    },
                },
                mentorId: mentorId,
                serviceApplicationElementId: null,
                serviceApplicationPackageId: isHourlyPackages ? null : item.id,
                serviceConsultationId: null,
                serviceConsultationPackageId: isHourlyPackages ? item.id : null,
            }));

    }

    private convertRawFormDataToServiceDefinitions(stepData: any, mentorId: string, mentorFee: ServiceFee, additionalData: any[]) {
        if (isNullOrUndefined(stepData)) {
            return [];
        }

        const selectedServiceElementsArray: { id: string; isConsultation: boolean; }[] = [],
            mentorServiceDefinitions: MentorServiceDefinitionPayload[] = [];

        for (const [key, value] of Object.entries(stepData)) {
            if (
                !key.includes('educat_commission_') &&
                !key.includes('platform_price_') &&
                !key.includes('vat_') &&
                value &&
                !isNullOrUndefined(value)
            ) {
                if (key.includes('consultation_')) {
                    selectedServiceElementsArray.push({ id: key.split('consultation_').pop() as string, isConsultation: true });
                } else {
                    selectedServiceElementsArray.push({ id: key, isConsultation: false });
                }
            }
        }

        selectedServiceElementsArray.forEach(selectedServiceElementMetadata => {
            const key = selectedServiceElementMetadata.isConsultation ?
                    `consultation_${selectedServiceElementMetadata.id}` :
                    selectedServiceElementMetadata.id,
                selectedServiceElement = additionalData.find((additionalDataItem: any) => additionalDataItem.id === selectedServiceElementMetadata.id),
                serviceElementPrice = Number(stepData[key]),
                calculatedPrices = this.countServicePricesFromInput(serviceElementPrice, selectedServiceElement?.name.includes('Godzina') ? mentorFee?.trialFee : mentorFee?.mentorFee), //TODO: 🤢
                mentorServiceDefinition: MentorServiceDefinitionPayload = {
                    name: selectedServiceElement.name,
                    description: selectedServiceElement.description,
                    itemPrice: {
                        amount: Math.round(calculatedPrices.mentorGross as number * 100),
                        currency: {
                            code: 'PLN',
                        },
                    },
                    mentorId: mentorId,
                    serviceApplicationElementId: selectedServiceElementMetadata.isConsultation ? null : selectedServiceElementMetadata.id,
                    serviceApplicationPackageId: null,
                    serviceConsultationId: selectedServiceElementMetadata.isConsultation ? selectedServiceElementMetadata.id : null,
                    serviceConsultationPackageId: null,
                    educatMargin: mentorFee?.mentorFee
                };

            mentorServiceDefinitions.push(mentorServiceDefinition);
        });

        return mentorServiceDefinitions;
    }
}

export default OnboardingHelperService;
