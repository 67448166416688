import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function deleteTutoringScopeAPI(authToken: string, scopeId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.delete(
        `tutoring_scopes/${scopeId}`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        },
        headers
    );
}
