import React, {useState} from 'react';
import {CustomCard, CustomCardType, Translation, OfferStatus} from "educat-common-web";
import moment from "moment/moment";
import InquiryStatusBadge from "../../InquiryStatusBadge";
import DownloadInsuranceButton from "./DownloadInsuranceButton";
import ResendInsuranceEmailButton from "./ResendInsuranceEmailButton";

interface IInquiryViewCardProps {
    inquiry: any | null;
    closeModal: () => void;
    changeModalType: () => void;
}

const InquiryViewCard: React.FC<IInquiryViewCardProps> = (props: IInquiryViewCardProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const getCountryName = (countryCode: string) => {
        return (<Translation text={`countries.${countryCode.toLowerCase()}`}/>)
    }

    const renderOrderDetails = () => {
        const labelKey: string = 'inquiries.inquiryList.viewModal.orderDetails';

        return (
            <section>
                <h2><Translation text={`${labelKey}.title`}/></h2>
                <table>
                    <tbody>
                    <tr>
                        <td><Translation text={`${labelKey}.createdAt`}/></td>
                        <td>{moment(props.inquiry.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.status`}/></td>
                        <td><InquiryStatusBadge status={props.inquiry.offers[0]?.status}/></td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.purchaseAt`}/></td>
                        <td>
                            {props.inquiry.acceptedOffer?.paidAt ? moment(props.inquiry.acceptedOffer?.paidAt).format('DD/MM/YYYY HH:mm') : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.inquiryPremium`}/></td>
                        <td className="text-nowrap">
                            {`${(props.inquiry.acceptedOffer?.premium?.amount / 100).toFixed(2)} EUR`}
                        </td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.axaFee`}/></td>
                        <td className="text-nowrap">
                            {`${(props.inquiry.acceptedOffer?.axaPremium?.amount / 100).toFixed(2)} EUR`}
                        </td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.educatFee`}/></td>
                        <td className="text-nowrap">
                            {`${(props.inquiry.acceptedOffer?.systemFee?.amount / 100).toFixed(2)} EUR`}
                        </td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.insuranceDateRange`}/></td>
                        <td>{moment(props.inquiry.from).format('DD/MM/YYYY')} - {moment(props.inquiry.to).format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.insuranceNo`}/></td>
                        <td>{props.inquiry.acceptedOffer?.reference ? props.inquiry.acceptedOffer.reference : '-'}</td>
                    </tr>
                    </tbody>
                </table>
            </section>
        )
    }

    const renderInquirySubjectDetails = () => {
        const labelKey: string = 'inquiries.inquiryList.viewModal.inquirySubjectDetails';
        const subject = props.inquiry.inquirySubjects.length ? props.inquiry.inquirySubjects[0] : null;
        return (
            <section>
                <h2><Translation text={`${labelKey}.title`}/></h2>
                <table>
                    <tbody>
                    <tr>
                        <td><Translation text={`${labelKey}.firstName`}/></td>
                        <td>{subject ? subject.firstName : '-'}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.lastName`}/></td>
                        <td>{subject ? subject.lastName : '-'}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.birthDate`}/></td>
                        <td>{ subject ? moment(subject.birthdate).format('DD/MM/YYYY') : '-'}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.phone`}/></td>
                        <td>{ subject ? subject.phone : '-'}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.email`}/></td>
                        <td>{ props.inquiry.guestEmail || '-'}</td>
                    </tr>
                    </tbody>
                </table>
            </section>
        )
    }

    const renderInquirySubjectAddress = () => {
        const labelKey: string = 'inquiries.inquiryList.viewModal.inquirySubjectAddress';
        const address = props.inquiry.inquirySubjects.length ? props.inquiry.inquirySubjects[0].address : null;

        return (
            <section>
                <h2><Translation text={`${labelKey}.title`}/></h2>
                <table>
                    <tbody>
                    <tr>
                        <td><Translation text={`${labelKey}.country`}/></td>
                        <td>{getCountryName(address.country)}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.city`}/></td>
                        <td>{ address ? address.city : '-'}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.postcode`}/></td>
                        <td>{ address ? address.postcode : '-'}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.street`}/></td>
                        <td>{ address ? address.addressLine1 : '-'}</td>
                    </tr>
                    </tbody>
                </table>
            </section>
        )
    }
    const renderInquiryDataDetails = () => {
        const labelKey: string = 'inquiries.inquiryList.viewModal.inquiryDataDetails';

        const dateFrom = moment(props.inquiry.from),
            dateTo = moment(props.inquiry.to),
            days: number = dateTo.diff(dateFrom, 'days'),
            duration: number = Math.round(days / 30)

        const renderClausetBadge = (isClause: boolean) => {
            return (
                <span className={`badge badge-confirm ${isClause ? 'yes' : 'no'}`}>
                    <Translation text={`badge.confirm.${isClause ? 'yes' : 'no'}`} />
                </span>
            );
        }

        return (
            <section>
                <h2><Translation text={`${labelKey}.title`}/></h2>
                <table>
                <tbody>
                    <tr>
                        <td><Translation text={`${labelKey}.insuranceDuration`}/>
                        </td>
                        <td>{duration}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.insuranceStart`}/>
                        </td>
                        <td>{moment(props.inquiry.from).format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.destination`}/></td>
                        <td>{getCountryName(props.inquiry.destination)}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.tripReason`}/></td>
                        <td><Translation text={`${labelKey}.tripReasons.${props.inquiry.tripReason}`}/></td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.school`}/></td>
                        <td>{props.inquiry.schoolName}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.variant`}/></td>
                        <td>{props.inquiry.inquiryVariant?.name || '-'}</td>
                    </tr>
                    <tr>
                        <td><Translation text={`${labelKey}.clauses.label`}/></td>
                        <td>
                            <span className="d-flex flex-column">
                                <span className="d-flex gap-3">
                                    <Translation text={`${labelKey}.clauses.alcoholClause`}/>
                                    {renderClausetBadge(props.inquiry.alcoholClause)}
                                </span>
                                <span className="d-flex gap-3">
                                    <Translation text={`${labelKey}.clauses.workClause`}/>
                                    {renderClausetBadge(props.inquiry.physicalWork)}
                                </span>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>
        )
    }

    const isInsuranceDateInFuture = (insuranceDateString: string) => {
        const insuranceDate = new Date(insuranceDateString),
            currentDate = new Date();

        return insuranceDate > currentDate;
    }

    return (
        <CustomCard type={CustomCardType.MODAL_CARD} showLocalLoader={isLoading}>
            <CustomCard.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <p>
                        <Translation text={'inquiries.inquiryList.viewModal.title'}/>
                    </p>
                    <div className="d-flex">
                        <DownloadInsuranceButton inquiryId={props.inquiry.id}
                                                 isDisabled={props.inquiry.acceptedOffer?.status !== OfferStatus.STATUS_ACTIVE && props.inquiry.acceptedOffer?.status !== OfferStatus.STATUS_PAID}
                                                 setIsLoading={(isLoading) => setIsLoading(isLoading)}/>
                        <button className="btn btn-action"
                                disabled={!isInsuranceDateInFuture(props.inquiry.from)}
                                onClick={props.changeModalType}>
                            <Translation text={'inquiries.inquiryList.viewModal.btnEdit'}/>
                        </button>
                        <ResendInsuranceEmailButton inquiryId={props.inquiry.id}
                                                    isDisabled={props.inquiry.acceptedOffer?.status !== OfferStatus.STATUS_ACTIVE && props.inquiry.acceptedOffer?.status !== OfferStatus.STATUS_PAID}
                                                    setIsLoading={(isLoading) => setIsLoading(isLoading)}/>
                        <button onClick={props.closeModal}>
                            <span className="feather icon-x"/>
                        </button>
                    </div>
                </div>
            </CustomCard.Header>
            <CustomCard.Body>
                <div className="inquiry-view-card">
                    <div className="row">
                        <div className="col-xl-12">
                            {renderOrderDetails()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            {renderInquirySubjectDetails()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            {renderInquirySubjectAddress()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            {renderInquiryDataDetails()}
                        </div>
                    </div>
                </div>
            </CustomCard.Body>
        </CustomCard>
    );
};
export default InquiryViewCard;
