import React, {useEffect, useMemo, useState} from 'react';
import {
    Translation,
    handleApiError,
    addAlert,
    AlertObject,
    authTokenSelector,
    RestQueryParams,
    IMediaObject,
    IFormConfig,
    FormControlChangeType,
    Form,
    Loader,
    LoaderType
} from 'educat-common-web';
import {catchError, filter, map, tap} from "rxjs/operators";
import {BehaviorSubject, forkJoin, from, of, Subscription} from "rxjs";
import {useDispatch, useSelector} from "react-redux";
import {getMediaObjectsAPI} from "../../../../api/getMediaObjectsAPI";
import {addDocumentFormConfig} from "./addDocumentFormConfig";
import {deleteMediaObjectAPI} from "../../../../api/deleteMediaObjectAPI";

interface IInquiryDocumentsProps {
}

const InquiryDocuments: React.FC<IInquiryDocumentsProps> = () => {
    const dispatch = useDispatch(),
        authToken: string | null = useSelector(authTokenSelector),
        [isLoading, setIsLoading] = useState<boolean>(true),
        [inquiryDocumentsList, setInquiryDocumentsList] = useState<typeof IMediaObject[]>([]),
        [formConfig, setFormConfig] = useState<typeof IFormConfig | null>(addDocumentFormConfig(false)),
        [value, setValue] = useState<any>({
            document: null,
        }),
        [removedDocuments, setRemovedDocuments] = useState<string[] | null>(null),
        onValueStateChange$ = useMemo(() => new BehaviorSubject<any>(null), []);

    const subscriptions: Subscription[] = [];

    useEffect(() => {
        const subscription = onValueStateChange$.pipe(
            filter((data: any) => data && data.changeType === FormControlChangeType.User),
            tap((data: any) => setValue(data.value)),
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        }
    }, [onValueStateChange$]);

    const onValueStateChange = (controlName: string, value: any, changeType: any) => {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    useEffect(() => {
        getInquiryDocuments();

        return() => {
            if (subscriptions) {
                subscriptions.forEach(subscription => {
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                });
            }
        }
    }, []);

    const getInquiryDocuments = () => {
        let params = new RestQueryParams()
            .add('tag', 'insurance.email.attachment');

        subscriptions.push(
            getMediaObjectsAPI(authToken, params).pipe(
                map((resp) => {
                    setInquiryDocumentsList(resp['hydra:member'] || []);
                    setIsLoading(false);
                }),
                catchError((error) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    const removeDocumentItems = (document: any) => {
        const documents = inquiryDocumentsList;
        const updatedDocuments = documents.filter(doc => doc.id !== document.id);

        const removedList = removedDocuments || [];

        if (!removedList?.includes(document.id)) {
            removedList?.push(document.id);
        }

        setInquiryDocumentsList(updatedDocuments);
        setRemovedDocuments(removedList);
    }

    const updateDocuments = () => {
        if (!value?.document?.length) {
            return;
        }

        setIsLoading(true);

        const uploadRequests = value.document.map((doc: any) => {

            const formData = new FormData();
            formData.append('file', doc.blobFile);
            formData.append('public', 'true');
            formData.append('tags', 'insurance.email.attachment');
            formData.append('name', doc.name.replace('.pdf',''));

            return from(fetch(`${process.env.REACT_APP_API_URL}/media_objects`, {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    Authorization: 'Bearer ' + authToken,
                }),
            })).pipe(
                catchError(error => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    return of(dispatch(addAlert(errorObj)));
                })
            );
        });

        subscriptions.push(
            forkJoin(uploadRequests).pipe(
                tap(() => {
                    setIsLoading(false);
                    getInquiryDocuments();
                    setFormConfig(addDocumentFormConfig(true));
                    dispatch(addAlert({message: 'inquiries.inquiryList.inquirySettingsModal.files.documentsUpdated'}));
                }),
                catchError((error) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    const removeDocuments = () => {
        if (!removedDocuments || !removedDocuments?.length) {
            return;
        }

        setIsLoading(true);
        const deleteRequests = removedDocuments.map((doc: any) => {
            return deleteMediaObjectAPI(authToken, doc);
        });

        subscriptions.push(
            forkJoin(deleteRequests).pipe(
                tap(() => {
                    setIsLoading(false);
                    setRemovedDocuments(null);
                    dispatch(addAlert({message: 'inquiries.inquiryList.inquirySettingsModal.files.documentsUpdated'}));
                }),
                catchError((error) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    return (
        <div className='inquiry-documents'>
            <div className="d-flex justify-content-between flex-column">
                <h2 className="subtitle">
                    <Translation text={'inquiries.inquiryList.inquirySettingsModal.files.title'}/>
                </h2>

                {formConfig && <Form config={formConfig}
                                     value={value}
                                     controlName={'addDocumentFormConfig'}
                                     onValueStateChange={onValueStateChange}/>
                }
            </div>

            {inquiryDocumentsList.length > 0 ?
                (<ul className={'my-4 inquiry-documents-list'}>
                    {inquiryDocumentsList.map((document: any) => {
                        return (
                            <React.Fragment key={document.id}>
                                <li className="inquiry-documents-item">
                                    <p>{document.name}</p>
                                    <button onClick={() => removeDocumentItems(document)}>
                                        <span className="feather icon-trash"/>
                                    </button>
                                </li>
                            </React.Fragment>)
                    })}
                </ul>) :
                <p className={'no-data'}>
                    <Translation text={'inquiries.inquiryList.inquirySettingsModal.files.noFilesAdded'}/>
                </p>
            }

            <div className="d-flex justify-content-end">
                <button className="btn btn-theme btn-small"
                        disabled={!(removedDocuments && removedDocuments?.length > 0 || value?.document)}
                        onClick={() => removedDocuments?.length ? removeDocuments() : updateDocuments()}>
                   <Translation text={'inquiries.inquiryList.inquirySettingsModal.files.updateDocuments'} />
                </button>
            </div>

            <Loader type={LoaderType.Local} showLoader={isLoading}/>
        </div>
    );
};
export default InquiryDocuments;
