import {
    FormControlType,
    IFormConfig,
    InputType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiselectMenuPlacement,
    MultiSelectType,
    IMultiselectOption
} from 'educat-common-web';
import {IInquiryListFilters} from "../inquiryFiltersUtil";


export const inquiryListFiltersFormConfig: typeof IFormConfig = (statusOptions: typeof IMultiselectOption[]) => {
    return {
        controlType: 'form',
        class: '',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'filters_row',
                controlType: 'group',
                class: 'row',
                controls: {
                    createdAt: {
                        controlType: 'control',
                        defaultValue: null,
                        formControlType: FormControlType.DATE_RANGE_PICKER,
                        placeholder: 'inquiries.inquiryList.filters.purchaseDate',
                        label: 'inquiries.inquiryList.filters.purchaseDate',
                        isLabelHidden: false,
                        hostClass: 'col-xl-3',

                    },
                    reference: {
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        placeholder: 'inquiries.inquiryList.filters.inquiryId',
                        isLabelHidden: false,
                        label: 'inquiries.inquiryList.filters.inquiryId',
                        type: InputType.TEXT,
                        hostClass: 'col-xl-3',
                    },
                    statuses: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        validationRules: [],
                        placeholder: 'inquiries.inquiryList.filters.status',
                        label: 'inquiries.inquiryList.filters.status',
                        multiselectOptions: statusOptions,
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        hostClass: 'col-xl-3',
                        menuPlacement: MultiselectMenuPlacement.TOP,
                        isCustomMultiValueContainer: true
                    },
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'submit',
                        inputStyles: 'btn-small btn-submit',
                        defaultContainerStyles: '',
                        containerStyles: '',
                        defaultValue: null,
                        btnText: 'inquiries.inquiryList.filters.btnSubmit',
                        hostClass: 'col-xl-3',
                        buttonDisabled: (mappedOutputValue: IInquiryListFilters, isFormValid: boolean) => {
                            return !isFormValid;
                        }
                    },
                }
            },
        ]
    }
};
