import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';

// ID of any mentor will be ok as long as it is valid;
// choice of ID doesn't have any effect on the final outcome, all mentors will be updated anyway
// so why pass an ID at all? API is badly designed, so workarounds are necessary
export function setMentorMarginAPI(authToken: string | null, marginPercent: string, mentorId: string): Observable<any> {
    let headers = undefined;


    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `mentors/set_margin/${mentorId}`,
        {marginPercent: marginPercent},
        new RestQueryParams(),
        headers
    );
}
