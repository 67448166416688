import React from "react";
import {Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import {authTokenSelector, Loader, LoaderType, refreshTokenSelector, Translation} from "educat-common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getAccountAccessAPI} from "../../../../api/getAccountAccess";
import {connect} from "react-redux";
import {RootState} from "../../../../store/reducers";
import styles from "./styles.module.scss";



interface IMentorsTableConnectedProps {
    authToken: string;
    refreshToken: string;
}

interface IMentorsTableExternalProps {
    applicants: any[];
    isLoading: boolean;
    envData: any;
}

interface IMentorsTableProps extends IMentorsTableConnectedProps,
    IMentorsTableExternalProps,
    WithTranslation {
}

interface IMentorsTableState {}

class MentorsTable extends React.Component<IMentorsTableProps, IMentorsTableState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IMentorsTableProps) {
        super(props);

        this.state = {};

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentDidUpdate(
        prevProps: Readonly<IMentorsTableProps>,
        prevState: Readonly<IMentorsTableState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if ((!this.props.applicants ||
                !Array.isArray(this.props.applicants)) ||
            (this.props.applicants.length === 0 && !this.props.isLoading)) {
            return <p>
                <Translation text={'applicants.applicantList.table.noData'}/>
            </p>;
        }


        return (
            <React.Fragment>
                <Loader type={LoaderType.Local} showLoader={this.props.isLoading}/>
                <table className="data-table default-table">
                    <thead>
                    <tr>
                        <th className="highlight-cell">
                            <Translation text={'applicants.applicantList.table.applicantName'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'applicants.applicantList.table.email'}/>
                        </th>
                        {/* <th className="highlight-cell">
                            <Translation text={'applicants.applicantList.table.phone'}/>
                        </th> */}
                        <th className="highlight-cell">
                            <Translation text={'applicants.applicantList.table.actionButtons.title'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    private renderTableRows() {
        const rows: any[] = [];

        this.props.applicants.map((item: any) => {
            let applicant = item.account,
                applicantName = `${applicant.firstName} ${applicant.lastName}`;
            return rows.push((
                <tr key={item.id}>
                    <td className="highlight-cell">
                        {/* <NavLink to={{
                            pathname: `/panel/applicant/${applicant.id}`,
                            state:
                                {id: applicant.id, name: applicantName}
                            }}
                             className={styles.itemLink}> */}
                        {/* </NavLink> */}
                        <div className={styles.aHref} onClick={()=>this.enterAccount(item.account)}>
                            {applicantName}
                        </div>
                    </td>
                    <td>
                        {item?.email}
                    </td>
                    {/* <td>
                        {item?.phone}
                    </td> */}
                    <td className="borderless">
                        {/* TODO: if any actions for Applicant will be available change buttons and uncomment */}
                        {/* <div className={styles.buttonWrapper}>
                            <button type="button" className="btn btn-theme">
                            <Translation text={'applicants.applicantList.table.actionButtons.acceptRegistration'}/>

                            </button>
                            <button type="button" className="btn btn-secondary-theme">
                            <Translation text={'applicants.applicantList.table.actionButtons.acceptOnboarding'}/>

                            </button>
                        </div> */}

                        <div>
                            <Link to={`/panel/applicants/${item.id}`}
                                    className="btn btn-theme btn-small">
                                <Translation text={'applicants.applicantList.table.actionButtons.details'}/>

                            </Link>
                        </div>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private enterAccount(account: any) {
        if (!account?.user?.active) {
            this.alertManager.addAlert('Aby wejść w tryb impersonifikacji, użytkownik musi być aktywny.');
            return;
        }
        getAccountAccessAPI(this.props.authToken, account.id).subscribe((resp:any)=>{
            console.log(resp)
            const accessedAccountToken = resp.refreshToken;
            const adminToken = this.props.refreshToken;
            window.location.replace(this.props.envData.REACT_APP_APPLICANT_URL + `/auth/login/${accessedAccountToken}/${adminToken}`);
        })
    }

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        refreshToken: refreshTokenSelector(state)
    })
)(withTranslation()(MentorsTable));
