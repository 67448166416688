export function exportApplicantsReportAPI(authToken: string): Promise<Blob> {
 /*   return educatAPIFile.get(
        `applicants/export`,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        }
    );*/

    // const response = fetch(' https://localhost:8443/applicants/export', {
    return fetch(process.env.REACT_APP_API_URL + '/applicants/export', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
            "Authorization": `Bearer ${authToken}`
        },
    }).then((response) => response.blob());

}