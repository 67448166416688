import {Form, FormControlChangeType, IFormConfig, isSameValue, Loader,} from 'educat-common-web';
import React from "react";
import {MentorRegistrationSteps, TaskTypeList} from "../../../../../service/mentorRegistrationService";
import {filter, tap} from 'rxjs/operators';
import {BehaviorSubject, Subject, Subscription} from "rxjs";
import {mentorHelpRangeFormConfig} from "./taskTypeBaseControl";

interface IConnectedFormStepHelpRangeProps {}

interface IExternalFormStepHelpRangeProps {
    readonly submitStep?: (stepName: MentorRegistrationSteps, stepValue: any) => void;
    readonly prevStep?: () => void;
    readonly stepData?: any;
    readonly realmList?: {[key: string]: any}[];
    readonly taskTypeList: TaskTypeList;
    readonly mentor: any;
    readonly onFormUpdate: any;
    readonly showValue: Subject<any>;
    readonly updateValidation: any;
}

interface IFormStepHelpRangeProps extends IConnectedFormStepHelpRangeProps, IExternalFormStepHelpRangeProps {}

interface IFormStepHelpRangeState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorRegistrationSteps;
    formConfig: typeof IFormConfig;
}

class FormHelpRange extends React.Component<IFormStepHelpRangeProps, IFormStepHelpRangeState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor(props: IFormStepHelpRangeProps) {
        super(props);

        const value = this.props.stepData;


        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: mentorHelpRangeFormConfig(this.props.mentor, this.props.taskTypeList, this.props.realmList),
            stepValue: value,
            stepName: MentorRegistrationSteps.HELP_RANGE,
        };

        // this.onFormValueChange(data.value, 'init')
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    componentDidMount() {
        if (this.props.taskTypeList) {
            this.setState({formConfig: mentorHelpRangeFormConfig(this.props.mentor, this.props.taskTypeList, this.props.realmList)});
        }

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data),
                    tap((data: any) => {
                        this.onFormValueChange(data.value, data.changeType)
                    }),
                )
                .subscribe()
        );
        this.onValueStateChange$.next({value: {realms:this.getCurrentRealms()}, changeType: 'user'});
    }

    getCurrentRealms(){
        return this.state.formConfig.controls.filter((form:any)=> form.key === 'realms')[0]?.controls?.realms?.defaultValue.map((value:any)=>value.value);
    }

    componentDidUpdate(
        prevProps: Readonly<IFormStepHelpRangeProps>,
        prevState: Readonly<IFormStepHelpRangeState>,
        snapshot?: any
    ): void {
        if (!isSameValue(this.props.taskTypeList, prevProps.taskTypeList)) {
            this.setState({formConfig: mentorHelpRangeFormConfig(this.state.stepValue, this.props.taskTypeList, this.props.realmList)});
        }
    }

    render() {
        return (
            <>
                <Loader showLoader={this.state.isProcessing} />
                <div className="onboarding-form-wrapper">
                    <Form
                        config={this.state.formConfig}
                        controlName={MentorRegistrationSteps.HELP_RANGE}
                        onValueStateChange={this.onValueStateChange}
                        onValidationStateChange={this.formValidityChange}
                        value={this.state.stepValue}/>
                </div>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {

        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
        this.props.onFormUpdate(this.state.stepValue, 'help_range');
    };

    private onFormValueChange = (value: any, changeType: typeof FormControlChangeType) => {
        this.props.onFormUpdate(value, 'help_range');

        if (changeType !== FormControlChangeType.Init && changeType !== FormControlChangeType.User) {
            return;
        }
        if (isSameValue(this.state.stepValue, value)) {
            return;
        }

        const newValue = Object.assign({}, this.state.stepValue, value);
        newValue.realms = this.computeRealms(value, changeType);


        this.setState({stepValue: newValue}, ()=>this.props.onFormUpdate(this.state.stepValue, 'help_range'));
    };

    private computeRealms = (value: any, changeType?: typeof FormControlChangeType) => {

        if (undefined === value?.realms) {
            // new realms hae not been defined yet, restore from state or reset collection
            return this.state.stepValue?.realms || [];
        }
        if (null === value?.realms || value?.realms?.length === 0) {

            if (changeType === 'init'){
                if (!this.getCurrentRealms()){
                    return [];
                } else {
                    return this.getCurrentRealms();
                }
            }
            // new realms are empty, reset collection
            return [];
        }
        if (undefined !== value?.realms?.[0]) {
            // new realms are not empty and contain valid values
            return value.realms;
        }
        if (this.state.stepValue?.realms?.length > 0 && undefined !== this.state.stepValue.realms[0]) {
            return this.state.stepValue.realms;
        }
        return [];
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
            this.props.updateValidation(isValid);
        }
    };
}

export default FormHelpRange;
