import React from 'react';
import {Translation} from 'educat-common-web';

interface IInquiryStatusBadgeProps {
    status: string | undefined;
}

const InquiryStatusBadge: React.FC<IInquiryStatusBadgeProps> = (props: IInquiryStatusBadgeProps) => {

    return (
        <span className={`badge badge-status ${props.status}`}>
            <Translation text={`inquiries.inquiryList.table.statusBadge.${props.status}`}/>
        </span>
    );
};
export default InquiryStatusBadge;
