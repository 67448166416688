import {
    authTokenSelector,
    CustomCard, Price, Translation
} from 'educat-common-web';
import React from 'react';
import {connect} from "react-redux";
import {of, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {getSalesAPI} from '../../api/getSales';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {RootState} from "../../store/reducers";
import SalesTable from "./SalesTable";
import styles from "../Shared/UserListStyles/styles.module.scss";
import {exportSalesExcel} from "../../api/provider/exportSalesExcel";


interface IConnectedSalesProps {
    readonly authToken: string;
}

interface IExternalSalesProps {
}

interface ISalesProps extends IConnectedSalesProps,
    IExternalSalesProps {
}

export type serviceChildItem = {
    serviceName: string;
    isCompleted: boolean;
};

export type SaleItem = {
    applicantFirstName: string,
    applicantLastName: string,
    mentorFirstName: string | null,
    mentorLastName: string | null,
    isPackage: boolean,
    serviceName: string,
    isCompleted: boolean,
    purchasedAt: string,
    price: typeof Price,
    serviceChildren: serviceChildItem[],
};

interface ISalesState {
    salesList: { [key: string]: any }[] | [];
    listMetadata: { [key: string]: any } | null;
    isProcessing: boolean;
}


class Sales extends React.Component<ISalesProps, ISalesState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ISalesProps) {
        super(props);

        this.state = {
            salesList: [],
            listMetadata: null,
            isProcessing: false
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getSales();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={`${styles.usersSection} row`}>
                <div className='col-12'>
                    <CustomCard showLocalLoader={this.state.isProcessing}>
                        <CustomCard.Header>
                            <div className={styles.header}>
                                <h2 className="custom-card-title">
                                    <Translation text="sales.title"/>
                                </h2>
                                <div className={`d-flex flex-row`}>
                                    <button type="button"
                                            className={`btn btn-small btn-theme btn-no-arrow`}
                                            onClick={() => {
                                                this.getSalesExport()
                                            }}
                                    >
                                        <Translation text="sales.buttons.exportList"/>
                                    </button>
                                </div>
                            </div>
                        </CustomCard.Header>

                        <CustomCard.Body>
                            <SalesTable sales={this.state.salesList} isProcessing={false}/>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </section>
        );
    }

    private getSalesExport = () => {
        this.setState({isProcessing: true});
        exportSalesExcel(this.props.authToken)
            .then((blob) => {
                this.alertManager?.addAlert('applicants.applicantView.alert.downloadAccepted');
                this.downloadFile(blob, "salesReport");
                this.setState({isProcessing: false});
            })
            .catch(error => {
                this.setState({isProcessing: false});
                this.alertManager?.addAlert('applicants.applicantView.alert.downloadError');
                console.error('There was an error!', error);
            });
    }

    private downloadFile(blob: any, fileName: string) {
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName + `.xlsx`
        document.body.appendChild(link);
        link.click();
        // link.parentNode.removeChild(link);
        link.remove();
    }

    private getSales = () => {
        this.setState({isProcessing: true});
        return this.subscriptions.push(
            getSalesAPI(this.props.authToken).pipe(
                map((resp: any) => {
                    this.setState({salesList: resp || [], isProcessing: false});
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of(error);
                })
            ).subscribe()
        )
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(Sales);
