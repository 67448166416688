import React from "react";
import {MentorRegistrationSurvey, TaskTypeList} from "../../../../service/mentorRegistrationService";
import FormBasicInformation from "./FormBasicInformation";
import FormStudyInformation from "./FormStudyInformation";
import FormMentorDescription from "./FormMentorDescription";
import FormHelpRange from "./FormHelpRange";
import FormRegistrationInformation from "./FormRegistrationInformation";
import FormClasses from "./FormClasses";
import {deepCloneObject, isNotNullOrUndefined, Loader, Translation} from "educat-common-web";
import styles from "../../../Shared/UserListStyles/styles.module.scss";
import {
    MentorOnboardingSteps, MentorServiceDefinitionPayload
} from "../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {TutoringSubject} from "../../../../api/provider/educatTutoringScopeTypeAPI";
import {deleteTutoringScopeAPI} from "../../../../api/deleteTutoringScope";
import {createTutoringScopeAPI} from "../../../../api/createTutoringScopeType";
import {sendMentorRegistrationSurveyAPI} from "../../../../api/sendMentorRegistrationSurvey";
import {AlertType, IAlertManagerService} from "../../../../service/alertManagerService";
import {updateUserAPI} from "../../../../api/updateUser";
import {setMentorServiceDefinitionsActiveStatusAPI} from "../../../../api/setMentorServiceDefinitionsActiveStatus";
import {sendMentorServiceDefinitionsAPI} from "../../../../api/sendMentorServiceDefinitions";

interface IGeneralInformationEditorProps {
    taskTypeList: TaskTypeList;
    mentor: any;
    tutoringScopesList: {
        multiselectOptions: { [key: string]: any }[];
        subjectsArray: TutoringSubject[];
    };
    languageList: { [key: string]: any }[];
    countryList: { [key: string]: any }[];
    realmList: { [key: string]: any }[];
    authToken: string;
    serviceApplicationElementsList: any[];
    serviceConsultationsList: any[];
}

interface IGeneralInformationEditorState {
    stepValue: any;
    stepName: string;
    isProcessing: boolean;
    rawFormData: any;
    subject: Subject<any>
    isValid: boolean;
}

class GeneralInformationEditor extends React.Component<IGeneralInformationEditorProps, IGeneralInformationEditorState>{

    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            stepValue: null,
            stepName: MentorOnboardingSteps.ONBOARDING_INFORMATION,
            isProcessing: true,
            rawFormData: {},
            subject: new Subject(),
            isValid: true,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setState({isProcessing: false});
    }


    render() {
        return <div style={{height: '100%'}} className='d-flex flex-column mb-2'>
            <Loader showLoader={this.state.isProcessing}/>
            <div className="d-flex justify-content-between fs-2 fw-bold mb-5">
                <h2 className={styles.title}>
                    <Translation text={'mentors.mentorView.generalInformation.title'} />
                </h2>
            </div>

            <FormRegistrationInformation mentor={this.props.mentor} onFormUpdate={this.updateFormData.bind(this)} updateValidation={this.setFormIsValid}/>
            <FormBasicInformation  mentor={this.props.mentor} languageList={this.props.languageList}
                                   onFormUpdate={this.updateFormData.bind(this)} updateValidation={this.setFormIsValid}/>
            <FormStudyInformation countryList={this.props.countryList} mentor={this.props.mentor} onFormUpdate={this.updateFormData.bind(this)}
                                  updateValidation={this.setFormIsValid} stepData={this.convertMentorDataToStudyInformation()}/>
            <FormMentorDescription mentor={this.props.mentor} onFormUpdate={this.updateFormData.bind(this)} updateValidation={this.setFormIsValid}/>
            <FormHelpRange showValue={this.state.subject} mentor={this.props.mentor} taskTypeList={this.props.taskTypeList}
                           realmList={this.props.realmList} onFormUpdate={this.updateFormData.bind(this)} updateValidation={this.setFormIsValid}/>
            <FormClasses mentor={this.props.mentor} tutoringScopesList={this.props.tutoringScopesList}
                         onFormUpdate={this.updateFormData.bind(this)} updateValidation={this.setFormIsValid}/>
            <button
                type="submit"
                disabled={!this.state.isValid}
                onClick={() => {this.updateGeneralInformation()}}
                className="btn btn-theme btn-rounded align-self-end mb-1"
            >

                <Translation text="buttons.save"/>
            </button>


            <div>&nbsp;</div>
        </div>;
    }
    //

    setFormIsValid = (value: boolean) => {
        this.setState({isValid: value})
    }



    updateFormData(data: any, form: string){
        let formData = this.state.rawFormData;
        formData[form] = data
        this.setState({rawFormData: deepCloneObject(formData)});
    }


    private addNewScopes() {
        const allTutoringScopes = this.props.tutoringScopesList;
        const scopesFormData = this.state.rawFormData.classes;
        const subjectId = scopesFormData.classes.subject;

        const selectedSubjectTutoringScopes = allTutoringScopes.subjectsArray.find(scope => scope.id === subjectId);
        if (!selectedSubjectTutoringScopes) {
            return [of(null)];
        }

        let updatedScopes = [];

        if (scopesFormData.exams) {
            let tutScopeItemTypeIds: any[] = [];
            Object.keys(scopesFormData.exams).forEach(key => {
                if (scopesFormData.exams[key]) {
                    tutScopeItemTypeIds.push(key)
                }
            })

            if (tutScopeItemTypeIds.length > 0){
                const examsTutoringScope = {
                    mentorId: this.props.mentor.id,
                    tutoringScopeTypeId: selectedSubjectTutoringScopes.examId,
                    tutoringScopeItemTypeIds: tutScopeItemTypeIds
                }

                updatedScopes.push(examsTutoringScope);
            }
        }


        if (scopesFormData.maturityExams) {
            let tutScopeItemTypeIds: any[] = [];
            Object.keys(scopesFormData.maturityExams).forEach(key => {
                if (scopesFormData.maturityExams[key]) {
                    tutScopeItemTypeIds.push(key)
                }
            })
            if (tutScopeItemTypeIds.length > 0) {
                const maturityExamsTutoringScope = {
                    mentorId: this.props.mentor.id,
                    tutoringScopeTypeId: selectedSubjectTutoringScopes.maturityExamId,
                    tutoringScopeItemTypeIds: tutScopeItemTypeIds
                }
                updatedScopes.push(maturityExamsTutoringScope);
            }
        }

        if (scopesFormData.others) {
            let tutScopeItemTypeIds: any[] = [];
            Object.keys(scopesFormData.others).forEach(key => {
                if (scopesFormData.others[key]) {
                    tutScopeItemTypeIds.push(key)
                }
            })
            if (tutScopeItemTypeIds.length > 0) {
                const othersTutoringScope = {
                    mentorId: this.props.mentor.id,
                    tutoringScopeTypeId: selectedSubjectTutoringScopes.otherId,
                    tutoringScopeItemTypeIds: tutScopeItemTypeIds
                }
                updatedScopes.push(othersTutoringScope);
            }
        }

        const result = updatedScopes.map(scope => createTutoringScopeAPI(this.props.authToken, scope));
        return !!result.length ? result : [of(null)];
    }

    private removeOldScopes() :Observable<any>[] {
        const result = this.props.mentor.tutoringScopes.map(
            (scope:any) => deleteTutoringScopeAPI(this.props.authToken, scope.id)
        );
        return result.length > 0 ? result : [of(null)];
    }

    convertRawDataToPayload(){
        const data = this.state.rawFormData;

        const countries = Object.keys(data.study_information)
            .filter(key=>key.startsWith('study_information_'))
            .map(key=> data.study_information[key].country)

        const schoolStudyFields: any[] = [];
        let studyLevel: string | null = null;

        // const schoolStudyFields =
        Object.keys(data.study_information)
            .filter(key=>key.startsWith('study_information_'))
            .forEach(key=> {
                const value = data.study_information[key];

                if (value.studyLevel && (null === studyLevel || ('bachelor' === studyLevel && 'master' === value.studyLevel))) {
                    // master supersedes bachelor
                    studyLevel = value.studyLevel;
                }

                schoolStudyFields.push({
                    schoolStudyFieldsId: value.fieldOfStudy,
                    status: value.status,
                    statusDescription: value.yearOfStudies ? value.yearOfStudies.toString() : null
                })
            });

        if (studyLevel === 'preparation'){
            studyLevel = 'bachelor';
        }

        const taskTypes = Object.keys(data.help_range)
            .filter(key=>key !== 'realms' && data.help_range[key]);


        const payload: MentorRegistrationSurvey = {
            account: {
                firstName: data.registration_information.firstName, // OK
                lastName: data.registration_information.lastName, // OK
                birthdate: new Date(data.basic_information.birthdate), // OK
                displayName: data.basic_information.displayName, // OK
                address: data.basic_information.address, // OK
                phone: data.registration_information.phone, // OK
                avatarId: this.props.mentor.account.avatar.id, // OK
            },
            shortDescription: null, // OK
            longDescription: data.mentor_description.longDescription, // OK
            videoLink: null, // OK
            programme: data.mentor_description.programme, // OK
            studyLevel: studyLevel, // ok
            countries: countries, // probably OK?
            languages: data.basic_information.languages.map((lang:any)=>lang.value), // OK
            studyFields: [], // ok
            mentorTaskTypes: taskTypes,
            subjects: data.classes.classes.subject ? [data.classes.classes.subject] : [], // OK
            realms: data.help_range.realms, // OK
            mentorSchoolStudyFields: schoolStudyFields, // ok (lol)
            studyFieldNotFound: data.study_information.studyFieldNotFound // ok
        };

        return payload;
    }


    updateGeneralInformation(){
        this.setState({isProcessing: true}, ()=>{
            const payload = this.convertRawDataToPayload();
            forkJoin([
                ...this.setMentorTaskTypesWithoutGroups(),
                ...this.removeOldScopes(),
                ...this.addNewScopes(),
                updateUserAPI(this.props.authToken, this.props.mentor.account.user.id, {login: this.state.rawFormData.registration_information.email}),
                sendMentorRegistrationSurveyAPI(this.props.authToken, this.props.mentor.id, payload)
            ])
                .subscribe({
                next: () => {
                    this.setState({isProcessing: false})
                    window.location.reload();
                    this.alertManager?.addAlert('Zmiany zapisano pomyślnie!', AlertType.SUCCESS);
                },
                error: () => {
                    this.setState({isProcessing: false})

                    this.alertManager?.addAlert('Wystąpił błąd. Odśwież stronę i spróbuj ponownie', AlertType.WARNING);
                }
            })
        })

    }


    convertMentorDataToStudyInformation(){

        let obj:{[key:string]:any} = {
            studyFieldNotFound: this.props.mentor.studyFieldNotFound
        }

        this.props.mentor.mentorSchoolStudyFields.forEach((item:any, index: number)=>{
             obj[`study_information_${index}`] = {
                 country:item.schoolStudyFields.school.countries[0].id,
                 fieldOfStudy:item.schoolStudyFields.id,
                 removeStudyField:null,
                 school:item.schoolStudyFields.school.id,
                 status:item.status,
                 yearOfStudies: item.statusDescription,
                 studyLevel:item.schoolStudyFields.levels.levels[0].name
             }
         })

        return deepCloneObject(obj);
    }


    private setMentorTaskTypesWithoutGroups() {
        let requests: any[] = [];
        let serviceDefinitionsToCreate: MentorServiceDefinitionPayload[] = [];
        let payload: any[] = [];

        const existingMentorDefinitions = this.props.mentor.mentorServiceDefinitions
            .filter((def: any) => isNotNullOrUndefined(def.serviceApplicationElement) && def.serviceApplicationElement.mentorTaskType?.id);

        this.props.taskTypeList.taskTypesWithoutGroups.forEach((el: any) => {

            payload.push({
                definitionId: existingMentorDefinitions
                    .find((def:any)=> {
                        return def.serviceApplicationElement.mentorTaskType.id === el.id
                    })?.id,
                setActive: this.state.rawFormData.help_range[el.id]
            })

            const existingMentorDefinitionsIDs = existingMentorDefinitions
                .map((def: any)=> def.serviceApplicationElement.mentorTaskType.id);


            let appElement = this.getServiceApplicationElementFromMentorTaskTypeId(el.id) ?? null;
            let consultation = this.getConsultationFromMentorTaskTypeId(el.id) ?? null;

            if (!existingMentorDefinitionsIDs.includes(el.id) && this.state.rawFormData.help_range[el.id]){
                serviceDefinitionsToCreate.push({
                    mentorId: this.props.mentor.id,
                    itemPrice: {
                        amount: 999999,
                        currency: {
                            code: 'PLN'
                        }
                    },
                    name: el.name,
                    description: appElement?.description ?? consultation?.description ?? null,
                    serviceApplicationElementId: appElement?.id ?? null,
                    serviceConsultationId: consultation?.id ?? null,
                    serviceApplicationPackageId: null,
                    serviceConsultationPackageId: null
                })
            }
        })


        const anyTasksSelected = !!(this.props.taskTypeList.taskTypeGroups[0].taskTypes.find((el:any)=> this.state.rawFormData.help_range[el.id]));
        {
            let id = this.props.mentor.mentorServiceDefinitions.filter((def:any)=> def?.serviceApplicationElement?.mentorTaskTypeGroup)[0]?.id;
            if (id){
                payload.push({
                    definitionId: this.props.mentor.mentorServiceDefinitions.filter((def:any)=> def?.serviceApplicationElement?.mentorTaskTypeGroup)[0]?.id,
                    setActive: anyTasksSelected
                })
            } else {
                let service = this.props.serviceApplicationElementsList.find((el:any)=>el.mentorTaskTypeGroup);
                serviceDefinitionsToCreate.push({
                    mentorId: this.props.mentor.id,
                    itemPrice: {
                        amount: 999999,
                        currency: {
                            code: 'PLN'
                        }
                    },
                    name: service.name,
                    description: service.description,
                    serviceApplicationElementId: service.id,
                    serviceConsultationId: null,
                    serviceApplicationPackageId: null,
                    serviceConsultationPackageId: null
                })
            }
        }



        requests.push(sendMentorServiceDefinitionsAPI(this.props.authToken, serviceDefinitionsToCreate));


        payload = payload.filter((el:any) => el.definitionId);
        requests.push(setMentorServiceDefinitionsActiveStatusAPI(this.props.authToken, {definitionProperties: payload}, this.props.mentor.id));
        return requests.length > 0 ? requests : [of(null)];
        // return undefined;
    }


    private getServiceApplicationElementFromMentorTaskTypeId(id: any) {
        return this.props.serviceApplicationElementsList.find((el: any) => {
            return el?.mentorTaskType?.id === id;
        });
    }

    private getConsultationFromMentorTaskTypeId(id: any) {
        return this.props.serviceConsultationsList.find((el: any) => {
            return el?.mentorTaskType?.id === id;
        });
    }
}

export default GeneralInformationEditor;