export enum MentorStatus
{
    noAccountRegistrationConfirmation = 'noAccountRegistrationConfirmation',
    noRegistrationSurvey = 'noRegistrationSurvey',
    registrationSurveyApproval = 'registrationSurveyApproval',
    noOnboardingSurvey = 'noOnboardingSurvey',
    onboardingSurveyApproval = 'onboardingSurveyApproval',
    mentorActive = 'mentorActive',
}

export class MentorProps {

    static getStatus(item: any){
        if (!item?.account){
            return;
        }

        if (!item.account.user || !item.account.user.registrationConfirmedAt) {
            return MentorStatus.noAccountRegistrationConfirmation;
        }

        const hasRegistration = !!item.account.address;
        if (!hasRegistration) {
            return MentorStatus.noRegistrationSurvey;
        }

        const isUserActive = item.confirmed;
        if (isUserActive) {
            return MentorStatus.mentorActive;
        }

        const isRegistrationConfirmed = item.onboardingEnabled;
        if (!isRegistrationConfirmed) {
            return MentorStatus.registrationSurveyApproval;
        }

        const hasOnboarding = !!item.mentorOnboarding.residency;
        if (!hasOnboarding) {
            return MentorStatus.noOnboardingSurvey;
        }

        if (!isUserActive) {
            return MentorStatus.onboardingSurveyApproval;
        }

        return MentorStatus.mentorActive;
    }
}
