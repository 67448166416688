import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable, of} from 'rxjs';
import {MentorServiceDefinitionPayload} from "../components/OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";


export function sendMentorServiceDefinitionsAPI(authToken: string | null, payload: MentorServiceDefinitionPayload[]): Observable<any> {
    if (!payload.length) return of(true);
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.post(
        `mentor_service_definitions`,
        {mentorServiceDefinitions: payload},
        new RestQueryParams(),
        headers
    );
}
