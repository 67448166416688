import React, {useEffect, useMemo, useState} from 'react';
import {Form, FormControlChangeType, IFormConfig, OfferStatus} from 'educat-common-web';
import {inquiryListFiltersFormConfig} from "./inquiryListFiltersFormConfig";
import {BehaviorSubject} from "rxjs";
import {filter, tap} from "rxjs/operators";
import i18n from "i18next";
import InquiryGenerateReportButton from "./InquiryGenerateReportButton";
import {IInquiryListFilters} from "../inquiryFiltersUtil";


interface IInquiryListFiltersProps {
    onSubmit: (filters: IInquiryListFilters) => void;
}

const InquiryListFilters: React.FC<IInquiryListFiltersProps> = (props: IInquiryListFiltersProps) => {
    const [formConfig, setFormConfig] = useState<typeof IFormConfig | null>(null),
        currentDate = new Date(),
        firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
        [value, setValue] = useState<IInquiryListFilters | null>({
            createdAt: [firstDayOfMonth, lastDayOfMonth],
            reference: null,
            statuses: null,
        }),
        onValueStateChange$ = useMemo(() => new BehaviorSubject<any>(null), []);

    const setForm = (): void => {
        const offerStatusArray = Object.keys(OfferStatus).map(key => ({
            value: OfferStatus[key as keyof typeof OfferStatus],
            label: i18n.t(`inquiries.inquiryList.filters.statuses.${OfferStatus[key as keyof typeof OfferStatus]}`)
        }));

        const formConfig = inquiryListFiltersFormConfig(offerStatusArray);
        setFormConfig(formConfig);
    };

    useEffect(() => {
        setForm();
    }, []);

    useEffect(() => {
        const subscription = onValueStateChange$.pipe(
            filter((data: any) => data && data.changeType === FormControlChangeType.User),
            tap((data: any) => setValue(data.value)),
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        }
    }, [onValueStateChange$]);

    const onValueStateChange = (controlName: string, value: any, changeType: any) => {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    const onSubmit = () => {
        const filters: IInquiryListFilters = {
            createdAt: value?.createdAt || null,
            reference: value?.reference || null,
            statuses: value?.statuses || null,
        }

        props.onSubmit(filters);
    }

    return (
        <div className="inquiry-list-filters">
            {formConfig && <Form config={formConfig}
                                 onValueStateChange={onValueStateChange}
                                 submitForm={onSubmit}
                                 value={value}
                                 controlName={'inquiryListFiltersForm'}/>}

            <div className="label-mock d-flex gap-4">
                {/*<AxaGenerateReportButton filters={value} />*/}
                <InquiryGenerateReportButton filters={value} />
            </div>
        </div>
    );
};
export default InquiryListFilters;
