import {FormControlType, IFormConfig} from "educat-common-web";


export const addDocumentFormConfig = (shouldResetUploadValue: boolean): typeof IFormConfig => {
    return {
        controlType: 'form',
        class: 'task-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'task_description',
                controlType: 'group',
                class: 'row',
                controls: {
                    document: {
                        hostClass: 'col-xl-12',
                        controlType: 'control',
                        formControlType: FormControlType.FILE_UPLOAD,
                        validationRules: [],
                        placeholder: "",
                        label: 'inquiries.inquiryList.inquirySettingsModal.files.addDocument',
                        isLabelHidden: true,
                        isFileRemovable: true,
                        shouldResetUploadValue: shouldResetUploadValue
                    },
                }
            },
        ]
    }
};
