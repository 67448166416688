import {Price} from 'educat-common-web';
import {isNotNullOrUndefined} from 'educat-common-web';
import {
    CustomCard, Translation
} from 'educat-common-web';
import React from 'react';
import InformationRow from '../../../Shared/InformationRow';
import styles from "../../../Shared/UserListStyles/styles.module.scss";
import {MentorProps, MentorStatus} from "../../../../constants/MentorStatus";


interface IExternalPlatformPricesProps {
    readonly isLoading: boolean;
    readonly services: any;
    readonly mentor: any;
    readonly modalTrigger?: any;
    readonly serviceApplicationElementsList?: any;
}

interface IPlatformPricesProps extends IExternalPlatformPricesProps {
}


class PlatformPrices extends React.Component<IPlatformPricesProps> {
    render() {
        const services = this.props.services;
        if (!services) return <div />;
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Header>
                    <div className={'d-flex justify-content-between'}>
                        <h2 className={styles.title}>
                            <Translation text={'mentors.mentorView.platformPrices.title'} />
                        </h2>
                        { this._renderEditDataBtn(this.props.mentor) }
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div>
                        <table className="data-table">
                            <tbody>
                                {this.renderServicePrices(services)}
                                {this.renderHourPackages(services)}
                                {this.renderServices()}
                                {this.renderServicePackages(services)}
                                {this.renderFreePackages(services)}
                            </tbody>
                        </table>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderServicePrices(services: any) {
        const servicePrices = services.filter((service: any) => isNotNullOrUndefined(service.serviceConsultation));
        if (servicePrices.length === 0) return null;
        return servicePrices.map((service: any) => <InformationRow key={`${service.id}`} keyName={service.name}
            value={<Price price={service.totalGrossPrice} />} />);
    }
    private renderHourPackages(services: any) {
        const hourPackages = services.filter((service: any) => isNotNullOrUndefined(service.serviceConsultationPackage));
        let value = <Translation text={`mentors.mentorView.platformPrices.hourPackages.noData`} />
        if (hourPackages.length > 0) {

            value = <ul className={styles.taskTypes}>
                {hourPackages.map((item: any) => <li key={`${item.name} h`}>{`${item.name} h`}</li>
                )}
            </ul>
        }

        return <InformationRow key={'mentors.mentorView.platformPrices.hourPackages.label'} keyName={'mentors.mentorView.platformPrices.hourPackages.label'}
            value={value} />
    }
    private renderServices() {
        const servicePackages = this.props.serviceApplicationElementsList;
        let value = <Translation text={`mentors.mentorView.platformPrices.services.noData`} />
        if (servicePackages.length > 0) {

            value = <ul className={styles.taskTypes}>
                {servicePackages.map((item: any) => <li key={`${item.name} h`}>{item.name}</li>
                )}
            </ul>
        }

        return <InformationRow key={'mentors.mentorView.platformPrices.services.label'} keyName={'mentors.mentorView.platformPrices.services.label'}
            value={value} />
    }

    private renderFreePackages(services: any) {
        const servicePackages = services.filter((service: any) => {
            return isNotNullOrUndefined(service.serviceApplicationPackage) && service.serviceApplicationPackage.free
        });
        // console.log(servicePackages);

        return <InformationRow key={'mentors.mentorView.platformPrices.freeHelpPackages.label'} keyName={'mentors.mentorView.platformPrices.freeHelpPackages.label'}
            value={<Translation text={`mentors.mentorView.platformPrices.freeHelpPackages.${servicePackages.length > 0}`} />} />
    }

    private _renderEditDataBtn(item: any) {
        return (
            <button type="button"
                    className="btn btn-theme btn-small mb-lg-auto position-relative"
                    style={{top: '-1rem', right: '-1rem'}}
                    hidden={MentorProps.getStatus(item) !== MentorStatus.mentorActive}
                    onClick={() => this.props.modalTrigger()}

            >
                <Translation text={'buttons.edit'}/>
            </button>
        )
    }

    private renderServicePackages(services: any) {
        const servicePackages = services.filter((service: any) => {
            return isNotNullOrUndefined(service.serviceApplicationPackage) && !service.serviceApplicationPackage.free
        });

        let value = <Translation text={`mentors.mentorView.platformPrices.servicePackages.noData`} />
        if (servicePackages.length > 0) {

            value = <ul className={styles.taskTypes}>
                {servicePackages.map((item: any) => <li key={`${item.name} h`}>{item.name}</li>
                )}
            </ul>
        }

        return <InformationRow key={'mentors.mentorView.platformPrices.servicePackages.label'} keyName={'mentors.mentorView.platformPrices.servicePackages.label'}
                               value={value} />
    }
}

export default PlatformPrices;
