import {
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFormConfig,
    isNullOrUndefined,
    isSameValue,
    Translation
} from 'educat-common-web';
import React from 'react';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {fixInjectedProperties, lazyInject} from '../../../../../ioc';
import {
    IOnboardingHelperService,
    MentorOnboardingSteps
} from '../../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService';
import OnboardingFormHeader from '../../../../OnboardingHost/MentorOnboarding/Common/OnboardingFormHeader';
import {mentorHourlyRatesFormConfig} from './mentorHourlyRatesFormConfig';

interface IConnectedFormStepHourlyRatesProps {
}

interface IExternalFormStepHourlyRatesProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData: any;
    readonly serviceApplicationElementsGeneral: any[];
    readonly mentorFee: number;
    readonly trialFee: number;
    readonly hidden: boolean
}

interface IFormStepHourlyRatesProps extends IConnectedFormStepHourlyRatesProps, IExternalFormStepHourlyRatesProps {
}

interface IFormStepHourlyRatesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
}

class FormHourlyRates extends React.Component<IFormStepHourlyRatesProps, IFormStepHourlyRatesState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);

    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;

    constructor(props: IFormStepHourlyRatesProps) {
        super(props);

        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.HOURLY_RATES,
        };


        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );


        if (this.props.serviceApplicationElementsGeneral && this.onboardingHelperService) {
            const groups = this.onboardingHelperService?.createFormGroupsFromServiceApplicationElements(
                'hourly_rates',
                this.props.serviceApplicationElementsGeneral
            );
            let updatedGroups = deepCloneObject(groups);

            // TODO: make validator to 10'ths...
            if (this.props.stepData && this.props.stepData.hourly_rates && Object.keys(this.props.stepData.hourly_rates).length > 0) {
                const hourlyRatesStepData = this.props.stepData['hourly_rates'];
                updatedGroups.map((control: any) => {
                    if (control.hasOwnProperty('controls')) {
                        Object.keys(control.controls).map((key: string) => {
                            const stepDataItem = hourlyRatesStepData[key];
                            if (stepDataItem) {
                                control.controls[key].defaultValue = stepDataItem;
                                control.controls[key].value = stepDataItem;
                                control.controls[key].hostClass = 'col-xl-2 onboarding-table-cell hide-label cell-input';
                            }
                            return control.controls[key];
                        });
                    }

                    return control;
                });

                updatedGroups = this.getRecalculatedGroupPrices(this.props.stepData, updatedGroups);
            }

            this.setState({formConfig: mentorHourlyRatesFormConfig(updatedGroups)});
        }
    }

    componentDidUpdate(prevProps: Readonly<IFormStepHourlyRatesProps>, prevState: Readonly<IFormStepHourlyRatesState>, snapshot?: any): void {

        if (prevProps.stepData !== this.props.stepData){

            if (this.props.serviceApplicationElementsGeneral && this.onboardingHelperService) {

                const groups = this.onboardingHelperService?.createFormGroupsFromServiceApplicationElements(
                    'hourly_rates',
                    this.props.serviceApplicationElementsGeneral
                );
                let updatedGroups = deepCloneObject(groups);
                // TODO: make validator to 10'ths...
                if (this.props.stepData && this.props.stepData.hourly_rates && Object.keys(this.props.stepData.hourly_rates).length > 0) {
                    const hourlyRatesStepData = this.props.stepData['hourly_rates'];
                    updatedGroups.map((control: any) => {
                        if (control.hasOwnProperty('controls')) {
                            Object.keys(control.controls).map((key: string) => {
                                const stepDataItem = hourlyRatesStepData[key];
                                if (stepDataItem) {
                                    control.controls[key].defaultValue = stepDataItem;
                                    control.controls[key].value = stepDataItem;
                                    control.controls[key].hostClass = 'col-xl-2 onboarding-table-cell hide-label cell-input';
                                }
                                return control.controls[key];
                            });
                        }

                        return control;
                    });

                    updatedGroups = this.getRecalculatedGroupPrices(this.props.stepData, updatedGroups);
                }

                this.setState({formConfig: mentorHourlyRatesFormConfig(updatedGroups)});
            }

        }


        if (!isSameValue(this.props.serviceApplicationElementsGeneral, prevProps.serviceApplicationElementsGeneral)) {
            const groups = this.onboardingHelperService?.createFormGroupsFromServiceApplicationElements(
                'hourly_rates',
                this.props.serviceApplicationElementsGeneral
            );
            this.setState({formConfig: mentorHourlyRatesFormConfig(groups)});
        }



    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className={`onboarding-form-wrapper ${this.props.hidden ? 'd-none' : ''}`}>
                    <OnboardingFormHeader hideSubtitle stepName={this.state.stepName}/>
                    <Form
                        config={this.state.formConfig}
                        controlName={MentorOnboardingSteps.HOURLY_RATES}
                        onValueStateChange={this.onValueStateChange}
                        onValidationStateChange={this.formValidityChange}
                        value={{}}
                    />
                    <button
                        type="submit"
                        onClick={() => {
                            this.props.submitStep(MentorOnboardingSteps.HOURLY_RATES, this.state.stepValue)
                        }}
                        className="btn btn-theme btn-rounded align-self-end mb-1 mt-4"
                    >
                        <Translation text="buttons.save"/>
                    </button>
                </div>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onFormValueChange = (value: any) => {
        let updatedFormConfig = deepCloneObject(this.state.formConfig);

        if (value['hourly_rates']) {
            Object.keys(value['hourly_rates'])
                .filter(
                    (key) =>
                        !key.includes('educat_commission_') &&
                        !key.includes('platform_price_') &&
                        !key.includes('vat_') &&
                        !isNullOrUndefined(value['hourly_rates'][key])
                )
                .forEach((key: string) => {

                    const selectedValue = value['hourly_rates'][key];
                    const countedValues = this.onboardingHelperService.countServicePricesFromInput(
                        typeof selectedValue === 'number' ? selectedValue : Number(selectedValue),
                        this.isConsultationTrial(key) ? this.props.trialFee : this.props.mentorFee
                    );

                    updatedFormConfig.controls[0].controls[`educat_commission_${key}`].defaultValue = countedValues.eduCatCommission
                        ? countedValues.eduCatCommission
                        : null;
                    updatedFormConfig.controls[0].controls[`educat_commission_${key}`].value = countedValues.eduCatCommission
                        ? countedValues.eduCatCommission
                        : null;
                    updatedFormConfig.controls[0].controls[`platform_price_${key}`].defaultValue = countedValues.grossPrice
                        ? countedValues.grossPrice
                        : null;
                    updatedFormConfig.controls[0].controls[`platform_price_${key}`].value = countedValues.grossPrice
                        ? countedValues.grossPrice
                        : null;
                    updatedFormConfig.controls[0].controls[`vat_${key}`].defaultValue = countedValues.vat ? countedValues.vat : null;
                    updatedFormConfig.controls[0].controls[`vat_${key}`].value = countedValues.vat ? countedValues.vat : null;
                });
        }
        this.setState({formConfig: updatedFormConfig, stepValue: value['hourly_rates']});
    };

    private getRecalculatedGroupPrices(value: any, groups: any) {
        let updatedGroups = deepCloneObject(groups);
        if (value['hourly_rates']) {
            Object.keys(value['hourly_rates'])
                .filter(
                    (key) =>
                        !key.includes('educat_commission_') &&
                        !key.includes('platform_price_') &&
                        !key.includes('vat_') &&
                        !isNullOrUndefined(value['hourly_rates'][key])
                )
                .forEach((key: string) => {
                    const selectedValue = value['hourly_rates'][key];
                    const countedValues = this.onboardingHelperService.countServicePricesFromInput(
                        typeof selectedValue === 'number' ? selectedValue : Number(selectedValue),
                        this.isConsultationTrial(key) ? this.props.trialFee : this.props.mentorFee
                    );

                    updatedGroups[0].controls[`educat_commission_${key}`].defaultValue = countedValues.eduCatCommission
                        ? countedValues.eduCatCommission
                        : null;
                    updatedGroups[0].controls[`educat_commission_${key}`].value = countedValues.eduCatCommission
                        ? countedValues.eduCatCommission
                        : null;
                    updatedGroups[0].controls[`platform_price_${key}`].defaultValue = countedValues.grossPrice
                        ? countedValues.grossPrice
                        : null;
                    updatedGroups[0].controls[`platform_price_${key}`].value = countedValues.grossPrice
                        ? countedValues.grossPrice
                        : null;
                    updatedGroups[0].controls[`vat_${key}`].defaultValue = countedValues.vat ? countedValues.vat : null;
                    updatedGroups[0].controls[`vat_${key}`].value = countedValues.vat ? countedValues.vat : null;
                });
        }
        return updatedGroups;
    }

    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };

    private isConsultationTrial(key: string) {
        const serviceDefinition = this.props.serviceApplicationElementsGeneral.find(service => key.includes(service.id));
        return serviceDefinition?.type == 'consultation_trial';
    }
}

export default FormHourlyRates;
