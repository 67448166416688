import React from 'react';
import {BasicModal,} from 'educat-common-web';
import InquiryViewCard from "./InquiryViewCard";
import InquiryEditCard from "./InquiryEditCard";

export enum InquiryViewEditModalType {
    View = 'view',
    Edit = 'edit',
}

interface IInquiryViewEditModalProps {
    isModalVisible: boolean;
    closeModal: () => void;
    inquiry: any | null;
    type: InquiryViewEditModalType | null;
    changeModalType: (e:InquiryViewEditModalType) => void;
    getInquiriesList?: () => void;
}

const InquiryViewEditModal: React.FC<IInquiryViewEditModalProps> = (props: IInquiryViewEditModalProps) => {

    const changeModalType = (type: InquiryViewEditModalType) => {
        props.changeModalType(type);
    }

    return (
        <BasicModal isModalShown={props.isModalVisible}
                    modalContentContainerClassName={'edit-view-inquiry-modal'}
                    closeModal={props.closeModal}>

            {props.type === InquiryViewEditModalType.View ?
                <InquiryViewCard inquiry={props.inquiry}
                                 closeModal={props.closeModal}
                                 changeModalType={() => changeModalType(InquiryViewEditModalType.Edit)}/> :
                <InquiryEditCard inquiry={props.inquiry}
                                 closeModal={props.closeModal}
                                 getInquiriesList={props.getInquiriesList}
                                 changeModalType={() => changeModalType(InquiryViewEditModalType.View)}/> }
        </BasicModal>
    );
};
export default InquiryViewEditModal;
