import {Form, IFormConfig, isSameValue} from "educat-common-web";
import React from "react";
import {MentorRegistrationSteps} from "../../../../../service/mentorRegistrationService";
import {
    mentorDescriptionFormConfig
} from "./mentorDescriptionFormConfig";

interface IConnectedFormStepMentorDescriptionProps {}

interface IExternalFormStepMentorDescriptionProps {
    readonly submitStep: (stepName: MentorRegistrationSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData?: any;
    readonly mentor: any;
    readonly onFormUpdate: any;
    readonly updateValidation: any;
}

interface IFormStepMentorDescriptionProps
    extends IConnectedFormStepMentorDescriptionProps,
        IExternalFormStepMentorDescriptionProps {}

interface IFormStepMentorDescriptionState {
    isStepValid: boolean;
    stepValue: any;
    stepName: MentorRegistrationSteps;
    formConfig: typeof IFormConfig;
}

class FormMentorDescription extends React.Component<any,any> {
    constructor(props: IFormStepMentorDescriptionProps) {
        super(props);

        this.state = {
            isStepValid: false,
            formConfig: mentorDescriptionFormConfig(this.props.mentor),
            stepValue: null,
            stepName: MentorRegistrationSteps.MENTOR_DESCRIPTION,
        };
    }

    componentDidMount() {
        if (this.props.stepData) {
            const updatedFormConfig = mentorDescriptionFormConfig(this.props.stepData);
            this.setState({formConfig: updatedFormConfig, stepValue: this.props.stepData});
        }
        this.setState({stepValue: this.state.stepValue});
    }

    componentDidUpdate(
        prevProps: Readonly<IFormStepMentorDescriptionProps>,
        prevState: Readonly<IFormStepMentorDescriptionState>,
        snapshot?: any
    ): void {
        if (this.state.stepValue && !isSameValue(this.state.stepValue, prevState.stepValue)) {
        }
    }

    render() {
        return (
            <Form
                config={this.state.formConfig}
                controlName={MentorRegistrationSteps.MENTOR_DESCRIPTION}
                onValueStateChange={this.formChangeHandler}
                onValidationStateChange={this.formValidityChange}
                value={this.state.stepValue}
            />
        );
    }

    private formChangeHandler = (controlName: MentorRegistrationSteps, value: any, changeType: string) => {

        // if (changeType !== "init") {
        this.setState({stepName: controlName, stepValue: value});
        this.props.onFormUpdate(value, 'mentor_description');
        // }
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
            this.props.updateValidation(isValid);
        }
    };
}

export default FormMentorDescription;
