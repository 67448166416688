import {
    CustomCard, DateComponent, Translation
} from 'educat-common-web';
import {IModelMentorTaskType} from 'educat-common-web/dist/model/mentor';
import React from 'react';
import InformationRow from '../../../Shared/InformationRow';
import styles from "../../../Shared/UserListStyles/styles.module.scss";
import {MentorProps, MentorStatus} from "../../../../constants/MentorStatus";


interface IExternalGeneralInformationProps {
    readonly isLoading: boolean;
    readonly mentor: any;
    readonly modalTrigger: any;
}

interface IGeneralInformationProps extends IExternalGeneralInformationProps {
}


class GeneralInformation extends React.Component<IGeneralInformationProps> {

    render() {
        const mentor = this.props.mentor
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Header>
                    <div className={'d-flex justify-content-between'}>
                        <h2 className={`d-inline ${styles.title}`}>
                            <Translation text={'mentors.mentorView.generalInformation.title'} />
                        </h2>
                        { this._renderEditDataBtn(mentor) }
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div>
                        <table className="data-table">
                            <tbody>
                                <InformationRow key={'firstName'}
                                    keyName={'mentors.mentorView.generalInformation.firstName'}
                                    value={mentor?.account?.firstName} />
                                <InformationRow key={'lastName'}
                                    keyName={'mentors.mentorView.generalInformation.lastName'}
                                    value={mentor?.account?.lastName} />
                                <InformationRow key={'displayableName'}
                                    keyName={'mentors.mentorView.generalInformation.displayableName'}
                                    value={mentor?.account?.displayableName} />
                                <InformationRow key={'email'}
                                    keyName={'mentors.mentorView.generalInformation.email'}
                                    value={mentor?.account?.user?.login} />
                                <InformationRow key={'phone'}
                                    keyName={'mentors.mentorView.generalInformation.phone'}
                                    value={mentor?.account?.phone} />
                                <InformationRow key={'studyLevel'}
                                    keyName={'mentors.mentorView.generalInformation.studyLevel'}
                                    value={mentor?.studyLevel ?
                                        <Translation
                                            text={`mentors.mentorView.studyLevel.${mentor.studyLevel}`} /> :
                                        null} />
                                <InformationRow key={'countries'}
                                    keyName={'mentors.mentorView.generalInformation.countries'}
                                    value={mentor?.countries
                                        ?.map((item: any, index: any) =>{
                                            return item.id
                                                ? <span key={item.id+index}><Translation text={`country.${item.id}`}/>{index === mentor.countries.length-1 ? '':', '}</span>
                                                : null
                                    })}
                                />

                                {this.renderMentorStudyFields(mentor)}

                                {this.renderMentorTaskTypes(mentor?.mentorTaskTypes)}

                                {this.renderMentorRealms(mentor?.realms)}

                                <InformationRow key={'birthdate'}
                                    keyName={'mentors.mentorView.generalInformation.birthdate'}
                                    value={mentor?.account?.birthdate ? <DateComponent date={mentor?.account?.birthdate} /> : null} />

                                <InformationRow key={'address'}
                                    keyName={'mentors.mentorView.generalInformation.address'}
                                    value={mentor?.account?.address} />


                                <InformationRow key={'languages'}
                                    keyName={'mentors.mentorView.generalInformation.languages'}
                                    value={mentor?.languages
                                        ?.map((item: any, index: number) => {
                                            return item.id
                                                ? <span key={item.id+index}><Translation text={`language.${item.id}`}/>{index === mentor.languages.length-1 ? '':', '}</span>
                                                : null
                                        })} />

                                <InformationRow key={'shortDescription'}
                                    keyName={'mentors.mentorView.generalInformation.shortDescription'}
                                    value={mentor?.longDescription} />


                                <InformationRow key={'programme'}
                                    keyName={'mentors.mentorView.generalInformation.programme'}
                                    value={mentor?.programme} />


                                <InformationRow key={'classes'}
                                    keyName={'mentors.mentorView.generalInformation.classes'}
                                    value={mentor?.classes} />
                            </tbody>
                        </table>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );

    }
    private renderMentorStudyFields(mentor: any) {
        if (mentor?.mentorSchoolStudyFields?.length > 0) {
            return mentor.mentorSchoolStudyFields.map((item: any, index: number) => {
                index = index+1;
                return <React.Fragment key={'fragment_mentorSchoolStudyFields.'+index}>
                    <InformationRow key={'mentors.mentorView.generalInformation.university.'+index}
                        keyName={'mentors.mentorView.generalInformation.university'}
                        keyNameOption={{index: `${index}`}}
                        value={item.schoolStudyFields.school.name} />

                    <InformationRow key={item.schoolStudyFields.studyField.id+'.'+index}
                        keyName={'mentors.mentorView.generalInformation.fieldOfStudy.label'}
                        keyNameOption={{index: `${index}`}}
                        value={<>
                            <span>{item.schoolStudyFields.studyField.name}</span>
                            <Translation text={`mentors.mentorView.generalInformation.fieldOfStudy.${item.schoolStudyFields.duration.unit === "months" ? "durationMonth" : "durationYear"}`}
                                config={{duration: item.schoolStudyFields.duration.length}} />
                            <span>{mentor.mentorSchoolStudyFields.length !== index + 1 ? ', ' : ''}</span>
                        </>} />

                    <InformationRow key={'mentors.mentorView.generalInformation.status.label.'+index}
                        keyName={'mentors.mentorView.generalInformation.status.label'}
                        keyNameOption={{index: `${index}`}}
                        value={<Translation
                            text={`mentors.mentorView.generalInformation.status.${item.status}`} />} />

                </React.Fragment>
            });
        }
    }
    private renderMentorTaskTypes(taskTypes: IModelMentorTaskType[] | undefined | null) {
        let value = <Translation text={`mentors.mentorView.generalInformation.helpRange.noData`} />

        if (taskTypes && taskTypes.length > 0) {
            value = <ul className={styles.taskTypes}>
                {taskTypes.map((item: IModelMentorTaskType, index: number) => {
                    return <li key={`${item.name}_${index}`}>
                        {item.mentorTaskTypeGroup === null ? item.name : `${item.mentorTaskTypeGroup.name}: ${item.name}`}
                        <span>{taskTypes.length !== index + 1 ? ', ' : ''}</span>
                     </li>
                })}
            </ul>
        }
        return <InformationRow key={'mentors.mentorView.generalInformation.helpRange.label'} keyName={'mentors.mentorView.generalInformation.helpRange.label'}
            value={value} />

    }
    private renderMentorRealms(realms: any[] | undefined | null) {
        let value = <Translation text={`mentors.mentorView.generalInformation.realms.noData`} />
        if (realms && realms.length > 0) {
            value = <ul className={styles.taskTypes}>
                {realms.map((item: IModelMentorTaskType, index: number) => {
                    return item.name ? <li key={`${item.name}_${index}`}>{`${item.name}${index + 1 !== realms.length ? ', ' : ''}`} </li> : null
                })}
            </ul>
        }

        return <InformationRow key={'mentors.mentorView.generalInformation.realms.label'} keyName={'mentors.mentorView.generalInformation.realms.label'}
            value={value} />
    }


    private _renderEditDataBtn(item: any) {
        return (
            <button type="button"
                    className="btn btn-theme btn-small mb-lg-auto position-relative"
                    style={{top: '-1rem', right: '-1rem'}}
                    hidden={MentorProps.getStatus(item) !== MentorStatus.mentorActive}
                    onClick={() => this.props.modalTrigger()}
            >
                <Translation text={'buttons.edit'}/>
            </button>
        )
    }
}

export default GeneralInformation;
