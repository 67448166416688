import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";
import {Form, IFormConfig} from "educat-common-web";
import {profileFormConfig} from "./profileFormConfig";


interface IConnectedProfileProps {}

interface IExternalProfileProps {}

interface IProfileProps extends IConnectedProfileProps,
    IExternalProfileProps,
    RouteComponentProps {
}

interface IProfileState {
    value: any;
    formConfig: typeof IFormConfig;
}


class Profile extends React.Component<IProfileProps, IProfileState> {
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: profileFormConfig
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xl-4">
                        <div className="user-img">
                            <div className="image-placeholder">
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <p>annakowalska@onet.pl</p>
                        <p>+48666777999</p>
                        <p>3/02/2005</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xx-l6">
                        <h5>Profile details</h5>
                        <Form config={this.state.formConfig}
                            // btnText="Save"//
                              value={this.state.value}
                              controlName={'clinicDetailsForm'}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Profile);
