import React, {useEffect, useState} from 'react';
import {
    Translation,
    handleApiError,
    addAlert,
    AlertObject,
    authTokenSelector,
    RestQueryParams,
    Loader,
    LoaderType,
    MultiSelect,
    MultiSelectType,
    InputBasic,
    InputType
} from 'educat-common-web';
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {useDispatch, useSelector} from "react-redux";
import {getUsersAPI} from "../../../../../api/getUsersAPI";
import {useTranslation} from "react-i18next";


interface IInquiryEmailProps {
    inquiryEmail: string;
    updateInquiry: (emailValue: string | undefined) => void;
}

const InquiryEmail: React.FC<IInquiryEmailProps> = ({inquiryEmail, updateInquiry}) => {
    const {t} = useTranslation(),
        dispatch = useDispatch(),
        authToken: string | null = useSelector(authTokenSelector),
        [isLoading, setIsLoading] = useState<boolean>(false),
        [selectedUsername, setSelectedUsername] = useState<{value: string; label: string} | null>(null),
        [newUsername, setNewUsername] = useState<string | null>(null),
        [usersList, setUsersList] = useState<any[]>([]);

    const subscriptions: Subscription[] = [];

    useEffect(() => {
        getUsersList();

        return() => {
            if (subscriptions) {
                subscriptions.forEach(subscription => {
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (usersList && usersList.length) {
            const hasUserEmail = usersList.some(user => user.value === inquiryEmail);

            hasUserEmail ? setSelectedUsername({value: inquiryEmail, label: inquiryEmail}) : setNewUsername(inquiryEmail);
        }
    }, [usersList, inquiryEmail])

    const getUsersList = (username?: string) => {
        let params = new RestQueryParams()
            .add('properties[]', 'login')
            .add('properties[account][]', 'firstName')
            .add('properties[account][]', 'lastName');

        if (username) {
            params = params.add('login[]', username);
        }

        subscriptions.push(
            getUsersAPI(authToken, params).pipe(
                map((resp) => {
                    const selectList = resp['hydra:member'].map((item: any) => {
                        return {
                            value: item.login,
                            label: item.login,
                            additionalData: `${item.account.firstName} ${item.account.lastName}`
                        }
                    })

                    setUsersList(selectList);
                }),
                catchError((error) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    const isEmailValid = (value: any) => {
        let re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return value === null || re.test(String(value).toLowerCase());
    };

    return (
        <div className='inquiry-email-edit mb-5'>
            <p className="title mb-5">
                <Translation text={'inquiries.inquiryList.editModal.editEmail.title'}/>
            </p>

            <div className="d-flex flex-1 control-row mb-5">
                <div className="form-control">
                    <label htmlFor={'select_users'} className={"form-label"}>
                        <Translation text={"inquiries.inquiryList.editModal.editEmail.selectUserEmail"} />
                    </label>
                    <MultiSelect
                        multiselectType={MultiSelectType.SINGLE}
                        handleChange={(e: any) => setSelectedUsername(e)}
                        options={usersList}
                        name={'select_users'}
                        placeholder={t('inquiries.inquiryList.editModal.editEmail.selectUserEmailPlaceholder')}
                        value={selectedUsername}
                        handleInputChange={(data: any) => getUsersList(data)}
                        isComponentCustom={true}
                        isCustomUsernameOption={true}
                        isClearable={true}
                    />
                </div>
                <button className="btn btn-theme btn-small ms-5"
                        disabled={!selectedUsername}
                        onClick={() => {
                            console.log('on click', selectedUsername)
                            updateInquiry(selectedUsername?.value)}}>
                    <Translation text={'inquiries.inquiryList.editModal.editEmail.changeEmail'} />
                </button>
            </div>

            <div className="d-flex flex-1 control-row ">
                <div className={`form-control ${isEmailValid(newUsername) ? '' : 'invalid'}`}>
                    <label htmlFor={'newUsername'} className={"form-label"}>
                        <Translation text={"inquiries.inquiryList.editModal.editEmail.selectNewEmail"} />
                    </label>
                    <InputBasic value={newUsername}
                                name={'newUsername'}
                                placeholder={t('inquiries.inquiryList.editModal.editEmail.selectNewEmailPlaceholder')}
                                type={InputType.EMAIL}
                                handleChange={(e: any) => {
                                    setNewUsername(e.target.value);
                                }}
                    />
                    <div className={'error-block'}></div>
                </div>
                <button className="btn btn-theme btn-small ms-5"
                        disabled={!newUsername}
                        onClick={() => {
                            if (newUsername) {
                                updateInquiry(newUsername)
                            }
                        }}>
                   <Translation text={'inquiries.inquiryList.editModal.editEmail.changeEmail'} />
                </button>
            </div>

            <Loader type={LoaderType.Local} showLoader={isLoading}/>
        </div>
    );
};
export default InquiryEmail;
