import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { createBrowserHistory } from 'history';
import {ConnectedRouter} from "connected-react-router";
import store, { persistor } from "./store";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import * as serviceWorker from './serviceWorker';
import routes from "./routes/routes";
import {Switch} from 'react-router-dom';
import "./i18n";
import Modal from "react-modal";

const history = createBrowserHistory();
Modal.setAppElement('#root');

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
                <Switch>
                    { routes }
                </Switch>
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
