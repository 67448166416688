import React from 'react';
import {
    BasicModal,
    CustomCard,
    Translation,
    CustomCardType,
    authTokenSelector,
} from 'educat-common-web';
import {useSelector} from "react-redux";

interface IInquiryCreateModalProps {
    closeModal: () => void;
}

const InquiryCreateModal: React.FC<IInquiryCreateModalProps> = (props: IInquiryCreateModalProps) => {
    const authToken: string | null = useSelector(authTokenSelector);
    const baseUrl = process.env.REACT_APP_AXA_INSURANCE_URL,
        queryParams = new URLSearchParams();

    queryParams.append('isAdmin', 'true');
    if (authToken) {
        queryParams.append('authToken', authToken);
    }

    const iframeSrc = `${baseUrl}/pl/inquiry/data?${queryParams.toString()}`;

    return (
        <BasicModal isModalShown={true}
                    modalContentContainerClassName={'edit-insurance-modal'}
                    wrapperCustomStyles={'add-inquiry-modal'}
                    closeModal={props.closeModal}>
            <CustomCard type={CustomCardType.MODAL_CARD}>
                <CustomCard.Header>
                    <div className="d-flex justify-content-between">
                        <p>
                            <Translation text={'inquiries.inquiryList.createModal.title'}/>
                        </p>
                        <button onClick={props.closeModal}>
                            <span className="feather icon-x"/>
                        </button>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div className={'inquiry-wrapper'}>
                        <iframe title="Admin Axa Insurance Form"
                                src={iframeSrc}/>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        </BasicModal>
    );
};
export default InquiryCreateModal;
