import {educatAPI, RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';


export function setMentorServiceDefinitionsActiveStatusAPI(authToken: string | null, payload: any, mentorId: string): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.put(
        `mentors/${mentorId}/set_service_definition_active_status`,
        payload,
        new RestQueryParams(),
        headers
    );
}
