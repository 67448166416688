import {
    CustomCard, Translation
} from 'educat-common-web';
import React from 'react';
import InformationRow from '../../../Shared/InformationRow';
import styles from "../../../Shared/UserListStyles/styles.module.scss";


interface IExternalApplicantInformationProps {
    readonly isLoading: boolean;
    readonly applicant: any;
    readonly additionalApplicantData: any;
}

interface IApplicantInformationProps extends IExternalApplicantInformationProps {
}


class ApplicantInformation extends React.Component<IApplicantInformationProps> {
    render() {
        const applicant = this.props.applicant,
            additionalApplicantData = this.props.additionalApplicantData;
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Header>
                    <h2 className={styles.title}>
                        <Translation text={'applicants.applicantView.generalInformation.title'} />
                    </h2>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div>
                        <table className="data-table">
                            <tbody>
                                <InformationRow key={'firstName'}
                                    keyName={'applicants.applicantView.generalInformation.firstName'}
                                    value={applicant?.account?.firstName} />
                                <InformationRow key={'lastName'}
                                    keyName={'applicants.applicantView.generalInformation.lastName'}
                                    value={applicant?.account?.lastName} />

                                <InformationRow key={'email'}
                                    keyName={'applicants.applicantView.generalInformation.email'}
                                    value={additionalApplicantData?.email} />
                                <InformationRow key={'phone'}
                                    keyName={'applicants.applicantView.generalInformation.phone'}
                                    value={additionalApplicantData?.phone} />
                                <InformationRow key={'countries'}
                                    keyName={'applicants.applicantView.generalInformation.countries'}
                                    value={applicant?.countries
                                        ?.map((item: any) =>
                                            item.id ?
                                                <Translation key={item.id}
                                                    text={`country.${item.id}`} /> :
                                                null)} />
                                {this.renderApplicantStudyFields(applicant)}
                                <InformationRow key={'studyLevel'}
                                    keyName={'applicants.applicantView.generalInformation.studyLevel.title'}
                                    value={applicant?.studyLevel ?
                                        <Translation
                                            text={`applicants.applicantView.generalInformation.studyLevel.${applicant.studyLevel}`} /> :
                                        null} />
                                <InformationRow key={'baccalaureateType'}
                                    keyName={'applicants.applicantView.generalInformation.baccalaureateType.title'}
                                    value={applicant?.baccalaureateType ?
                                        <Translation
                                            text={`applicants.applicantView.generalInformation.baccalaureateType.${applicant.baccalaureateType}`} /> :
                                        null} />
                                <InformationRow key={'studyStartYear'}
                                    keyName={'applicants.applicantView.generalInformation.studyStartYear'}
                                    value={applicant?.studyStartYear ? applicant.studyStartYear : null} />
                                <InformationRow key={'origin'}
                                    keyName={'applicants.applicantView.generalInformation.applicationOrigin.title'}
                                    value={applicant?.origin ?
                                        <Translation
                                            text={`applicants.applicantView.generalInformation.applicationOrigin.${applicant.origin}`} /> :
                                        null} />
                                {this.renderBaccalaureateSubjects(applicant?.applicantBaccalaureateSubjects)}
                            </tbody>
                        </table>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private renderBaccalaureateSubjects(baccalaureateSubjects: any) {
        if (!baccalaureateSubjects?.length) return null;
        return <>
            <tr>
                <td colSpan={2} className="data-table-title data-table-highlight">
                    <h3>
                        <Translation text="applicants.applicantView.generalInformation.baccalaureateSubjects.title" />
                    </h3>
                </td>

            </tr>
            {baccalaureateSubjects.map((subjectItem: any, index: number) =>
                <InformationRow key={`baccalaureateSubjects${index}`}
                    keyName={subjectItem.subject?.name ? subjectItem.subject?.name : 'applicants.applicantView.generalInformation.baccalaureateSubjects.noData'}
                    value={
                        <Translation
                            text={`applicants.applicantView.generalInformation.baccalaureateSubjects.${subjectItem.level ? subjectItem.level : 'noData'}`} />} />
            )}
        </>
    }

    private renderApplicantStudyFields(applicant: any) {
        if (applicant?.applicantSchoolStudyFields?.length > 0) {
            return applicant.applicantSchoolStudyFields.map((item: any, index: number) => {
                return <React.Fragment key={'fragment_applicantSchoolStudyFields'}>
                    <InformationRow key={'applicants.applicantView.generalInformation.university'}
                        keyName={'applicants.applicantView.generalInformation.university'}
                        keyNameOption={{index: `${index}`}}
                        value={item.schoolStudyFields.school.name} />

                    <InformationRow key={item.schoolStudyFields.studyField.id}
                        keyName={'applicants.applicantView.generalInformation.fieldOfStudy.label'}
                        keyNameOption={{index: `${index}`}}
                        value={<>
                            <span>{item.schoolStudyFields.studyField.name}</span>
                            <Translation text={`applicants.applicantView.generalInformation.fieldOfStudy.${item.schoolStudyFields.duration.unit === "months" ? "durationMonth" : "durationYear"}`}
                                config={{duration: item.schoolStudyFields.duration.length}} />
                            <span>{applicant.schoolStudyFields?.length !== index + 1 ? ', ' : ''}</span>
                        </>} />
                </React.Fragment>
            });
        }


    }
}


export default ApplicantInformation;
