import {Observable} from 'rxjs';
import {educatAPI} from './provider/educatAPI';
import {RestQueryParams} from "educat-common-web";

export function getAccountAccessAPI(authToken: string | null, accountId: any): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `accounts/${accountId}/get_access`,
        new RestQueryParams(),
        headers
    );
}
