import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules
} from 'educat-common-web';

export const inquirySettingsFormConfig: typeof IFormConfig = () => {
    const validationRules: any[] = [
        {name: ValidationRules.IS_REQUIRED},
    ];

    return {
        class: 'variants-settings-form',
        dataAccessor: (data: any) => data, // data is not grouped
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);

            return previousStateSnapshot;
        },
        controls: [
            {
                controlType: 'group',
                key: 'essential',
                class: 'row mb-4',
                controls: {
                    essentialEurope: {
                        hostClass: 'col-md-6 form-control required',
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        minValue: 0,
                        label: 'Essential Europe [EUR]',
                        type: InputType.NUMBER,
                        inputDataMapper: (data: any) => {
                            if (!data) return null;
                            return Number(data);
                        },
                        outputDataMapper: (data: any) => {
                            return Number(data);
                        },
                    },
                    essentialWorld: {
                        hostClass: 'col-md-6 form-control required',
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        minValue: 0,
                        label: 'Essential World [EUR]',
                        type: InputType.NUMBER,
                        inputDataMapper: (data: any) => {
                            if (!data) return null;
                            return Number(data);
                        },
                        outputDataMapper: (data: any) => {
                            return Number(data);
                        },
                    },
                }
            },
            {
                controlType: 'group',
                key: 'premium',
                class: 'row mb-4',
                controls: {
                    premiumEurope: {
                        hostClass: 'col-md-6 form-control required',
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        minValue: 0,
                        label: 'Premium Europe [EUR]',
                        type: InputType.NUMBER,
                        inputDataMapper: (data: any) => {
                            if (!data) return null;
                            return Number(data);
                        },
                        outputDataMapper: (data: any) => {
                            return Number(data);
                        },
                    },
                    premiumWorld: {
                        hostClass: 'col-md-6 form-control required',
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        minValue: 0,
                        label: 'Premium World [EUR]',
                        type: InputType.NUMBER,
                        inputDataMapper: (data: any) => {
                            if (!data) return null;
                            return Number(data);
                        },
                        outputDataMapper: (data: any) => {
                            return Number(data);
                        },
                    },
                }
            },
            {
                controlType: 'group',
                key: 'allInclusive',
                class: 'row mb-4',
                controls: {
                    allInclusiveEurope: {
                        hostClass: `col-md-6 form-control required`,
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        minValue: 0,
                        label: 'All Inclusive Europe [EUR]',
                        type: InputType.NUMBER,
                        inputDataMapper: (data: any) => {
                            if (!data) return null;
                            return Number(data);
                        },
                        outputDataMapper: (data: any) => {
                            return Number(data);
                        },
                    },
                    allInclusiveWorld: {
                        hostClass: 'col-md-6 form-control required',
                        controlType: 'control',
                        formControlType: FormControlType.INPUT,
                        validationRules: [{name: ValidationRules.IS_REQUIRED}],
                        minValue: 0,
                        label: 'All Inclusive World [EUR]',
                        type: InputType.NUMBER,
                        inputDataMapper: (data: any) => {
                            if (!data) return null;
                            return Number(data);
                        },
                        outputDataMapper: (data: any) => {
                            return Number(data);
                        },
                    },
                }
            },
            {
                controlType: 'group',
                key: 'variants-settings-form-btn-submit',
                class: 'row',
                controls: {
                    submitButton: {
                        controlType: 'control',
                        formControlType: FormControlType.BUTTON,
                        buttonType: 'submit',
                        inputStyles: 'btn-small',
                        defaultContainerStyles: '',
                        hostClass: 'd-flex align-items-end',
                        defaultValue: null,
                        btnText: 'buttons.update',
                        buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                            return !isFormValid;
                        }
                    },
                },
            },
        ]
    };
};
