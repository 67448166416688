import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard, InputBasic, InputType,
    Pagination,
    Translation
} from 'educat-common-web';
import styles from "../../Shared/UserListStyles/styles.module.scss";
import MentorsTable from "./MentorsTable";
import {catchError, debounceTime, filter, map, tap} from "rxjs/operators";
import {confirmMentorOnboardingAPI} from '../../../api/confirmMentorOnboarding';
import {enableMentorOnboardingAPI} from '../../../api/enableMentorOnboarding';
import {getAdminMentorsAPI} from '../../../api/getAdminMentors';
import {RestQueryParams} from 'educat-common-web';
import {exportMentorsAPI} from "../../../api/exportMentors";
import {IFileManagerService} from "../../../service/fileManagerService";
import {exportMentorRatesAPI} from "../../../api/exportMentorsRates";
import FormInput from "../../Shared/FormInput";

export type PaginationData = {
    itemsPerPage: number;
    page: number;
    showDeactivated?: boolean;
};

export type FilterData = {
    firstName: string;
    lastName: string;
    email: string;
}

interface IConnectedMentorListProps {
    readonly authToken: string;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalMentorListProps {
    envData: any;
}

interface IMentorListProps extends IConnectedMentorListProps,
    IExternalMentorListProps,
    RouteComponentProps {
}

interface IMentorListState {
    searchValue: string;
    mentorList: { [key: string]: any }[] | [];
    listMetadata: { [key: string]: any } | null;
    isProcessing: boolean;
    areListedAccountsActive: boolean;
    filters: FilterData;
}


class MentorList extends React.Component<IMentorListProps, IMentorListState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    @lazyInject('FileManagerService') private fileManager: IFileManagerService | undefined;

    constructor(props: IMentorListProps) {
        super(props);

        this.state = {
            searchValue: '',
            mentorList: [],
            listMetadata: null,
            isProcessing: false,
            areListedAccountsActive: true,
            filters: {
                firstName: '',
                lastName: '',
                email: ''
            }
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        if (sessionStorage.getItem('panel') == 'applicants'){
            sessionStorage.removeItem('panel');
            this.props.history.push('/panel/applicants');
        }
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.applicants', path: '/panel/applicants'}
        ]);

        this.getMentorList({page: 1, itemsPerPage: 10, showDeactivated: false});
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data),
                debounceTime(100),
                tap((data: any) => this.onFormValueChange(data.value))
            ).subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={`${styles.usersSection} row`}>
                <div className='col-12'>
                    <CustomCard showLocalLoader={this.state.isProcessing}>
                        <CustomCard.Header>
                            <div className={styles.header}>
                                <h2 className="custom-card-title">
                                    <Translation text="mentors.mentorList.title" />
                                </h2>

                                {/*<div className={styles.actions}>*/}
                                {/*    <div className="form-control input-icon icon-search ml-4">*/}
                                {/*        <InputBasic type={InputType.TEXT}*/}
                                {/*                    placeholder={t('mentors.search')}*/}
                                {/*                    className="input"*/}
                                {/*                    value={this.state.searchValue}*/}
                                {/*                    name='searchInput'*/}
                                {/*                    handleChange={(e: any) => this.onValueStateChange(e)}*/}
                                {/*                    autoComplete="off"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className={`d-flex flex-row justify-content-between mb-5`}>
                                <div className={`d-grid gap-1`}>
                                    <div className={`row`}>
                                        <div className={`col`}>
                                            <button type="button"
                                                    className={`btn btn-small btn-theme btn-no-arrow col`}
                                                    onClick={() => { this.downloadMentorPersonalDataReport() }}
                                            >
                                                <Translation text="mentors.mentorList.buttons.exportPersonalDataList" />
                                            </button>
                                        </div>
                                        <div className={`col`}>
                                            <button type="button"
                                                    className={`btn btn-small btn-theme btn-no-arrow col`}
                                                    onClick={() => { this.downloadMentorRatesDataReport() }}
                                            >
                                                <Translation text="mentors.mentorList.buttons.exportRatesList" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`d-grid`}>
                                    <div className={`row`}>
                                        <div className={`col`}>
                                            <button type="button"
                                                    className={`btn btn-small btn-theme btn-no-arrow
                                            ${this.state.areListedAccountsActive ? '' : 'btn-primary-outline'}`}
                                                    onClick={() => {
                                                        this.getMentorList({page: 1, itemsPerPage: 10, showDeactivated: false});
                                                        this.setState({areListedAccountsActive: true})
                                                    }}
                                            >
                                                <Translation text="mentors.mentorList.buttons.activeList" />
                                            </button>
                                        </div>
                                        <div className={`col`}>
                                            <button type="button"
                                                    className={`btn btn-small btn-theme btn-no-arrow
                                            ${this.state.areListedAccountsActive ? 'btn-primary-outline' : ''}`}
                                                    onClick={() => {
                                                        this.setState({areListedAccountsActive: false})
                                                        this.getMentorList({page: 1, itemsPerPage: 10, showDeactivated: true});
                                                    }}
                                            >
                                                <Translation text="mentors.mentorList.buttons.inactiveList" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <div className={`row`}>
                                <div className={'col-lg-6'}>
                                    <div className={'onboarding-form'} style={{padding: 1}}>
                                        <FormInput id={'firstName'} label={'Imię'} onChange={this.setFilter}/>
                                    </div>
                                </div>
                                <div className={'col-lg-6'}>
                                    <div className={'onboarding-form'} style={{padding: 1}}>
                                        <FormInput id={'lastName'} label={'Nazwisko'} onChange={this.setFilter}/>
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-lg-6'}>
                                    <div className={'onboarding-form'} style={{padding: 1}}>
                                        <FormInput id={'email'} label={'Email'} onChange={this.setFilter}/>
                                    </div>
                                </div>
                                <div className={'col-lg-6'}>
                                    <div className={'row'}>
                                        <div className={'col-xl-6'}>
                                        </div>
                                        <div className={'col-xl-6'}>
                                            <button type="button"
                                                    className={`btn btn-small btn-theme btn-no-arrow`}
                                                    onClick={() => {
                                                        this.getMentorList({page: 1, itemsPerPage: 10, showDeactivated: false}, this.state.filters);
                                                    }}
                                            >
                                                <Translation text="mentors.mentorList.buttons.search"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`row`} style={{marginTop: '5rem'}}>
                                <MentorsTable mentors={this.state.mentorList}
                                              isProcessing={false}
                                              acceptMentorRegistration={this.acceptMentorRegistration}
                                              acceptMentorOnboarding={this.acceptMentorOnboarding}
                                              envData={this.props.envData}
                                />
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                    <Pagination listMetadata={this.state.listMetadata}
                                changePage={this.getMentorList}
                    />
                </div>
            </section>
        );
    }

    setFilter = (filter: keyof FilterData, value: string) => {
        let filters = this.state.filters;
        filters[filter] = value;
        this.setState({filters: filters});
    }

    private onValueStateChange = (value: any) => {
        this.onValueStateChange$.next({value: value.target.value});
    };

    private onFormValueChange = (value: any) => {
        this.setState({
            searchValue: value
        });

    };

    private downloadMentorPersonalDataReport(){
        this.setState({isProcessing: true});
        exportMentorsAPI(this.props.authToken)
            .then((blob) => {
                this.alertManager?.addAlert('applicants.applicantView.alert.downloadAccepted');
                this.fileManager?.download(blob, "mentors");
                this.setState({isProcessing: false});
            })
            .catch(() => {
                this.setState({isProcessing: false});
                this.alertManager?.addAlert('alerts.baseError');
            });
    }

    private  downloadMentorRatesDataReport(){
        this.setState({isProcessing: true});
        exportMentorRatesAPI(this.props.authToken)
            .then((blob) => {
                this.alertManager?.addAlert('applicants.applicantView.alert.downloadAccepted');
                this.fileManager?.download(blob, "mentor_rates");
                this.setState({isProcessing: false});
            })
            .catch(() => {
                this.setState({isProcessing: false});
                this.alertManager?.addAlert('alerts.baseError');
            });
    }

    private getMentorList = (paginationData?: PaginationData, filters?: FilterData) => {
        let params = this.prepareQueryFilters(paginationData, filters);

        this.setState({isProcessing: true});
        return this.subscriptions.push(
            getAdminMentorsAPI(this.props.authToken, params).pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        this.setState({
                            isProcessing: false,
                            mentorList: resp['hydra:member'] || [],
                            listMetadata: resp['hydra:view'] || null
                        });
                    }
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of(error);
                })
            ).subscribe()
        )
    };

    private prepareQueryFilters(paginationData: PaginationData | undefined, filters: FilterData | undefined) {
        let params = null;
        params = this.addPaginationData(paginationData, params);
        params = this.addSearchFilters(filters, params);
        return params;
    }

    private addPaginationData(paginationData: PaginationData | undefined, params: any) {
        if (paginationData) {
            params = new RestQueryParams()
                .add('page', paginationData.page)
                .add('itemsPerPage', paginationData.itemsPerPage);

            if (paginationData.showDeactivated) {
                params = params
                    .add('deactivated', 'true')
            } else {
                params = params
                    .add('deactivated', 'false')
            }
        }
        return params;
    }

    private addSearchFilters(filters: FilterData | undefined, params: any) {
        if (filters) {
            if (!params) {
                params = new RestQueryParams();
            }
            if (filters.firstName) {
                params = params.add('account.firstName', filters.firstName);
            }
            if (filters.lastName) {
                params = params.add('account.lastName', filters.lastName);
            }
            if (filters.email) {
                params = params.add('account.user.login', filters.email);
            }
        }
        return params;
    }

    private acceptMentorRegistration = (mentorId: string) => {
        this.setState({isProcessing: true})
        const payload = {returnUrl: `${this.props.envData.REACT_APP_MENTOR_URL}/onboarding/mentor`}
        return this.subscriptions.push(
            enableMentorOnboardingAPI(this.props.authToken, mentorId, payload).pipe(
                tap(() => {
                    this.alertManager?.addAlert('mentors.mentorList.alert.registrationAccepted');
                    this.setState({isProcessing: false});
                    this.getMentorList({page: 1, itemsPerPage: 10, showDeactivated: false});
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false})
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };
    private acceptMentorOnboarding = (mentorId: string) => {
        this.setState({isProcessing: true})
        const payload = {returnUrl: `${this.props.envData.REACT_APP_MENTOR_URL}/onboarding/mentor`}
        return this.subscriptions.push(
            confirmMentorOnboardingAPI(this.props.authToken, mentorId, payload).pipe(
                tap(() => {
                    this.alertManager?.addAlert('mentors.mentorList.alert.onboardingAccepted');
                    this.setState({isProcessing: false});
                    this.getMentorList({page: 1, itemsPerPage: 10, showDeactivated: false});
                }),
                catchError((error: any) => {
                    this.setState({isProcessing: false})
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(MentorList));
