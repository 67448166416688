import {deepCloneObject, Form, FormControlType, IFormConfig, Translation} from 'educat-common-web';
import React from 'react';
import {Subscription} from 'rxjs';
import {MentorOnboardingSteps} from '../../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService';
import OnboardingFormHeader from '../../../../OnboardingHost/MentorOnboarding/Common/OnboardingFormHeader';
import {mentorHourPackagesFormConfig, taskGroup} from './mentorHourPackagesFormConfig';

interface IHourPackageItem {
    name: string;
    description: string;
    id: string;
    price: number;
    currency: string;
    discount: number;
    discountedPrice: { [key: string]: any };
}

interface IConnectedFormStepHourPackagesProps {
}

interface IExternalFormStepHourPackagesProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData: any;
    readonly serviceConsultationPackageList: any[];
    readonly hidden: boolean

}

interface IFormStepHourPackagesProps extends IConnectedFormStepHourPackagesProps, IExternalFormStepHourPackagesProps {
}

interface IFormStepHourPackagesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
}

class FormHourPackages extends React.Component<IFormStepHourPackagesProps, IFormStepHourPackagesState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IFormStepHourPackagesProps) {
        super(props);

        this.state = {
            isStepValid: true,
            isProcessing: false,
            formConfig: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.HOUR_PACKAGES,
        };
    }

    componentDidMount() {
        this.setupFormData();
    }

    componentDidUpdate(prevProps: Readonly<IFormStepHourPackagesProps>, prevState: Readonly<IFormStepHourPackagesState>, snapshot?: any) {
        if (prevProps.stepData !== this.props.stepData || prevProps.serviceConsultationPackageList !== this.props.serviceConsultationPackageList) {
            this.setupFormData();
        }
    }

    private setupFormData() {
        const groups = this.createFormGroupsFromHourPackages();
        let updatedGroups = deepCloneObject(groups);
        const hourPackageStepData = this.props.stepData;
        updatedGroups.map((control: any) => {
            if (control.hasOwnProperty('controls')) {
                Object.keys(control.controls).map((key: string) => {
                    if (key.includes('net_price') || key.includes('net_price_discount_') || key.includes('client_discount_') || key.includes('vat_') || key.includes('gross_price')) {
                        return control.controls[key];
                    }
                    const stepDataItem = hourPackageStepData ? hourPackageStepData[key] : null;
                    if (stepDataItem !== null && stepDataItem !== undefined && stepDataItem.active) {
                        control.controls[key].defaultValue = stepDataItem.price;
                        control.controls[key].value = stepDataItem.price;
                        control.controls[key].checked = stepDataItem.price;
                    } else {
                        control.controls[key].defaultValue = false;
                        control.controls[key].value = false;
                        control.controls[key].checked = false;
                    }
                    return control.controls[key];
                });
            }
            return control;
        });
        const updatedFormConfig = mentorHourPackagesFormConfig(updatedGroups);
        this.setState({formConfig: updatedFormConfig, stepValue: this.props.stepData});
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className={`onboarding-form-wrapper ${this.props.hidden ? 'd-none' : ''}`}>
                    <OnboardingFormHeader hideSubtitle stepName={this.state.stepName}/>
                    {this.state.formConfig &&
                        <Form config={this.state.formConfig}
                              controlName={MentorOnboardingSteps.HOUR_PACKAGES}
                              onValueStateChange={this.formChangeHandler}
                              onValidationStateChange={this.formValidityChange}
                              value={this.state.stepValue}/>}

                    <div className="additional-information">
                        <Translation text="mentorOnboarding.onboarding.priceRecalculatingInfo"/>
                    </div>
                    <button
                        type="submit"
                        onClick={() => {
                            this.props.submitStep(MentorOnboardingSteps.HOUR_PACKAGES, this.state.stepValue)
                        }}
                        className="btn btn-theme btn-rounded align-self-end mb-1 mt-4"
                    >
                        <Translation text="buttons.save"/>
                    </button>
                </div>
            </>
        );
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any) => {
        if (!this.state.stepValue) {
            return this.setState({stepValue: value});
        }
        let updatedStepValue = deepCloneObject(this.state.stepValue);
        for (const [key, inputValue] of Object.entries(value)) {
            updatedStepValue[key] = inputValue;
        }
        this.setState({stepValue: updatedStepValue});
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };

    private createFormGroupsFromHourPackages(): any[] {
        return this.props.serviceConsultationPackageList.map((hourPackage: IHourPackageItem) => {
            const controls = this.createFormControlsFromHourPackage(hourPackage);

            return taskGroup(hourPackage.name, controls, false);
        });
    }

    private createFormControlsFromHourPackage(hourPackage: IHourPackageItem) {
        let controls = {};
        controls = Object.assign(controls, {
            [hourPackage.id]: {
                controlType: 'control',
                defaultValue: true,
                value: true,
                formControlType: FormControlType.CHECKBOX,
                validationRules: [],
                placeholder: '',
                label: hourPackage.name,
                isLabelHidden: false,
                isCheckboxLabelRaw: false,
                checkboxLabel: ``,
                hostClass: 'col-xl-2 onboarding-table-cell checkbox-container',
            },
            [`net_price${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.price ? `${Math.round(hourPackage.price / 100)} ${hourPackage.currency}` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
            },
            [`client_discount_${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.discount ? `${Math.round(hourPackage.discount / 100)} %` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
            },
            [`net_price_discount_${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.discountedPrice ? `${Math.round(hourPackage.discountedPrice.amount / 100)} ${hourPackage.currency}` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
            },
            [`vat_${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.discountedPrice ? `${Math.round(hourPackage.discountedPrice.amount * 0.23 / 100)} ${hourPackage.currency}` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
            },
            [`gross_price_${hourPackage.id}`]: {
                controlType: 'control',
                defaultValue: hourPackage.discountedPrice ? `${Math.round(hourPackage.discountedPrice.amount * 1.23 / 100)} ${hourPackage.currency}` : null,
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly`,
            },
        });

        return controls;
    }
}

export default FormHourPackages;
