import {CustomCard} from 'educat-common-web';
import {Translation} from 'educat-common-web';
import {IModelAccount, isNotNullOrUndefined} from 'educat-common-web';
import React from 'react';
import noUser from '../../../assets/images/no_user.jpg';
import styles from '../UserListStyles/styles.module.scss';
import {MentorProps, MentorStatus} from "../../../constants/MentorStatus";


interface IConnectedAccountImageProps {
}

interface IExternalAccountImageProps {
    readonly account: typeof IModelAccount;
    readonly type: 'largeThumb' | 'mediumThumb' | 'smallThumb' | 'tinyThumb';
    readonly adaptive?: boolean;
    readonly isMentor?: boolean;
    readonly mentor?: any;
    readonly modalTrigger?: any;
}

interface IAccountImageProps
    extends IConnectedAccountImageProps,
        IExternalAccountImageProps {
}

interface IAccountImageState {
}

class AccountImage extends React.Component<IAccountImageProps, IAccountImageState> {
    render() {
        const image = isNotNullOrUndefined(this.props.account?.avatar)
            ? this.props.account.avatar[this.props.type]
            : noUser;

        return (
            <CustomCard>
            <CustomCard.Header>
                <div className={'d-flex justify-content-between'}>
                    <h2 className={styles.title}>
                        <Translation text={`${this.props.isMentor ? 'mentors.mentorView' : 'applicants.applicantView'}.imageCard.title`} />
                    </h2>
                    { this._renderEditDataBtn(this.props.mentor) }
                </div>
            </CustomCard.Header>
            <CustomCard.Body>
            <div className={`ms-auto me-auto mentor-img ${true === this.props.adaptive ? 'adaptive' : ''}`}
                    style={{backgroundImage: `url(${image}`, boxShadow: isNotNullOrUndefined(this.props.account?.avatar) ? '0px 0px 4px 0px' : 'unset'}}
                    aria-hidden="true"/>
                    </CustomCard.Body>
                    </CustomCard>
        )

    }

    private _renderEditDataBtn(item: any) {
        return (
            <button type="button"
                    className="btn btn-theme btn-small mb-lg-auto position-relative"
                    style={{top: '-1rem', right: '-1rem'}}
                    hidden={MentorProps.getStatus(item) !== MentorStatus.mentorActive}
                    onClick={() => this.props.modalTrigger()}
            >
                <Translation text={'buttons.edit'}/>
            </button>
        )
    }
}


export default AccountImage;
