import React from "react";
import {Loader, Translation} from "educat-common-web";
import styles from "../../../Shared/UserListStyles/styles.module.scss";
import {
    MentorOnboardingSteps
} from "../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {CalculatedPrices} from "../../../../api/provider/educatCalculatedPricesAPI";
import {
    IOnboardingHelperService
} from "../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";
import PricePackageList from "./PricePackageList";
import {calculatePackagePricesAPI} from "../../../../api/calculatePackagePrices";
import {IAlertManagerService} from "../../../../service/alertManagerService";


interface IConnectedMentorsProps {
    readonly authToken: string;
}

interface IPlatformPricesEditorProps extends IConnectedMentorsProps{
    serviceApplicationElementsList: any[];
    serviceApplicationPackagesList: any[];
    serviceConsultationPackagesList: any[];
    serviceConsultationsList: any[];
    freeServiceConsultationPackageList: any[];
    onFinish: any;
    mentor: any;
    trialFee: any;
    reloadUserData: any;
}

interface IPlatformPricesEditorState {
    stepValue: any;
    stepName: MentorOnboardingSteps;
    isProcessing: boolean;
    dataReady: boolean;
    calculatedPrices: CalculatedPrices;
}

class PlatformPricesEditor extends React.Component<IPlatformPricesEditorProps, IPlatformPricesEditorState>{

    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            stepValue: null,
            stepName: MentorOnboardingSteps.ONBOARDING_INFORMATION,
            isProcessing: true,
            dataReady: false,
            calculatedPrices: {serviceApplicationPackagePrices: [], serviceConsultationPackagePrices: []}
        };
        fixInjectedProperties(this);


    }

    componentDidMount() {
        // this.convertMentorDataToRawFormData();
        this.updateCalculatedPrices();
    }

    render() {
        return <div style={{height: '100%'}} className='d-flex flex-column mb-2'>
            <Loader showLoader={this.state.isProcessing}/>
            <div className="d-flex justify-content-between fs-2 fw-bold mb-5">
                <h2 className={styles.title}>
                    <Translation text={'mentors.mentorView.platformPrices.title'} />
                </h2>
            </div>

            {!this.state.isProcessing && <PricePackageList
                serviceApplicationElementsList={this.props.serviceApplicationElementsList}
                serviceApplicationPackagesList={this.props.serviceApplicationPackagesList}
                serviceConsultationPackagesList={this.props.serviceConsultationPackagesList}
                serviceConsultationsList={this.props.serviceConsultationsList}
                freeServiceConsultationPackageList={this.props.freeServiceConsultationPackageList}
                calculatedPackagePrices={this.state.calculatedPrices}
                mentor={this.props.mentor}
                trialFee={this.props.trialFee}
                reloadUserData={()=>this.props.reloadUserData()}
                setCalculatedPackagePrices={this.updateCalculatedPrices.bind(this)}
            ></PricePackageList>}



            <div>&nbsp;</div>
        </div>
    }

    updateCalculatedPrices(){
        calculatePackagePricesAPI(this.props.authToken, this.props.mentor.id)
            .subscribe({
                next: (response) => {
                    this.setState({
                        calculatedPrices: response,
                        isProcessing: false
                    })
                },
                error: () => {
                    this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.calculatePricesFailure');
                    this.setState({isProcessing: false});
                }
            })
    }





}

export default PlatformPricesEditor;