import React, {useState} from 'react';
import {Translation} from 'educat-common-web';

interface IConfirmActionButtonProps {
    translationKey: string;
    onConfirm: () => void;
    disabled?: boolean;
    btnClass?: string;
}

const ConfirmActionButton: React.FC<IConfirmActionButtonProps> = (props: IConfirmActionButtonProps) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const onConfirm = () => {
        setShowConfirmation(false);
        props.onConfirm();
    }

    const renderConfirmationPane = () => {
        if (!showConfirmation) return;

        return (
            <div className="confirmation-action-pane">
                <h3>
                    <Translation text={"inquiries.inquiryList.actions.confirmAction"}/>
                </h3>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-outline btn-small" onClick={() => setShowConfirmation(!showConfirmation)}>
                        <Translation text={'buttons.cancel'}/>
                    </button>
                    <button className="btn btn-theme btn-small" onClick={onConfirm}>
                        <Translation text={'buttons.confirm'}/>
                    </button>
                </div>
            </div>
        )
    }
    return (
        <div className="confirmation-action-button">
            <button className={props.btnClass} onClick={() => setShowConfirmation(!showConfirmation)} disabled={!!props.disabled}>
                <Translation text={props.translationKey}/>
            </button>
            { renderConfirmationPane() }
        </div>
    );
};
export default ConfirmActionButton;
