import React from "react";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard,
    RestQueryParams,
    Translation
} from "educat-common-web";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import PricingForm from "./PricingForm";
import {getServiceCompetenceTestsAPI} from "../../api/getServiceCompetenceTests";
import {catchError, filter, map, tap} from "rxjs/operators";
import {AlertType, IAlertManagerService} from "../../service/alertManagerService";
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {forkJoin, of} from "rxjs";
import {getSubjectTestsAPI} from "../../api/getSubjectTests";
import {getAdminMentorsAPI} from "../../api/getAdminMentors";
import {setMentorMarginAPI} from "../../api/setMentorMargin";
import {updateServiceDefinitionPriceAPI} from "../../api/updateServiceDefinitionPrice";
import {getTestPackagesAPI} from "../../api/getTestPackages";
import {getTrialMarginAPI} from "../../api/getTrialMargin";
import {setTrialMarginAPI} from "../../api/setTrialMargin";


interface IPricingState {
    edit: boolean,
    consultationMargin: any,
    dataLoaded: boolean,
    isLoading: boolean
    competenceTestList: any[],
    subjectTestList: any[],
    testPackageList: any[],
    mentorList: any[],
}

class Pricing extends React.Component<any, IPricingState> {

    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;



    constructor(props: any) {
        super(props);
        this.state = {
            edit: false,
            consultationMargin: 0,
            dataLoaded: false,
            isLoading: false,
            competenceTestList: [],
            mentorList: [],
            subjectTestList: [],
            testPackageList: [],
        }
        fixInjectedProperties(this);
    }

    componentDidMount() {
        forkJoin([
            this.getConsultationMargin(),
            this.getCompetenceTestList(),
            this.getSubjectTestList(),
            this.getTestPackageList(),
            this.getMentorList()
        ]).subscribe(() => {
            this.setState({isLoading: false, dataLoaded: true})
        })
    }

    render() {
        return <>
            <section className={`row`}>
                <div className='col-12'>
                    <CustomCard showLocalLoader={this.state?.isLoading}>
                        <CustomCard.Header>
                            <h2 className="custom-card-title d-flex justify-content-between mb-4">
                                <Translation text="pricing.title"/>
                                <div className={`d-flex flex-row`}>
                                    <button type="submit"
                                            className={`btn btn-small btn-theme btn-no-arrow ms-2`}
                                            onClick={() => {
                                                this.setState({edit: !this.state.edit})
                                            }}
                                            form={this.state.edit ? 'none' : 'pricing-form'}
                                    >
                                        <Translation
                                            text={this.state.edit ? "pricing.buttons.save" : "pricing.buttons.edit"}/>
                                    </button>
                                </div>
                            </h2>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <div>
                                {this.state.dataLoaded && <PricingForm
                                    edit={this.state.edit}
                                    consultationMargin={this.state.consultationMargin}
                                    competenceTestList={this.state.competenceTestList}
                                    subjectTestList={this.state.subjectTestList}
                                    testPackageList={this.state.testPackageList}
                                    mentorList={this.state.mentorList}
                                    onSubmit={this.onSubmit}
                                />}
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </div>
            </section>
        </>;
    }

    onSubmit = (formData: any) =>{
        const validationResult = this.validateForm(formData);
        if (!validationResult.isValid) {
            this.setState({edit: !this.state.edit});
            this.alertManager?.addAlert(validationResult.message, AlertType.WARNING);
        } else {
            this.setState({isLoading: true});
            forkJoin([
                updateServiceDefinitionPriceAPI(this.props.authToken, this.state.subjectTestList[0].id,
                    {type:'subject', finalGrossPrice: formData.subjectTestPrice * 100}),
                updateServiceDefinitionPriceAPI(this.props.authToken, this.state.competenceTestList[0].id,
                    {type:'competence', finalGrossPrice: formData.morrisbyTestPrice * 100}),
                updateServiceDefinitionPriceAPI(this.props.authToken,
                    this.state.testPackageList.find((el: any) => el.name.toLowerCase().includes('morrisby')).id,
                    {type: 'testPackage', finalGrossPrice: formData.morrisbyDiagnosticPackagePrice * 100}),
                updateServiceDefinitionPriceAPI(this.props.authToken,
                    this.state.testPackageList.find((el: any) => el.name.toLowerCase().includes('przedmiotowy')).id,
                    {type: 'testPackage', finalGrossPrice: formData.subjectPackagePrice * 100}),
                setMentorMarginAPI(this.props.authToken, formData.margin, this.state.mentorList[0].id),
                setTrialMarginAPI(this.props.authToken, formData.consultationMargin, this.state.mentorList[0].id)
            ]).subscribe({
                next: () => {
                    this.setState({isLoading: false})
                    window.location.reload();
                    this.alertManager?.addAlert('Zmiany zapisano pomyślnie!', AlertType.SUCCESS);
                },
                error: () => {
                    this.setState({isLoading: false})
                    this.alertManager?.addAlert('Wystąpił błąd. Odśwież stronę i spróbuj ponownie', AlertType.WARNING);
                }
            })
        }
    }

    private getConsultationMargin() {
        this.setState({isLoading: true});
        return getTrialMarginAPI(this.props.authToken).pipe(
            tap((result: any) => {
                this.setState({consultationMargin: result['hydra:member'].join()})
            })
        );
    }

    private getTestPackageList(){
        this.setState({isLoading: true});
        return getTestPackagesAPI(this.props.authToken).pipe(
            filter((response) => !!response),
            tap((resp: any) => {
                if (resp['hydra:member']) {
                    this.setState({testPackageList: resp['hydra:member']});
                }
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                return of();
            })
        )
    }

    private getCompetenceTestList() {
        this.setState({isLoading: true});
        return getServiceCompetenceTestsAPI(this.props.authToken).pipe(
            filter((response) => !!response),
            tap((resp: any) => {
                if (resp['hydra:member']) {
                    this.setState({competenceTestList: resp['hydra:member']});
                }
            }),
            catchError((err: any) => {
                this.alertManager?.handleApiError(err);
                return of();
            })
        )
    }

    private getSubjectTestList() {
        this.setState({isLoading: true});
        return getSubjectTestsAPI(this.props.authToken).pipe(
                tap((resp: any) => {
                    if (resp['hydra:member']) {
                        this.setState({subjectTestList: resp['hydra:member']});
                    }
                }),
                catchError((err: any) => {
                    this.alertManager?.handleApiError(err);
                    return of();
                })
            )
    }

    private getMentorList() {
        const params = new RestQueryParams()
            .add('page', 1)
            .add('itemsPerPage', 10)
            .add('deactivated', 'false');
        this.setState({isLoading: true});
        return getAdminMentorsAPI(this.props.authToken, params).pipe(
            map((resp: any) => {
                if (resp['hydra:member']) {
                    this.setState({mentorList: resp['hydra:member'] || []});
                }
            }),
            catchError((error: any) => {
                this.alertManager?.handleApiError(error);
                this.setState({isLoading: false});
                return of(error);
            }))
    }

    private validateForm(formData: any) : {isValid: boolean, message: string} {
        if (formData.margin > 99){
            return {
                isValid: false,
                message: 'Marża Educat może stanowić maksymalnie 99% ceny. Popraw wprowadzoną wartość i spróbuj ponownie.'
            };
        }
        return {
            isValid: true,
            message: ''
        };
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withTranslation()(withRouter(Pricing)));