import {deepCloneObject, Form, FormControlType, IFormConfig, Translation} from 'educat-common-web';
import React from 'react';
import {Subscription} from 'rxjs';
import {MentorOnboardingSteps} from '../../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService';
import OnboardingFormHeader from '../../../../OnboardingHost/MentorOnboarding/Common/OnboardingFormHeader';
import {mentorServicePackagesFormConfig, taskGroup} from './mentorServicePackagesFormConfig';

interface IConnectedFormStepServicePackagesProps {
}

interface IExternalFormStepServicePackagesProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData: any;
    readonly serviceApplicationPackageList: any[];
    readonly hidden: boolean

}

interface IFormStepServicePackagesProps extends IConnectedFormStepServicePackagesProps, IExternalFormStepServicePackagesProps {
}

interface IFormStepServicePackagesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
}

class FormServicePackages extends React.Component<IFormStepServicePackagesProps, IFormStepServicePackagesState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IFormStepServicePackagesProps) {
        super(props);

        this.state = {
            isStepValid: true,
            isProcessing: false,
            formConfig: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.SERVICE_PACKAGES,
        };
    }

    componentDidMount() {
        this.setupFormData();
    }

    componentDidUpdate(prevProps: Readonly<IFormStepServicePackagesProps>, prevState: Readonly<IFormStepServicePackagesState>, snapshot?: any) {
        if (prevProps.stepData !== this.props.stepData || prevProps.serviceApplicationPackageList !== this.props.serviceApplicationPackageList) {
            this.setupFormData();
        }
    }

    private setupFormData() {
        const groups = this.createFormGroupsFromPackages();
        let updatedGroups = deepCloneObject(groups);
        const servicePackagesStepData = this.props.stepData;
        updatedGroups.map((control: any) => {
            if (control.hasOwnProperty('controls')) {
                Object.keys(control.controls).map((key: string) => {
                    if (key.includes('net_price') || key.includes('services_') || key.includes('vat_') || key.includes('gross_price')) {
                        return control.controls[key];
                    }
                    const stepDataItem = servicePackagesStepData ? servicePackagesStepData[key] : null;
                    if (stepDataItem !== null && stepDataItem !== undefined && stepDataItem.active) {
                        control.controls[key].defaultValue = stepDataItem.price;
                        control.controls[key].value = stepDataItem.price;
                        control.controls[key].checked = stepDataItem.price;
                    } else {
                        control.controls[key].defaultValue = false;
                        control.controls[key].value = false;
                        control.controls[key].checked = false;
                    }
                    return control.controls[key];
                });
            }
            return control;
        });
        const updatedFormConfig = mentorServicePackagesFormConfig(updatedGroups);
        this.setState({formConfig: updatedFormConfig, stepValue: this.props.stepData});
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className={`onboarding-form-wrapper ${this.props.hidden ? 'd-none' : ''}`}>
                    <OnboardingFormHeader hideSubtitle stepName={this.state.stepName}/>
                    {this.state.formConfig &&
                        <Form config={this.state.formConfig}
                              controlName={MentorOnboardingSteps.SERVICE_PACKAGES}
                              onValueStateChange={this.formChangeHandler}
                              onValidationStateChange={this.formValidityChange}
                              value={this.state.stepValue}
                        />
                    }
                    <button
                        type="submit"
                        onClick={() => {
                            this.props.submitStep(MentorOnboardingSteps.SERVICE_PACKAGES, this.state.stepValue)
                        }}
                        className="btn btn-theme btn-rounded align-self-end mb-1 mt-4"
                    >
                        <Translation text="buttons.save"/>
                    </button>
                </div>
            </>
        );
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any) => {
        if (!this.state.stepValue) {
            return this.setState({stepValue: value});
        }
        let updatedStepValue = deepCloneObject(this.state.stepValue);
        for (const [key, inputValue] of Object.entries(value)) {
            updatedStepValue[key] = inputValue;
        }
        this.setState({stepValue: updatedStepValue});
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };

    private createFormGroupsFromPackages() {
        return this.props.serviceApplicationPackageList.map(
            (serviceApplicationPackage: { name: string; id: string; services: string; price: number; currency: string }) => {
                const controls = this.createFormControlsFromPackageList(serviceApplicationPackage);

                return taskGroup(`group_${serviceApplicationPackage.id}`, serviceApplicationPackage.name, controls, false);
            }
        );
    }

    private createFormControlsFromPackageList(serviceApplicationPackage: {
        name: string;
        id: string;
        services: string;
        price: number;
        currency: string;
    }) {
        let controls = {};
        controls = Object.assign(controls, {
            [serviceApplicationPackage.id]: {
                controlType: 'control',
                defaultValue: true,
                value: true,
                formControlType: FormControlType.CHECKBOX,
                validationRules: [],
                placeholder: '',
                label: serviceApplicationPackage.name,
                isLabelHidden: false,
                isCheckboxLabelRaw: false,
                checkboxLabel: ``,
                hostClass: 'col-xl-4 checkbox-container',
            },
            [`services_${serviceApplicationPackage.id}`]: {
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly textarea-mask`,
                controlType: 'control',
                defaultValue: serviceApplicationPackage?.services ? serviceApplicationPackage.services : '--',
                disabled: true,
                formControlType: FormControlType.TEXTAREA,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
            },
            [`net_price_${serviceApplicationPackage.id}`]: {
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly bg-transparent`,
                controlType: 'control',
                defaultValue: serviceApplicationPackage.price
                    ? `${serviceApplicationPackage.price / 100} ${serviceApplicationPackage.currency}`
                    : '--',
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
            },
            [`vat_${serviceApplicationPackage.id}`]: {
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly bg-transparent`,
                controlType: 'control',
                defaultValue: serviceApplicationPackage.price
                    ? `${Math.round(serviceApplicationPackage.price * 0.23 / 100)} ${serviceApplicationPackage.currency}`
                    : '--',
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
            },
            [`gross_price_${serviceApplicationPackage.id}`]: {
                hostClass: `col-xl-2 onboarding-table-cell hide-label input-readonly bg-transparent`,
                controlType: 'control',
                defaultValue: serviceApplicationPackage.price
                    ? `${Math.round(serviceApplicationPackage.price * 1.23 / 100)} ${serviceApplicationPackage.currency}`
                    : '--',
                disabled: true,
                formControlType: FormControlType.INPUT,
                validationRules: [],
                labelHidden: true,
                placeholder: '--',
            },
        });

        return controls;
    }
}

export default FormServicePackages;
