import React, {useEffect, useMemo, useState} from 'react';
import {
    BasicModal,
    CustomCard,
    Translation,
    CustomCardType,
    Form,
    IFormConfig,
    IPrice,
    handleApiError,
    addAlert,
    AlertObject,
    authTokenSelector,
    FormControlChangeType
} from 'educat-common-web';
import {inquirySettingsFormConfig} from "./inquirySettingsFormConfig";
import {getInquiryVariantsAPI} from "../../../api/getInquiryVariantsAPI";
import {catchError, filter, map, tap} from "rxjs/operators";
import {BehaviorSubject, forkJoin, of, Subscription} from "rxjs";
import {useDispatch, useSelector} from "react-redux";
import {IUpdateInquiryVariantPayload, updateInquiryVariantAPI} from "../../../api/updateInquiryVariantAPI";
import InquiryDocuments from "./InquiryDocuments";

export interface IInquiryVariant {
    readonly basePrice: typeof IPrice;
    readonly europeAdditionalPrice: typeof IPrice;
    readonly worldAdditionalPrice: typeof IPrice;
    readonly alcoholClauseValue: number;
    readonly physicalWorkValue: number;
    readonly name: string;
    readonly id: string;
}

export interface IInquirySettingsFormValue {
    essentialEurope: string | null;
    essentialWorld: string | null;
    premiumEurope: string | null;
    premiumWorld: string | null;
    allInclusiveEurope: string | null;
    allInclusiveWorld: string | null;
}

export enum InquiryVariantName {
    ESSENTIAL = 'Essentials',
    PREMIUM = 'Premium',
    ALL_INCLUSIVE = 'All inclusive',
}

interface IInquirySettingsModalProps {
    closeModal: () => void;
}

const InquirySettingsModal: React.FC<IInquirySettingsModalProps> = (props: IInquirySettingsModalProps) => {
    const dispatch = useDispatch(),
        authToken: string | null = useSelector(authTokenSelector),
        onValueStateChange$ = useMemo(() => new BehaviorSubject<any>(null), []),
        [formConfig, setFormConfig] = useState<typeof IFormConfig | null>(null),
        [value, setValue] = useState<IInquirySettingsFormValue>({
            essentialEurope: null,
            essentialWorld: null,
            premiumEurope: null,
            premiumWorld: null,
            allInclusiveEurope: null,
            allInclusiveWorld: null,
        }),
        [isLoading, setIsLoading] = useState<boolean>(true),
        [inquiryVariantsList, setInquiryVariantsList] = useState<IInquiryVariant[]>([]);

    const subscriptions: Subscription[] = [];

    const setForm = (): void => {
        const formConfig = inquirySettingsFormConfig(null);
        setFormConfig(formConfig);
    };

    useEffect(() => {
        getInquiryVariants();
        setForm();

        return() => {
            if (subscriptions) {
                subscriptions.forEach(subscription => {
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        updateFormValues();
    }, [inquiryVariantsList]);

    useEffect(() => {
        const subscription = onValueStateChange$.pipe(
            filter((data: any) => data && data.changeType === FormControlChangeType.User),
            tap((data: any) => setValue(data.value)),
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        }
    }, [onValueStateChange$]);

    const onValueStateChange = (controlName: string, value: any, changeType: any) => {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    const getInquiryVariants = () => {
        subscriptions.push(
            getInquiryVariantsAPI().pipe(
                map((resp) => {
                    setInquiryVariantsList(resp['hydra:member'] || []);
                    setIsLoading(false);
                }),
                catchError((error) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    const convertPriceToString = (price: typeof IPrice | null) => {
        if (price === null) return null;
        const number = typeof price === 'number' ? price : Number(price);
        if (isNaN(number)) return null;
        return (number / 100).toFixed(2);
    };

    const convertValueToAmount = (value: string | null) => {
        if (!value) return null;

        const number = Number(value);
        if (isNaN(number)) return null;

        return String(number * 100);
    };

    const updateFormValues = () => {
        if (!inquiryVariantsList.length) {
            return;
        }
        const essentialEuropePrice = inquiryVariantsList.find(variant => variant.name === InquiryVariantName.ESSENTIAL)?.europeAdditionalPrice || null,
            essentialWorldPrice = inquiryVariantsList.find(variant => variant.name === InquiryVariantName.ESSENTIAL)?.worldAdditionalPrice || null,
            premiumEuropePrice = inquiryVariantsList.find(variant => variant.name === InquiryVariantName.PREMIUM)?.europeAdditionalPrice || null,
            premiumWorldPrice = inquiryVariantsList.find(variant => variant.name === InquiryVariantName.PREMIUM)?.worldAdditionalPrice || null,
            allInclusiveEuropePrice = inquiryVariantsList.find(variant => variant.name === InquiryVariantName.ALL_INCLUSIVE)?.europeAdditionalPrice || null,
            allInclusiveWorldPrice = inquiryVariantsList.find(variant => variant.name === InquiryVariantName.ALL_INCLUSIVE)?.worldAdditionalPrice || null;

        const updatedValues: IInquirySettingsFormValue = {
            essentialEurope: convertPriceToString(essentialEuropePrice?.amount),
            essentialWorld: convertPriceToString(essentialWorldPrice?.amount),
            premiumEurope: convertPriceToString(premiumEuropePrice?.amount),
            premiumWorld: convertPriceToString(premiumWorldPrice?.amount),
            allInclusiveEurope: convertPriceToString(allInclusiveEuropePrice?.amount),
            allInclusiveWorld: convertPriceToString(allInclusiveWorldPrice?.amount),
        }

        setValue(updatedValues);
    }

    const createVariantPayload = (variantDetails: IInquiryVariant, europeValue: string | null, worldValue: string | null) => {
        return {
            nameTranslation: { pl_PL: variantDetails.name },
            basePrice: variantDetails.basePrice,
            europeAdditionalPrice: {
                amount: convertValueToAmount(europeValue),
                currency: variantDetails.europeAdditionalPrice.currency
            },
            worldAdditionalPrice: {
                amount: convertValueToAmount(worldValue),
                currency: variantDetails.worldAdditionalPrice.currency
            },
            alcoholClauseValue: variantDetails.alcoholClauseValue,
            physicalWorkValue: variantDetails.physicalWorkValue,
        };
    };

    const updateVariants = () => {
        const essentialVariantDetails = inquiryVariantsList.find((variant => variant.name === InquiryVariantName.ESSENTIAL)),
            premiumVariantDetails = inquiryVariantsList.find((variant => variant.name === InquiryVariantName.PREMIUM)),
            allInclusiveVariantDetails = inquiryVariantsList.find((variant => variant.name === InquiryVariantName.ALL_INCLUSIVE));

        if (!essentialVariantDetails || !premiumVariantDetails || !allInclusiveVariantDetails) {
            return;
        }

        setIsLoading(true);

        const essentialVariantPayload: IUpdateInquiryVariantPayload = createVariantPayload(essentialVariantDetails, value.essentialEurope, value.essentialWorld),
              premiumVariantPayload: IUpdateInquiryVariantPayload = createVariantPayload(premiumVariantDetails, value.premiumEurope, value.premiumWorld),
              allInclusiveVariantPayload: IUpdateInquiryVariantPayload = createVariantPayload(allInclusiveVariantDetails, value.allInclusiveEurope, value.allInclusiveWorld);

        const changeEssentialVariant = updateInquiryVariantAPI(authToken, essentialVariantDetails.id, essentialVariantPayload),
            changePremiumVariant = updateInquiryVariantAPI(authToken, premiumVariantDetails.id, premiumVariantPayload),
            changeAllInclusiveVariant = updateInquiryVariantAPI(authToken, allInclusiveVariantDetails.id, allInclusiveVariantPayload);

        subscriptions.push(
            forkJoin([changeEssentialVariant, changePremiumVariant, changeAllInclusiveVariant]).pipe(
                tap(() => {
                    setIsLoading(false);
                    dispatch(addAlert({message: 'inquiries.inquiryList.inquirySettingsModal.variantsUpdated'}));
                }),
                catchError((error) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    return (
        <BasicModal isModalShown={true}
                    modalContentContainerClassName={'inquiry-settings-modal'}
                    closeModal={props.closeModal}>
            <CustomCard type={CustomCardType.MODAL_CARD} showLocalLoader={isLoading}>
                <CustomCard.Header>
                    <div className="d-flex justify-content-between mb-5">
                        <p>
                            <Translation text={'inquiries.inquiryList.inquirySettingsModal.title'}/>
                        </p>
                        <button onClick={props.closeModal}>
                            <span className="feather icon-x"/>
                        </button>
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div>
                        <h2 className="subtitle">
                            <Translation text={'inquiries.inquiryList.inquirySettingsModal.variants.title'}/>
                        </h2>
                        {formConfig && <Form config={formConfig}
                                             onValueStateChange={onValueStateChange}
                                             submitForm={updateVariants}
                                             value={value}
                                             controlName={'variantsSettingsForm'}/>}
                    </div>
                    <hr className="my-3"/>
                    <InquiryDocuments />
                </CustomCard.Body>
            </CustomCard>
        </BasicModal>
    );
};
export default InquirySettingsModal;
