import React, {useEffect} from 'react';
import {addAlert, handleApiError, Translation, AlertObject, authTokenSelector} from 'educat-common-web';
import {from, of, Subscription} from "rxjs";
import {saveAs} from 'file-saver';
import {useDispatch, useSelector} from "react-redux";
import {getInquiryCSVFiltersParam, IInquiryListFilters} from "../../inquiryFiltersUtil";

interface InquiryGenerateReportButtonProps {
    filters: IInquiryListFilters | null;
}

const InquiryGenerateReportButton: React.FC<InquiryGenerateReportButtonProps> = ({filters}) => {
    const dispatch = useDispatch(),
        authToken = useSelector(authTokenSelector);

    let subscription: Subscription | null = null;

    useEffect(() => {
        return() => {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }, []);

    const generateCSV = () => {
        if (!filters) return;

        subscription = from(fetchInquiryReport(
            authToken as string,
            `${process.env.REACT_APP_API_URL}/inquiries/export${getInquiryCSVFiltersParam(filters)}`
        )).subscribe();
    };

    const fetchInquiryReport = (authToken: string, url: string) => {
        return fetch(url, {
            headers: {
                "Accept": "text/csv",
                "Content-type": "application/json",
                "Authorization": `Bearer ${authToken}`
            },
        }).then(response => response.blob())
            .then(blob => {
                saveAs(blob, 'report.csv');
            })
            .catch(error => {
                const errorObj = handleApiError(error) as typeof AlertObject;
                return of(dispatch(addAlert(errorObj)))
            });
    };

    return (
        <button className="btn btn-theme btn-small" onClick={generateCSV}>
            <Translation text={'inquiries.inquiryList.filters.btnInquiryReportCSV'}/>
        </button>
    );
};
export default InquiryGenerateReportButton;
