export enum UserActiveStatus
{
    notActivated = 'notActivated',
    active = 'active',
    deactivated = 'deactivated'
}

export class UserProps {

    static getStatus(user: any){

        if (!user || !user.registrationConfirmedAt) {
            return UserActiveStatus.notActivated;
        }

        if (user.active){
            return UserActiveStatus.active;
        }

        return UserActiveStatus.deactivated

    }
}
