import React from 'react';
import styles from './styles.module.scss';
import {Translation} from "educat-common-web";

interface IConnectedServiceItemProps {
}

interface IExternalServiceItemProps {
    readonly label: string;
    readonly onClick: any;
}

interface IServiceItemProps extends IExternalServiceItemProps,
    IConnectedServiceItemProps {
}

interface IServiceItemState {
    isModalVisible: boolean;
    selectedItem: any;
}


class PricePackageItem extends React.Component<IServiceItemProps, IServiceItemState> {
    constructor(props: IServiceItemProps) {
        super(props);

        this.state = {
            isModalVisible: false,
            selectedItem: null,
        };
    }

    render() {
        return (
            <div className={styles.consultationPackageSummary} onClick={()=> {this.props.onClick()}}>
                <h2 className={`${styles.title} fs-3`}><Translation text={this.props.label}></Translation></h2>
            </div>
        );
    }



}

export default PricePackageItem;
