import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard,
    Pagination, RestQueryParams,
    Translation,
} from 'educat-common-web';
import styles from "../../Shared/UserListStyles/styles.module.scss";
import InquiryListTable from "../InquiryListTable";
import {catchError, map} from "rxjs/operators";
import {getAdminInquiriesAPI} from "../../../api/getAdminInquiries";
import InquiryListTotals from "../InquiryListTotals";
import InquiryListFilters from "../InquiryListFilters";
import InquiryCreateModal from "../InquiryCreateModal";
import InquirySettingsModal from "../InquirySettingsModal";
import {
    DEFAULT_PAGE,
    DEFAULT_PER_PAGE,
    getInquiryListFiltersParams,
    IInquiryListFilters,
    IPaginationData
} from "../inquiryFiltersUtil";


interface IConnectedInquiryListProps {
    readonly authToken: string;
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
}

interface IExternalInquiryListProps {
    envData: any;
}

interface IInquiryListProps extends IConnectedInquiryListProps,
    IExternalInquiryListProps,
    RouteComponentProps {
}

interface IInquiryListState {
    inquiryList: {[key: string]: any}[] | [];
    listMetadata: {[key: string]: any} | null;
    isProcessing: boolean;
    selectedFilters: IInquiryListFilters;
    paginationData: IPaginationData;
    showFilters: boolean;
    showInquiryCreateModal: boolean;
    showInquirySettingsModal: boolean;
}

class InquiryList extends React.Component<IInquiryListProps, IInquiryListState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IInquiryListProps) {
        super(props);

        this.state = {
            inquiryList: [],
            listMetadata: null,
            isProcessing: false,
            selectedFilters: {},
            paginationData: {
                page: DEFAULT_PAGE,
                itemsPerPage: DEFAULT_PER_PAGE
            },
            showFilters: false,
            showInquiryCreateModal: false,
            showInquirySettingsModal: false,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.inquiries', path: '/panel/insurances'}
        ]);

        this.getInquiryList();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private toggleFilters = () => {
        this.setState({showFilters: !this.state.showFilters})
    }

    private toggleInquiryCreateModal = () => {
        this.setState({showInquiryCreateModal: !this.state.showInquiryCreateModal})
    }

    private toggleInquirySettingsModal = () => {
        this.setState({showInquirySettingsModal: !this.state.showInquirySettingsModal})
    }

    private onInquiryFiltersSubmit = (filters: IInquiryListFilters) => {
        const paginationData: IPaginationData = {page: 1, itemsPerPage: DEFAULT_PER_PAGE};
        this.getInquiryList(filters, paginationData);
        this.setState({
            selectedFilters: filters,
            paginationData: paginationData
        });
    }

    render() {
        const filterKey = this.state.showFilters ? 'hide' : 'show';
        return (
            <section className={`${styles.usersSection} row`}>
                <div className='col-12'>
                    <CustomCard showLocalLoader={this.state.isProcessing}>
                        <CustomCard.Body>
                            <InquiryListTotals selectedFilters={this.state.selectedFilters}/>
                        </CustomCard.Body>
                    </CustomCard>
                    <CustomCard showLocalLoader={this.state.isProcessing}>
                        <CustomCard.Header>
                            <div className={styles.header}>
                                <h2 className="custom-card-title">
                                    <Translation text="inquiries.inquiryList.title"/>
                                </h2>

                                <div className="actions-container">
                                    <button className="btn btn-theme btn-small" onClick={this.toggleFilters}>
                                        <Translation text={`inquiries.inquiryList.actions.btnFilters.${filterKey}`}/>
                                    </button>
                                    <button className="btn btn-theme btn-small" onClick={this.toggleInquirySettingsModal}>
                                        <Translation text={`inquiries.inquiryList.actions.btnInquirySettings`}/>
                                    </button>
                                    <button className="btn btn-theme btn-small" onClick={this.toggleInquiryCreateModal}>
                                        <Translation text="inquiries.inquiryList.actions.btnNewInquiry"/>
                                    </button>
                                </div>
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            {this.state.showFilters && <InquiryListFilters onSubmit={this.onInquiryFiltersSubmit}/>}

                            <InquiryListTable inquiries={this.state.inquiryList}
                                              getInquiriesList={this.getInquiryList}
                                              authToken={this.props.authToken}
                                              isProcessing={false}/>

                            <Pagination listMetadata={this.state.listMetadata}
                                        changePage={(paginationData: IPaginationData) =>
                                            this.getInquiryList(null, paginationData)}/>

                        </CustomCard.Body>
                    </CustomCard>
                </div>
                { this.state.showInquiryCreateModal &&
                    <InquiryCreateModal closeModal={this.toggleInquiryCreateModal} /> }

                { this.state.showInquirySettingsModal &&
                    <InquirySettingsModal closeModal={this.toggleInquirySettingsModal} /> }
            </section>
        );
    }

    private getInquiryList = (filters?: IInquiryListFilters | null, paginationData?: IPaginationData) => {

        const params: typeof RestQueryParams = getInquiryListFiltersParams(
            filters ? filters : this.state.selectedFilters,
            paginationData ? paginationData : this.state.paginationData,
        );

        this.setState({isProcessing: true});
        return this.subscriptions.push(
            getAdminInquiriesAPI(this.props.authToken, params).pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        this.setState({isProcessing: false, inquiryList: resp['hydra:member'] || [], listMetadata: resp['hydra:view'] || null});
                    }
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of(error);
                })
            ).subscribe()
        )
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(InquiryList));
