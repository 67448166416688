import React from "react";
import {Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {Loader, LoaderType, Translation} from "educat-common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import {Price} from "educat-common-web";
import {serviceChildItem, SaleItem} from "..";
import {deepCloneObject} from "educat-common-web";
import styles from "./styles.module.scss";

interface ISalesTableConnectedProps {}

interface ISalesTableExternalProps {
    sales: any[];
    isProcessing: boolean;
}

interface ISalesTableProps extends ISalesTableConnectedProps,
    ISalesTableExternalProps,
    WithTranslation {
}

interface ISalesTableState {
    openPackages: number[];
}

class SalesTable extends React.Component<ISalesTableProps, ISalesTableState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: ISalesTableProps) {
        super(props);

        this.state = {
            openPackages: []
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentDidUpdate(
        prevProps: Readonly<ISalesTableProps>,
        prevState: Readonly<ISalesTableState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if ((!this.props.sales ||
                !Array.isArray(this.props.sales)) ||
            (this.props.sales.length === 0 && !this.props.isProcessing)) {
            return <p>
                <Translation text={'sales.table.noData'}/>
            </p>;
        }


        return (
            <React.Fragment>
                <Loader type={LoaderType.Local} showLoader={this.props.isProcessing}/>
                <table className="data-table default-table">
                    <thead>
                    <tr>
                        <th className="index-column">
                            <span className="sr-only">
                                <Translation text={'sales.table.tableItemNumber'}/>
                            </span>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'sales.table.applicantName'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'sales.table.mentorName'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'sales.table.package'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'sales.table.service'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'sales.table.status.label'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'sales.table.purchaseDate'}/>
                        </th>
                        <th className="highlight-cell">
                            <Translation text={'sales.table.grossPrice'}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    private renderTableRows() {
        const rows: any[] = [];

        this.props.sales.forEach((item: SaleItem, index: number) => {
            let applicantName = `${item.applicantFirstName} ${item.applicantLastName}`,
                mentorName = (item.mentorFirstName && item.mentorLastName) ? `${item.mentorFirstName} ${item.mentorLastName}` : '--';
            const packageRow = item.isPackage && this.state.openPackages.includes(index);
            const status = item.isCompleted ? 'completed' : 'inProgress';
            rows.push((
                <tr key={`${item.serviceName}${index}`} className={`${packageRow ? 'package-row' : ''}`}>
                    <td className="no-padding">{index + 1}</td>
                    <td className="highlight-cell">
                        {applicantName}
                    </td>
                    <td>
                        {mentorName}
                    </td>
                    <td>
                        {this.renderPackage(item, index)}
                    </td>
                    <td>
                        {this.renderServiceName(item)}
                    </td>
                    <td>
                        <div className={`badge ${status}`}>
                            <Translation text={`sales.table.status.${status}`}/>
                        </div>
                    </td>
                    <td>
                        {this.renderPurchaseDate(item.purchasedAt)}
                    </td>
                    <td>
                        {item.price ? <Price price={item.price}/> : '--'}
                    </td>
                </tr>
            ));
            if (packageRow) {
                item.serviceChildren.forEach((child: serviceChildItem, index: number) => {
                    rows.push(
                    <tr key={`${child.serviceName}${index}`} className="package-row">
                    <td className="no-padding"></td>
                    <td className="highlight-cell"></td>
                    <td></td>
                    <td></td>
                    <td>
                        {child.serviceName}
                    </td>
                    <td>
                        {/*<Translation text={`sales.table.status.${child.isCompleted ? 'completed' : 'inProgress'}`}/>*/}
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                    )
                })
            }
        });
        return rows;
    }

    private renderPackage(item: SaleItem, index: number) {
        if (!item.isPackage) return '--';

        return <div className="d-flex justify-content-between align-items-center">
            <span>
                {`${item.serviceName}`}
            </span>
            <button className={styles.wrapToggleButton} onClick={() => this.togglePackageOpen(index)}>
                <Translation text={`sales.table.${this.state.openPackages.includes(index) ? 'collapse' : 'expand'}`}/>
            </button>
        </div>
    }

    private togglePackageOpen(index: number) {
        let updatedOpenPackages = deepCloneObject(this.state.openPackages);
        if (this.state.openPackages.includes(index) ) {
            updatedOpenPackages = updatedOpenPackages.filter((openPackage: number) => openPackage !== index);
        } else {
            updatedOpenPackages.push(index);
        }
        return this.setState({openPackages: updatedOpenPackages});
    }

    private renderServiceName(item: SaleItem) {
        if (!item.isPackage && item.serviceName) return item.serviceName;
        return '--';
    }

    private renderPurchaseDate(purchaseDate: string) {
        if (!purchaseDate) return '--';
        const date = new Date(purchaseDate);
        return date.toLocaleDateString('pl-PL', {});
    }
}

export default withTranslation()(SalesTable);
