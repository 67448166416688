import {RestQueryParams} from "educat-common-web";
import { Observable } from "rxjs";
import {educatAPI} from "./provider/educatAPI";

export function enableMentorOnboardingAPI(authToken: string, mentorId: string, payload: any): Observable<any> {
  return educatAPI.put(
    `mentors/${mentorId}/enable_onboarding`,
    payload,
    new RestQueryParams(),
    {
      Authorization: `Bearer ${authToken}`,
    }
  );
}
