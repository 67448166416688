import React from "react";
import {authTokenSelector, ImageUploader, Loader, Translation} from "educat-common-web";
import {
    IOnboardingHelperService,
    MentorOnboardingSteps
} from "../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";
import {catchError, tap} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {MentorAccount} from "../../../../service/mentorRegistrationService";
import {fixInjectedProperties, lazyInject} from "../../../../ioc";
import {RootState} from "../../../../store/reducers";
import {connect} from "react-redux";
import {IAlertManagerService} from "../../../../service/alertManagerService";
import styles from "../../../Shared/UserListStyles/styles.module.scss";
import {updateMentorImageAPI} from "../../../../api/updateMentorImage";

class AccountImageEditor extends React.Component<any, any>{

    private readonly subscriptions: Subscription[] = [];
    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            // formConfig: mentorOnboardingInformationFormConfig(this.props.stepData),
            imageId: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.ONBOARDING_INFORMATION,
        };
        fixInjectedProperties(this);
    }


    render() {

        return <div className="d-flex flex-column">
            <Loader showLoader={this.state.isProcessing}/>

            <div className="d-flex justify-content-between fs-2 fw-bold mb-5">
                <h2 className={styles.title}>
                    <Translation text={'mentors.mentorView.imageCard.title'} />
                </h2>
            </div>

            <div className="col-xl-12 m-user-image-input">
						<span className="image-input-label required">
							<Translation
                                text={"mentorOnboarding.registration.basic_information.formControls.photo.label"}/>
						</span>
                <ImageUploader
                    selectedImageFile={this.state.userImageFile}
                    triggerText={"mentorOnboarding.registration.basic_information.formControls.photo.input"}
                    onImageChange={(imageId: string, file: any) => {this.setState({imageId: imageId, userImageFile: file, isProcessing: false})}}
                    onUploadError={this.errorHandler}
                    onUploadBegin={this.uploadBeginHandler}
                />
                {/*onImageChange={(imageId: string, file: any) => this.updateUserImage(imageId, file)}*/}

            </div>
            <button
                type="submit"
                onClick={() => this.updateMentorImage(this.state.imageId)}
                className="btn btn-theme btn-rounded align-self-end mt-4"
                disabled={!this.state.imageId}
            >
                <Translation text="buttons.save"/>
            </button>
        </div>

    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private uploadBeginHandler = (file: any) => {
        this.setState({isProcessing: true});
    }

    private errorHandler = (reason: any, file: any) => {
        this.setState({isProcessing: false});
        const errMessage = reason?.response?.['hydra:description'];
        if (!errMessage) {
            this.alertManager?.addAlert('Wystąpił problem przy zapisywaniu przesłanego zdjęcia. Upewnij się, że przesłany plik jest w formacie JPG lub PNG i ma mniej niż 4MB.')
            return;
        }
        if (errMessage === 'file-too-large') {
            this.alertManager?.addAlert('Przesłane zdjęcie jest zbyt duże - maksymalny rozmiar zdjęcia wynosi 4MB.')
        }
        if (errMessage === 'bad-file-format') {
            this.alertManager?.addAlert('Przesłany plik nie jest zdjęciem - dozwolone formaty pliku: PNG, JPG.')
        }
    }

    private formChangeHandler = (controlName: MentorOnboardingSteps, value: any, changeType: string) => {
        if (changeType !== 'init') {
            this.setState({stepName: controlName, stepValue: value});
        }
    };

    updateMentorImage(imageId: string){
        this.setState({isProcessing: true});

        const mentor = {
            account:  {
                phone: this.props.mentor.account.phone,
                avatarId: imageId,
                address: this.props.mentor.account.address,
                birthdate: this.props.mentor.account.birthdate,
                displayName: this.props.mentor.account.displayName,
                firstName: this.props.mentor.account.firstName,
                lastName: this.props.mentor.account.lastName
            }
        };

        // mentor.account.avatarId = imageId;

        this.subscriptions.push(
            updateMentorImageAPI(this.props.authToken, this.props.mentor.id, mentor)
                .pipe(
                    catchError(() => {
                        this.alertManager?.addAlert('mentorOnboarding.onboarding.alerts.onboardingSendingFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: MentorAccount) => {
                        if (response) {
                            this.setState({
                                isProcessing: false,
                            });
                        }
                    })
                )
                .subscribe({
                    next: (res: any) => {
                        this.alertManager?.addAlert('alerts.saveSuccessful');
                        this.props.onFinish(res);
                    },
                    error: () => {}

                })
        );
    }


}

export default connect(
    (state: RootState) => ({
        // account: accountSelector(state),
        authToken: authTokenSelector(state),
    }),
    {}
)(AccountImageEditor);