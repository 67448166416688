import {
    authTokenSelector,
    changeBreadcrumbs,
    CustomCard, getApplicantsAPI, Pagination, RestQueryParams, Translation
} from 'educat-common-web';
import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {RootState} from "../../../store/reducers";
import styles from "../../Shared/UserListStyles/styles.module.scss";
import ApplicantsTable from "./ApplicantsTable";
import {PaginationData} from "../../Mentors/MentorList";
import {catchError, map} from "rxjs/operators";
import {exportApplicantsReportAPI} from "../../../api/exportApplicantsReport";
import {IFileManagerService} from "../../../service/fileManagerService";
import FormInput from "../../Shared/FormInput";

export type FilterData = {
    firstName: string;
    lastName: string;
    email: string;
}

interface IConnectedApplicantListProps {
    readonly changeBreadcrumbs: typeof changeBreadcrumbs;
    readonly authToken: string;
}

interface IExternalApplicantListProps {
    readonly envData: any;
}

interface IApplicantListProps extends IConnectedApplicantListProps,
    IExternalApplicantListProps,
    RouteComponentProps,
    WithTranslation {
}

interface IApplicantListState {
    searchValue: string;
    searchDisplayValue: string;
    isProcessing: boolean;
    applicantList: { [key: string]: any }[] | [];
    listMetadata: { [key: string]: any } | null;
    filters: FilterData;
}


class ApplicantList extends React.Component<IApplicantListProps, IApplicantListState> {
    private subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any>;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;
    @lazyInject('FileManagerService') private fileManager: IFileManagerService | undefined;

    constructor(props: IApplicantListProps) {
        super(props);

        this.state = {
            searchValue: '',
            searchDisplayValue: '',
            isProcessing: true,
            applicantList: [],
            listMetadata: null,
            filters: {
                firstName: '',
                lastName: '',
                email: ''
            }
        };
        this.onValueStateChange$ = new BehaviorSubject(this.state.searchValue);

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.props.changeBreadcrumbs([
            {labelKey: 'breadcrumbs.dashboard', path: '/panel/dashboard', icon: 'icon-home'},
            {labelKey: 'breadcrumbs.applicants', path: '/panel/applicants'}
        ]);

        this.getApplicantList({page: 1, itemsPerPage: 10});

        // this.props.getApplicantList();
        // this.subscriptions.push(
        //     this.onValueStateChange$.pipe(
        //         tap(value => this.setState({searchDisplayValue: value})),
        //         debounceTime(250),
        //         tap(value => this.onFormValueChange(value))
        //     ).subscribe()
        // );
    }


    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        // const {t} = this.props;
        return (
            <section className={`${styles.usersSection} row`}>
                <div className='col-12'>
                    <CustomCard showLocalLoader={this.state.isProcessing}>
                        <CustomCard.Header>
                            <div className={styles.header}>
                                <h2 className="custom-card-title">
                                    <Translation text="applicants.applicantList.title"/>
                                </h2>
                                <div className={`d-flex flex-row`}>
                                    <button type="button"
                                            className={`btn btn-small btn-theme btn-no-arrow`}
                                            onClick={() => {
                                                this.getApplicantsExcel()

                                            }}
                                    >
                                        <Translation text="applicants.applicantList.buttons.exportList"/>
                                    </button>
                                </div>

                                {/* <div className={styles.actions}>
                                    <div className="form-control input-search ml-4">
                                        <InputBasic type={InputType.TEXT}
                                                    placeholder={t('applicants.applicantList.search')}
                                                    className="input"
                                                    value={this.state.searchDisplayValue}
                                                    name='searchInput'
                                                    handleChange={(e: any) => this.onValueStateChange(e)}
                                                    autoComplete="off"/>
                                    </div>
                                </div> */}
                            </div>
                        </CustomCard.Header>
                        <CustomCard.Body>
                            <div>
                                <div className={`row`}>
                                    <div className={'col-lg-6'}>
                                        <div className={'onboarding-form'} style={{padding: 1}}>
                                            <FormInput id={'firstName'} label={'Imię'} onChange={this.setFilter}/>
                                        </div>
                                    </div>
                                    <div className={'col-lg-6'}>
                                        <div className={'onboarding-form'} style={{padding: 1}}>
                                            <FormInput id={'lastName'} label={'Nazwisko'} onChange={this.setFilter}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-lg-6'}>
                                        <div className={'onboarding-form'} style={{padding: 1}}>
                                            <FormInput id={'email'} label={'Email'} onChange={this.setFilter}/>
                                        </div>
                                    </div>
                                    <div className={'col-lg-6'}>
                                        <div className={'row'}>
                                            <div className={'col-xl-6'}>
                                            </div>
                                            <div className={'col-xl-6'}>
                                                <button type="button"
                                                        className={`btn btn-small btn-theme btn-no-arrow`}
                                                        onClick={() => {
                                                            this.getApplicantList({page: 1, itemsPerPage: 10, showDeactivated: false}, this.state.filters);
                                                        }}
                                                >
                                                    <Translation text="applicants.applicantList.buttons.search"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'row'} style={{marginTop: '5rem'}}>
                                <ApplicantsTable applicants={this.state.applicantList} isLoading={false} envData={this.props.envData} />
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                    <Pagination listMetadata={this.state.listMetadata}
                                changePage={this.getApplicantList}
                    />
                </div>
            </section>
        );
    }

    setFilter = (filter: keyof FilterData, value: string) => {
        let filters = this.state.filters;
        filters[filter] = value;
        this.setState({filters: filters});
    }


    getApplicantsExcel() {
        this.setState({isProcessing: true});
        exportApplicantsReportAPI(this.props.authToken)
            .then((blob) => {
                this.alertManager?.addAlert('applicants.applicantView.alert.downloadAccepted');
                this.fileManager?.download(blob, "applicants");
                this.setState({isProcessing: false});
            })
            .catch(error => {
                this.setState({isProcessing: false});
                this.alertManager?.addAlert('applicants.applicantView.alert.downloadError');
                console.error('There was an error!', error);
            });
    }

    private getApplicantList = (paginationData?: PaginationData, filters?: FilterData) => {
        let params = this.prepareQueryFilters(paginationData, filters);
        this.setState({isProcessing: true});
        return this.subscriptions.push(
            getApplicantsAPI(this.props.authToken, null, params).pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        this.setState({
                            isProcessing: false,
                            applicantList: resp['hydra:member'] || [],
                            listMetadata: resp['hydra:view'] || null
                        });
                    }
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    return of(error);
                })
            ).subscribe()
        )
    };

    private prepareQueryFilters(paginationData: PaginationData | undefined, filters: FilterData | undefined) {
        let params = null;
        params = this.addPaginationData(paginationData, params);
        params = this.addSearchFilters(filters, params);
        return params;
    }

    private addPaginationData(paginationData: PaginationData | undefined, params: any) {
        if (paginationData) {
            params = new RestQueryParams()
                .add('page', paginationData.page)
                .add('itemsPerPage', paginationData.itemsPerPage);

            if (paginationData.showDeactivated) {
                params = params
                    .add('deactivated', 'true')
            } else {
                params = params
                    .add('deactivated', 'false')
            }
        }
        return params;
    }

    private addSearchFilters(filters: FilterData | undefined, params: any) {
        if (filters) {
            if (!params) {
                params = new RestQueryParams();
            }
            if (filters.firstName) {
                params = params.add('account.firstName', filters.firstName);
            }
            if (filters.lastName) {
                params = params.add('account.lastName', filters.lastName);
            }
            if (filters.email) {
                params = params.add('account.user.login', filters.email);
            }
        }
        return params;
    }

    // private onValueStateChange = (value: any) => {
    //     this.onValueStateChange$.next(value.target.value);
    // };

    // private onFormValueChange = (value: any) => {
    //     this.setState({
    //         searchValue: value
    //     }, () => this.props.getApplicantList(undefined, value));
    // };
}

export default withTranslation()(connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(ApplicantList)));
