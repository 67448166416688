import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {of, Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {
    authTokenSelector,
    changeBreadcrumbs,
    getApplicantAPI,
} from 'educat-common-web';
import {catchError, map} from "rxjs/operators";
import AccountImage from '../../Shared/AccountImage';
import ApplicantInformation from './ApplicantInformation';



interface MatchProcessParams {
    id?: string;
}

interface IExternalApplicantsProps {
}

interface IConnectedApplicantsProps {
    readonly authToken: string;
}

interface IApplicantViewProps extends
    IExternalApplicantsProps,
    IConnectedApplicantsProps,
    RouteComponentProps<MatchProcessParams> {
}

interface IApplicantViewState {
    applicant: {[key: string]: any} | null;
    additionalApplicantData: {[key: string]: any} | null;
    isProcessing: boolean;
}


class ApplicantView extends React.Component<IApplicantViewProps, IApplicantViewState> {
    private subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IApplicantViewProps) {
        super(props);

        this.state = {
            applicant: null,
            additionalApplicantData: null,
            isProcessing: true
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.getApplicant();
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <section className={`row`}>
                <div className="col-7">
                    <ApplicantInformation isLoading={this.state.isProcessing} applicant={this.state.applicant} additionalApplicantData={this.state.additionalApplicantData}/>
                </div>
                <div className="col-5">
                    <AccountImage type={'largeThumb'} account={this.state.applicant?.account} />
                </div>
            </section>
        );
    }

    private getApplicant = () => {
        const applicantId = this.props.match.params.id;
        return this.subscriptions.push(
            getApplicantAPI(applicantId, this.props.authToken).pipe(
                map((resp: any) => {
                    if (resp) {
                        // const additionalApplicantData = this.props.currentApplicantList.find(applicant => applicant.id === applicantId);
                        this.setState({
                            applicant: resp,
                            // additionalApplicantData: additionalApplicantData,
                            isProcessing: false
                        });
                    }
                }),
                catchError((error: any) => {
                    this.alertManager?.handleApiError(error);
                    this.setState({isProcessing: false});
                    return of(error);
                })
            ).subscribe()
        )
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {
        changeBreadcrumbs
    }
)(withRouter(ApplicantView));
