import {RestQueryParams} from 'educat-common-web';
import {Observable} from 'rxjs';
import {educatAPI} from './provider/educatAPI';

export function getSalesAPI(authToken: string | null, lastName?: string): Observable<any> {
    let headers = undefined;
    let params = new RestQueryParams();
    if (lastName) {
        params = new RestQueryParams()
        .add('ownerAccount.lastName', lastName);
    }
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `service_instances/sold`,
        params,
        headers
    );
}
