import {Observable} from "rxjs";
import {educatAPI} from "./provider/educatAPI";
import {RestQueryParams} from "educat-common-web";

export function deleteMediaObjectAPI(
    authToken: string | null,
    fileId: string
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.delete(
        `media_objects/${fileId}`,
        new RestQueryParams(),
        headers
    );
}
