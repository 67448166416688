import React, {FormEventHandler} from "react";
import FormInput from "../../Shared/FormInput";

interface IPricingFormProps{
    edit: boolean;
    consultationMargin: any;
    mentorList: any;
    subjectTestList: any;
    competenceTestList: any;
    testPackageList: any;
    onSubmit: any;
}

interface IPricingFormState{
    margin: any;
    consultationMargin: any;
    subjectTestPrice: any;
    morrisbyTestPrice: any;
    morrisbyDiagnosticPackagePrice: any;
    subjectPackagePrice: any;
}

class PricingForm extends React.Component<IPricingFormProps, IPricingFormState> {

    constructor(props: any) {
        super(props);
        this.state = {
            margin: this.extractMarginValue(),
            consultationMargin: this.extractConsultationMarginValue() ?? 4,
            subjectTestPrice: this.extractSubjectTestPrice(),
            morrisbyTestPrice: this.extractMorrisbyTestPrice(),
            morrisbyDiagnosticPackagePrice: this.extractMorrisbyDiagnosticPackagePrice(),
            subjectPackagePrice: this.extractSubjectPackagePrice()
        }
    }

    private extractMarginValue(){
        return this.props.mentorList[0].feePercent;
    }

    private extractConsultationMarginValue() {
        return this.props.consultationMargin;
    }

    private extractSubjectTestPrice(){
        return this.props.subjectTestList[0].finalGrossPrice.amount / 100;
    }

    private extractMorrisbyTestPrice(){
        return this.props.competenceTestList[0].finalGrossPrice.amount / 100;
    }

    private extractMorrisbyDiagnosticPackagePrice() {
        return this.props.testPackageList.find((el: any) => el.name.toLowerCase().includes('morrisby')).finalGrossPrice.amount / 100;
    }

    private extractSubjectPackagePrice() {
        return this.props.testPackageList.find((el: any) => el.name.toLowerCase().includes('przedmiotowy')).finalGrossPrice.amount / 100; // TODO: find() condition feels wrong
    }

    render() {
        if (!this.props.mentorList.length || !this.props.subjectTestList.length || !this.props.competenceTestList.length) {
            return null;
        }
        return <form id='pricing-form' onSubmit={this.handleSubmit}>
            <div className={`onboarding-form`}>
                <FormInput number id={'margin'} label={'Marża Educat'} disabled={!this.props.edit} suffix={'%'} initValue={this.state.margin} onChange={this.handleValueChange} />
                <FormInput number id={'consultationMargin'} label={'Marża Educat - Konsultacja wstępna'} disabled={!this.props.edit} suffix={'%'} initValue={this.state.consultationMargin} onChange={this.handleValueChange} />
                <FormInput number id={'subjectTestPrice'} label={'Testy Przedmiotowe'} disabled={!this.props.edit} suffix={'zł'} initValue={this.state.subjectTestPrice} onChange={this.handleValueChange}/>
                <FormInput number id={'morrisbyTestPrice'} label={'Test Morrisby z konsultacją'} disabled={!this.props.edit} suffix={'zł'} initValue={this.state.morrisbyTestPrice} onChange={this.handleValueChange}/>
                <FormInput number id={'morrisbyDiagnosticPackagePrice'} label={'Pakiet diagnostyczny z Morrisby'} disabled={!this.props.edit} suffix={'zł'} initValue={this.state.morrisbyDiagnosticPackagePrice} onChange={this.handleValueChange}/>
                <FormInput number id={'subjectPackagePrice'} label={'Pakiet przedmiotowy'} disabled={!this.props.edit} suffix={'zł'} initValue={this.state.subjectPackagePrice} onChange={this.handleValueChange}/>
            </div>
        </form>;
    }

    handleValueChange = (controlId: any, value:any) => {
        const newState = {[controlId]: value} as Pick<IPricingFormState, keyof IPricingFormState>;
        this.setState(newState)
    }

    handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state)
    }
}

export default PricingForm;
