import React, {useEffect, useState} from "react";
import {
    addAlert,
    authTokenSelector,
    handleApiError,
    Loader,
    LoaderType,
    RestQueryParams,
    Translation,
    AlertObject
} from "educat-common-web";
import {of, Subscription} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {getInquiriesSummaryAPI} from "../../../api/getInquiriesSummaryAPI";
import {useDispatch, useSelector} from "react-redux";
import { IPrice } from "educat-common-web";
import moment from "moment/moment";
import {getInquirySummaryParams, IInquiryListFilters} from "../inquiryFiltersUtil";

export interface IInquirySummary {
    insuranceCount: number;
    premium: typeof IPrice;
    profitAxa: typeof IPrice;
    profitEducat: typeof IPrice;
}

interface IInquiryListTotalsProps {
    selectedFilters: IInquiryListFilters | null;
}


const InquiryListTotals: React.FC<IInquiryListTotalsProps> = ({selectedFilters}) => {
    const dispatch = useDispatch(),
        authToken: string | null = useSelector(authTokenSelector),
        [isLoading, setIsLoading] = useState<boolean>(true),
        [inquiriesSummary, setInquiriesSummary] = useState<IInquirySummary | null>(null);

    const subscriptions: Subscription[] = [];

    useEffect(() => {
        getInquiriesSummary();

        return() => {
            if (subscriptions) {
                subscriptions.forEach(subscription => {
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        getInquiriesSummary();
    }, [selectedFilters]);

    const renderBox = (labelKey: string, value: string) => {
        return (
            <div className="total">
                <label className="total-label"><Translation text={`inquiries.inquiryList.totals.${labelKey}`}/></label>
                <span className="total-value">{value}</span>
            </div>
        )
    }

    const formatPrice = (price: typeof IPrice | null): string => {
        if (price === null) {
            return '0.00 EUR';
        }

        if (price.amount === 0) {
            return `0.00 ${price.currency.code}`;
        }

        const formattedAmount = (price.amount / 100).toFixed(2);
        return `${formattedAmount} ${price.currency.code}`;
    }

    const getInquiriesSummary = () => {

        const params: typeof RestQueryParams = getInquirySummaryParams(selectedFilters);

        return subscriptions.push(
            getInquiriesSummaryAPI(authToken, params).pipe(
                map((resp: any) => {
                    if (resp['hydra:member']) {
                        const dataArray = resp['hydra:member'],
                            defaultPrice = { amount: 0, currency: { code: 'EUR' } },
                            defaultInsuranceCount = 0;

                        const inquirySummary: IInquirySummary = {
                            insuranceCount: dataArray.find((item: any) => item.insuranceCount)?.insuranceCount || defaultInsuranceCount,
                            premium: dataArray.find((item: any) => item.premium)?.premium || defaultPrice,
                            profitAxa: dataArray.find((item: any) => item.profitAxa)?.profitAxa || defaultPrice,
                            profitEducat: dataArray.find((item: any) => item.profitEducat)?.profitEducat || defaultPrice,
                        };
                        setIsLoading(false);
                        setInquiriesSummary(inquirySummary);
                    }
                }),
                catchError((error: any) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }


    return (
        <div className="totals-container">
            <Loader type={LoaderType.Local} showLoader={isLoading}/>
            <h2 className="totals-title"><Translation text={'inquiries.inquiryList.totals.title'}/></h2>
            <div className="totals">
                {renderBox('insuranceNo', inquiriesSummary?.insuranceCount ? String(inquiriesSummary?.insuranceCount) : '0')}
                {renderBox('premiumSum', formatPrice(inquiriesSummary?.premium || null))}
                {renderBox('axaFeeSum', formatPrice(inquiriesSummary?.profitAxa || null))}
                {renderBox('educatFeeSum', formatPrice(inquiriesSummary?.profitEducat || null))}
            </div>
        </div>
    )
}

export default InquiryListTotals;
