import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams, IPrice} from "educat-common-web";

export interface IUpdateInquiryVariantPayload {
    nameTranslation: {
        pl_PL: string;
    },
    basePrice: typeof IPrice;
    europeAdditionalPrice: typeof IPrice;
    worldAdditionalPrice: typeof IPrice;
    alcoholClauseValue: number;
    physicalWorkValue: number;
}

export function updateInquiryVariantAPI(authToken: string | null, variantId: string, payload: IUpdateInquiryVariantPayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.put(
        `inquiry_variants/${variantId}`,
        payload,
        new RestQueryParams(),
        headers
    );
}