import createAPIHandler from "../base/apiFactory";

export const educatAPI = createAPIHandler({
  url: process.env.REACT_APP_API_URL,
  defaultHeaders: {
    "Accept": "application/ld+json",
    "Content-type": "application/ld+json"
  },
  mapper: (data: any): any => {
    return data;
  },
  isList: false
});


export const educatAPIFile = createAPIHandler({
  url: process.env.REACT_APP_API_URL,
  defaultHeaders: {
    'Content-Type': 'application/pdf'
  },
  mapper: (data: any): any => {
    console.log(data)
    return data;
  },
  isList: false
});
