import { combineReducers } from "redux";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {alertSlice, sagaSlice, loginSlice, accountSlice, initLogout} from "educat-common-web";
import authSlice from "./authSlice";


const history = createBrowserHistory();

const appReducer = combineReducers({
    auth: authSlice,
    alert: alertSlice,
    saga: sagaSlice,
    login: loginSlice,
    account: accountSlice,
    router: connectRouter(history)
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, { type: undefined });
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
