import {Observable} from 'rxjs';
import {educatAPI} from './provider/educatAPI';

export function getUsersAPI(authToken: string | null, params: any): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return educatAPI.get(
        `users`,
        params,
        headers
    );
}