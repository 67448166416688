import {
    CustomCard, Translation
} from 'educat-common-web';
import React from 'react';
import InformationRow from '../../../Shared/InformationRow';
import styles from "../../../Shared/UserListStyles/styles.module.scss";
import {MentorProps, MentorStatus} from "../../../../constants/MentorStatus";


interface IExternalOnboardingInformationProps {
    readonly isLoading: boolean;
    readonly mentor: any;
}

interface IOnboardingInformationProps extends IExternalOnboardingInformationProps {
    modalTrigger: any;
}

class OnboardingInformation extends React.Component<IOnboardingInformationProps> {
    render() {
        const mentor = this.props.mentor
        if (!mentor?.mentorOnboarding) return <div />;
        return (
            <CustomCard showLocalLoader={this.props.isLoading}>
                <CustomCard.Header>
                    <div className={'d-flex justify-content-between'}>
                        <h2 className={styles.title}>
                            <Translation text={'mentors.mentorView.onboardingInformation.title'} />
                        </h2>
                        { this._renderEditDataBtn(mentor) }
                    </div>
                </CustomCard.Header>
                <CustomCard.Body>
                    <div>
                        <table className="data-table">
                            <tbody>
                                <InformationRow key={'mentors.mentorView.onboardingInformation.nationality.label'}
                                keyName={'mentors.mentorView.onboardingInformation.nationality.label'}
                                    value={mentor.mentorOnboarding.nationality ?
                                        <Translation
                                            text={`mentors.mentorView.onboardingInformation.nationality.${mentor.mentorOnboarding.nationality}`} /> :
                                        null} />
                                <InformationRow key={'mentors.mentorView.onboardingInformation.businessOwner.label'}
                                keyName={'mentors.mentorView.onboardingInformation.businessOwner.label'}
                                    value={mentor.mentorOnboarding.businessOwner ?
                                        <Translation
                                            text={`mentors.mentorView.onboardingInformation.businessOwner.${mentor.mentorOnboarding.businessOwner}`} /> :
                                        null} />
                                <InformationRow key={'mentors.mentorView.onboardingInformation.over26.label'}
                                keyName={'mentors.mentorView.onboardingInformation.over26.label'}
                                    value={mentor.mentorOnboarding.over26 ?
                                        <Translation
                                            text={`mentors.mentorView.onboardingInformation.over26.${mentor.mentorOnboarding.over26}`} /> :
                                        null} />
                                <InformationRow key={'mentors.mentorView.onboardingInformation.student.label'}
                                keyName={'mentors.mentorView.onboardingInformation.student.label'}
                                    value={mentor.mentorOnboarding.student ?
                                        <Translation
                                            text={`mentors.mentorView.onboardingInformation.student.${mentor.mentorOnboarding.student}`} /> :
                                        null} />
                                <InformationRow key={'mentors.mentorView.onboardingInformation.bankAccountNumber'}
                                keyName={'mentors.mentorView.onboardingInformation.bankAccountNumber'}
                                    value={mentor.mentorOnboarding.bankAccountNumber} />
                                <InformationRow key={'mentors.mentorView.onboardingInformation.bankData'}
                                keyName={'mentors.mentorView.onboardingInformation.bankData'}
                                    value={mentor.mentorOnboarding.bankData} />
                                <InformationRow key={'mentors.mentorView.onboardingInformation.residency.label'}
                                keyName={'mentors.mentorView.onboardingInformation.residency.label'}
                                    value={mentor.mentorOnboarding.residency ?
                                        <Translation
                                            text={`mentors.mentorView.onboardingInformation.residency.${mentor.mentorOnboarding.residency}`} /> :
                                        null} />
                            </tbody>
                        </table>
                    </div>
                </CustomCard.Body>
            </CustomCard>
        );
    }

    private _renderEditDataBtn(item: any) {
        return (
            <button type="button"
                    className="btn btn-theme btn-small mb-lg-auto position-relative"
                    style={{top: '-1rem', right: '-1rem'}}
                    hidden={MentorProps.getStatus(item) !== MentorStatus.mentorActive}
                    onClick={() => this.props.modalTrigger()}
            >
                <Translation text={'buttons.edit'}/>
            </button>
        )
    }

}

export default OnboardingInformation;
