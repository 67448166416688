import {
    deepCloneObject,
    Form,
    FormControlChangeType,
    IFormConfig,
    isNullOrUndefined, Translation
} from 'educat-common-web';
import React from 'react';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {fixInjectedProperties, lazyInject} from '../../../../../ioc';
import {IOnboardingHelperService, MentorOnboardingSteps} from '../../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService';
import OnboardingFormHeader from '../../../../OnboardingHost/MentorOnboarding/Common/OnboardingFormHeader';
import {mentorApplicationExamPricesFormConfig} from './mentorApplicationExamPricesFormConfig';

interface IConnectedFormStepApplicationExamPricesProps {
}

interface IExternalFormStepApplicationExamPricesProps {
    readonly submitStep: (stepName: MentorOnboardingSteps, stepValue: any) => void;
    readonly prevStep: () => void;
    readonly stepData: any;
    readonly serviceApplicationElementsExam: any[];
    readonly mentorFee: number;
    readonly hidden: boolean
}

interface IFormStepApplicationExamPricesProps
    extends IConnectedFormStepApplicationExamPricesProps,
        IExternalFormStepApplicationExamPricesProps {
}

interface IFormStepApplicationExamPricesState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    stepName: MentorOnboardingSteps;
    formConfig: typeof IFormConfig;
}

class FormApplicationExamPrices extends React.Component<IFormStepApplicationExamPricesProps, IFormStepApplicationExamPricesState> {
    readonly subscriptions: Subscription[] = [];
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;

    constructor(props: IFormStepApplicationExamPricesProps) {
        super(props);

        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: null,
            stepValue: null,
            stepName: MentorOnboardingSteps.APPLICATION_EXAM_PRICES,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );

        this.setupFormData();
    }

    componentDidUpdate(prevProps: Readonly<IFormStepApplicationExamPricesProps>, prevState: Readonly<IFormStepApplicationExamPricesState>, snapshot?: any) {
        if (prevProps.stepData !== this.props.stepData) {
            this.setupFormData();
        }
    }

    private setupFormData() {
        if (this.props.serviceApplicationElementsExam && this.onboardingHelperService) {
            const groups = this.onboardingHelperService?.createFormGroupsFromServiceApplicationElements(
                'application_exam',
                this.props.serviceApplicationElementsExam.filter((el:any)=>!!el.mentorServiceDefinitionId)
            );

            let updatedGroups = deepCloneObject(groups);

            if (this.props.stepData?.application_exam && Object.keys(this.props.stepData.application_exam).length > 0) {
                const applicationExamsStepData = this.props.stepData['application_exam'];
                updatedGroups.map((control: any) => {
                    if (control.hasOwnProperty('controls')) {
                        Object.keys(control.controls).map((key: string) => {
                            const stepDataItem = applicationExamsStepData[key];
                            if (stepDataItem) {
                                control.controls[key].defaultValue = stepDataItem;
                                control.controls[key].value = stepDataItem;
                                control.controls[key].hostClass = 'col-xl-2 onboarding-table-cell hide-label cell-input';
                            }
                            return control.controls[key];
                        });
                    }

                    return control;
                });
                updatedGroups = this.getRecalculatedGroupPrices(this.props.stepData, updatedGroups);

            }

            this.setState({formConfig: mentorApplicationExamPricesFormConfig(updatedGroups)});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <>
                <div className={`onboarding-form-wrapper ${this.props.hidden ? 'd-none' : ''}`}>
                    <OnboardingFormHeader hideSubtitle stepName={this.state.stepName}/>
                    <Form
                        config={this.state.formConfig}
                        controlName={MentorOnboardingSteps.APPLICATION_EXAM_PRICES}
                        onValueStateChange={this.onValueStateChange}
                        onValidationStateChange={this.formValidityChange}
                        value={{}}
                    />
                    <button
                        type="submit"
                        onClick={() => this.props.submitStep(MentorOnboardingSteps.APPLICATION_EXAM_PRICES, this.state.stepValue)}
                        className="btn btn-theme btn-rounded align-self-end mb-1 mt-4"
                    >
                        <Translation text="buttons.save"/>
                    </button>
                </div>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };
    private onFormValueChange = (value: any) => {
        let updatedFormConfig = deepCloneObject(this.state.formConfig);
        if (value['application_exam']) {
            Object.keys(value['application_exam'])
                .filter(
                    (key) =>
                        !key.includes('educat_commission_') &&
                        !key.includes('platform_price_') &&
                        !key.includes('vat_') &&
                        !isNullOrUndefined(value['application_exam'][key])
                )
                .forEach((key: string) => {
                    const selectedValue = value['application_exam'][key];
                    const countedValues = this.onboardingHelperService.countServicePricesFromInput(
                        typeof selectedValue === 'number' ? selectedValue : Number(selectedValue),
                        this.props.mentorFee
                    );

                    updatedFormConfig.controls[0].controls[`educat_commission_${key}`].defaultValue = countedValues.eduCatCommission
                        ? countedValues.eduCatCommission
                        : null;
                    updatedFormConfig.controls[0].controls[`educat_commission_${key}`].value = countedValues.eduCatCommission
                        ? countedValues.eduCatCommission
                        : null;
                    updatedFormConfig.controls[0].controls[`platform_price_${key}`].defaultValue = countedValues.grossPrice
                        ? countedValues.grossPrice
                        : null;
                    updatedFormConfig.controls[0].controls[`platform_price_${key}`].value = countedValues.grossPrice
                        ? countedValues.grossPrice
                        : null;
                    updatedFormConfig.controls[0].controls[`vat_${key}`].defaultValue = countedValues.vat ? countedValues.vat : null;
                    updatedFormConfig.controls[0].controls[`vat_${key}`].value = countedValues.vat ? countedValues.vat : null;
                });
        }
        this.setState({formConfig: updatedFormConfig, stepValue: value});
    };


    private getRecalculatedGroupPrices(value: any, groups: any) {
        let updatedGroups = deepCloneObject(groups);
        if (value['application_exam']) {
            Object.keys(value['application_exam'])
                .filter(
                    (key) =>
                        !key.includes('educat_commission_') &&
                        !key.includes('platform_price_') &&
                        !key.includes('vat_') &&
                        !isNullOrUndefined(value['application_exam'][key])
                )
                .forEach((key: string) => {
                    const selectedValue = value['application_exam'][key];
                    const countedValues = this.onboardingHelperService.countServicePricesFromInput(
                        typeof selectedValue === 'number' ? selectedValue : Number(selectedValue),
                        this.props.mentorFee
                    );

                    if (updatedGroups[0].controls[`educat_commission_${key}`]){
                        updatedGroups[0].controls[`educat_commission_${key}`].defaultValue = countedValues.eduCatCommission
                            ? countedValues.eduCatCommission
                            : null;
                        updatedGroups[0].controls[`educat_commission_${key}`].value = countedValues.eduCatCommission
                            ? countedValues.eduCatCommission
                            : null;
                        updatedGroups[0].controls[`platform_price_${key}`].defaultValue = countedValues.grossPrice
                            ? countedValues.grossPrice
                            : null;
                        updatedGroups[0].controls[`platform_price_${key}`].value = countedValues.grossPrice
                            ? countedValues.grossPrice
                            : null;
                        updatedGroups[0].controls[`vat_${key}`].defaultValue = countedValues.vat ? countedValues.vat : null;
                        updatedGroups[0].controls[`vat_${key}`].value = countedValues.vat ? countedValues.vat : null;
                    }

                });
        }
        return updatedGroups;
    }

    private formValidityChange = (controlName: string, isValid: boolean) => {

        if (this.props.serviceApplicationElementsExam?.length === 0) {
            return this.setState({isStepValid: true});
        }
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
        }
        return null;
    };
}

export default FormApplicationExamPrices;
