import {
    authTokenSelector,
    deepCloneObject,
    IModelMentorServiceDefinitionRates,
    isNotNullOrUndefined,
    Loader
} from "educat-common-web";
import React from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import PricePackageItem from "./PricePackageItem";
import {fixInjectedProperties, lazyInject} from "../../../../../ioc";
import {IAlertManagerService} from "../../../../../service/alertManagerService";
import {forkJoin, of, Subscription} from "rxjs";
import {connect} from "react-redux";
import {RootState} from "../../../../../store/reducers";
import {
    IOnboardingHelperService,
    MentorOnboardingSteps,
    MentorServiceDefinitionPayload
} from "../../../../OnboardingHost/MentorOnboarding/Common/converters/onboardingHelperService";
import FormHourlyRates from "../FormHourlyRates";
import FormApplicationExamPrices from "../FormApplicationExamPrices";
import FormHourPackages from "../FormHourPackages";
import FormServicePackages from "../FormServicePackages";
import FormFreeHelpPackages from "../FormFreeHelpPackages";
import {CalculatedPrices} from "../../../../../api/provider/educatCalculatedPricesAPI";
import {catchError, switchMap, tap} from "rxjs/operators";
import {updateMentorServiceDefinitionsAPI} from "../../../../../api/updateMentorServiceDefinitions";
import {sendMentorServiceDefinitionsAPI} from "../../../../../api/sendMentorServiceDefinitions";
import {changeMentorServiceConfigAPI} from "../../../../../api/changeMentorServiceConfig";
import {MentorAccount} from "../../../../../service/mentorRegistrationService";
import {setMentorServiceDefinitionsActiveStatusAPI} from "../../../../../api/setMentorServiceDefinitionsActiveStatus";


interface IConnectedServicesCardProps {
    authToken: string;
}

interface IExternalServicesCardProps {
    serviceApplicationElementsList: any[];
    serviceApplicationPackagesList: any[];
    serviceConsultationPackagesList: any[];
    serviceConsultationsList: any[];
    freeServiceConsultationPackageList: any[];
    calculatedPackagePrices: CalculatedPrices;
    mentor: any;
    trialFee: any;
    reloadUserData: any;
    setCalculatedPackagePrices: any;
}

interface IPricePackageListProps extends IConnectedServicesCardProps,
    IExternalServicesCardProps,
    WithTranslation {
}

interface IPricePackageListState {
    isProcessing: boolean;
    services: typeof IModelMentorServiceDefinitionRates[] | [];
    showList: boolean;
    shownForm: string;
    rawFormValue: any;
    serviceApplicationElementsGeneral: any[];
    serviceApplicationPackageListWithUpdatedPrices: any[];
    consultationPackageListWithUpdatedPrices: any[];
}


class PricePackageList extends React.Component<IPricePackageListProps, IPricePackageListState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject("AlertManagerService") private alertManagerService: IAlertManagerService;
    @lazyInject('OnboardingHelperService') private onboardingHelperService: IOnboardingHelperService;


    constructor(props: IPricePackageListProps) {
        super(props);
        this.state = {
            isProcessing: false,
            services: [],
            showList: true,
            shownForm: '',
            rawFormValue: null,
            serviceApplicationElementsGeneral: [],
            serviceApplicationPackageListWithUpdatedPrices: [],
            consultationPackageListWithUpdatedPrices: []
        };
        fixInjectedProperties(this);
    }

    componentDidMount(): void {
        this.convertMentorDataToRawFormData();
        this.attachMentorDataToList();

        const consultationPackageListWithUpdatedPrices = this.onboardingHelperService.updateCurrentConsultationPackages(
            this.props.serviceConsultationPackagesList,
            this.props.calculatedPackagePrices.serviceConsultationPackagePrices
        );

        const serviceApplicationPackageListWithUpdatedPrices = this.onboardingHelperService.updateCurrentServiceApplicationPackages(
            this.props.serviceApplicationPackagesList,
            this.props.calculatedPackagePrices.serviceApplicationPackagePrices
        );

        this.setState({
            serviceApplicationPackageListWithUpdatedPrices: serviceApplicationPackageListWithUpdatedPrices,
            consultationPackageListWithUpdatedPrices: consultationPackageListWithUpdatedPrices
        })

    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private convertMentorDataToRawFormData() {
        this.setState({isProcessing: true});
        const updatedRawFormData = this.onboardingHelperService.convertMentorDataToRawFormData(this.props.mentor.mentorServiceDefinitions, this.props.mentor, this.props.trialFee);
        this.setState({rawFormValue: updatedRawFormData, isProcessing: false});
    }

    render() {

        return (
            <div className="row">
                <Loader showLoader={this.state.isProcessing}/>
                <div className="col-12">
                    {this.state.showList && <>
                        <PricePackageItem label={`mentorOnboarding.onboarding.hourly_rates.formTitle`}
                                          onClick={()=> {
                                              this.showForm(MentorOnboardingSteps.HOURLY_RATES)
                                          }}/>
                        <PricePackageItem label={`mentorOnboarding.onboarding.application_exam_prices.formTitle`}
                                          onClick={()=> {
                                              this.showForm(MentorOnboardingSteps.APPLICATION_EXAM_PRICES)
                                          }}/>
                        <PricePackageItem label={`mentorOnboarding.onboarding.hour_packages.formTitle`}
                                          onClick={()=> {
                                              this.showForm(MentorOnboardingSteps.HOUR_PACKAGES)
                                          }}/>
                        <PricePackageItem label={`mentorOnboarding.onboarding.service_packages.formTitle`}
                                          onClick={()=>this.showForm(MentorOnboardingSteps.SERVICE_PACKAGES)}/>
                        <PricePackageItem label={`mentorOnboarding.onboarding.free_help_packages.formTitle`}
                                          onClick={()=>this.showForm(MentorOnboardingSteps.FREE_HELP_PACKAGES)}/>
                    </>}

                    <FormHourlyRates serviceApplicationElementsGeneral={this.state.serviceApplicationElementsGeneral}
                                 submitStep={this.submitStep}
                                 prevStep={() => {}}
                                 stepData={this.isolatedStepValue(MentorOnboardingSteps.HOURLY_RATES)}
                                 trialFee={this.props.trialFee}
                                 mentorFee={this.mentorFee}
                                 hidden={this.state.shownForm !== MentorOnboardingSteps.HOURLY_RATES}
                    />
                    <FormApplicationExamPrices
                        mentorFee={Number(this.props.mentor.feePercent)}
                        submitStep={this.submitStep}
                        prevStep={()=>{}}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.APPLICATION_EXAM_PRICES)}
                        serviceApplicationElementsExam={this.props.serviceApplicationElementsList}
                        hidden={this.state.shownForm !== MentorOnboardingSteps.APPLICATION_EXAM_PRICES}
                    />
                    <FormHourPackages
                        submitStep={this.submitStep}
                        prevStep={()=>{}}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.HOUR_PACKAGES)}
                        serviceConsultationPackageList={this.state.consultationPackageListWithUpdatedPrices}
                        hidden={this.state.shownForm !== MentorOnboardingSteps.HOUR_PACKAGES}
                    />
                    <FormServicePackages
                        submitStep={this.submitStep}
                        prevStep={()=>{}}
                        stepData={this.isolatedStepValue(MentorOnboardingSteps.SERVICE_PACKAGES)}
                        serviceApplicationPackageList={this.state.serviceApplicationPackageListWithUpdatedPrices}
                        hidden={this.state.shownForm !== MentorOnboardingSteps.SERVICE_PACKAGES}
                    />
                    <FormFreeHelpPackages
                        reloadUserData={()=>{}}
                        freeServiceConsultationPackageList={this.props.freeServiceConsultationPackageList}
                        submitStep={this.submitStep}
                        prevStep={()=>{}}
                        mentorData={this.props.mentor}
                        hidden={this.state.shownForm !== MentorOnboardingSteps.FREE_HELP_PACKAGES}
                    />


                </div>
            </div>
        );
    }

    showForm(formType: string){
        this.setState({shownForm: formType, showList: false});
    }


    private get mentorFee() {
        return Number(this.props.mentor.feePercent ?? 0);
    }

    private isolatedStepValue = (stepName: MentorOnboardingSteps): any => {
        const value = this.state?.rawFormValue?.[stepName];

        return (isNotNullOrUndefined(value) && Object.keys(value).length > 0) ?
            deepCloneObject(value) :
            null;
    };

    private submitStep = (stepName: MentorOnboardingSteps, stepValue: any) => {
        const updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
        const prevRawFormValue = deepCloneObject(this.state.rawFormValue);
        updatedRawFormValue[stepName] = stepValue;
        this.setState({isProcessing: true});

        if (stepName === MentorOnboardingSteps.HOURLY_RATES && this.props.mentor.id) {
            this.updateMentorServiceDefinitions(stepValue, stepName);
        }
        if (stepName === MentorOnboardingSteps.APPLICATION_EXAM_PRICES && this.props.mentor.id) {
            this.updateMentorServiceDefinitions(stepValue, stepName);
        }

        if ((stepName === MentorOnboardingSteps.HOUR_PACKAGES || stepName === MentorOnboardingSteps.SERVICE_PACKAGES)) {
            const prevPackages = prevRawFormValue[stepName];
            let newPackages = stepValue;
            // remove keys which contain data about calculated prices
            Object.keys(newPackages).forEach(key => {
                if (key.includes('_')){
                    delete newPackages[key];
                }
            })

            let IDsOfServicesToDisable: any[] = [];
            Object.keys(newPackages).forEach(key=>{   // browse updated packages
                if (Object.keys(prevPackages).includes(key)){  // if updated package already existed
                    const definitionIdToUpdate = this.props.mentor.mentorServiceDefinitions.find((definition:any)=>{
                        let obj = Object.keys(definition)
                            .filter(defKey=> defKey.startsWith('service'))
                            .find(defKey=> {
                                return definition[defKey]?.id === key
                            })
                        return obj;
                    }).id;
                    IDsOfServicesToDisable.push({definitionId: definitionIdToUpdate, setActive: !!(newPackages[key].active ?? newPackages[key])});
                }
            })

            this.updateMentorHourPackages(stepValue, IDsOfServicesToDisable, stepName);
        }

        if (stepName === MentorOnboardingSteps.FREE_HELP_PACKAGES){
            if (stepValue !== null) {
                const freePackageServiceDefinition = this.props.mentor.mentorServiceDefinitions.find((definition: any)=>
                    definition.serviceApplicationPackage?.id === this.props.freeServiceConsultationPackageList[0]?.id
                )

                if (freePackageServiceDefinition && stepValue.selected){
                    changeMentorServiceConfigAPI(this.props.authToken, this.props.mentor.id, stepValue.maxApplicants).subscribe(()=>{
                        this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                        this.setState({ isProcessing: false });
                        window.location.reload();
                    });
                }
                if (!freePackageServiceDefinition && stepValue.selected){
                    this.sendMentorFreePackageDataAndSubmitStep(stepValue)
                } else if (freePackageServiceDefinition){
                    this.setState({isProcessing: true});
                    setMentorServiceDefinitionsActiveStatusAPI(this.props.authToken,
                        {definitionProperties: [{definitionId: freePackageServiceDefinition.id, setActive: stepValue.selected}]},
                        this.props.mentor.id)
                    .subscribe(()=>{
                        this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                        this.setState({ isProcessing: false });
                        window.location.reload();
                    });

                }
            } else {
                this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                this.setState({ isProcessing: false });
                window.location.reload();
            }

        }

        window.scrollTo(0, 0);
    };


    private updateMentorServiceDefinitions(stepValue: any, formType: MentorOnboardingSteps) {
        const additionalDataPayloadServiceItems = {
            general: deepCloneObject(this.props.serviceConsultationsList),
            exam: deepCloneObject(this.props.serviceApplicationElementsList),
        };

        const payload = this.onboardingHelperService.convertRawDataToPayload(
                stepValue,
                this.props.mentor.id,
                formType,
            { mentorFee: this.mentorFee, trialFee: this.props.trialFee },
                additionalDataPayloadServiceItems
            );

        let updateRequests = payload.map((serviceDefinition:any)=>{
            const serviceIdKey = Object.keys(serviceDefinition)
                .find(key=>key.startsWith('service') && isNotNullOrUndefined(serviceDefinition[key])) as string;
            const serviceId = serviceDefinition[serviceIdKey];

            const mentorServiceDefinitionId = formType === MentorOnboardingSteps.HOURLY_RATES
                ? this.state.serviceApplicationElementsGeneral.find(el=>el.id === serviceId).mentorServiceDefinitionId
                : this.props.serviceApplicationElementsList.find(el=>el.id === serviceId).mentorServiceDefinitionId;

            return updateMentorServiceDefinitionsAPI(this.props.authToken, serviceDefinition, mentorServiceDefinitionId);
        })
        if (updateRequests.length === 0){
            updateRequests = [of(true)];
        }


        this.setState({isProcessing: true});

        if (payload && updateRequests)
        this.subscriptions.push(
            forkJoin(updateRequests)
                .pipe(
                    catchError(() => {
                        this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: any) => {
                        if (response) {
                            this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                            this.setState({ isProcessing: false });
                        }
                    })
                )
                .subscribe(()=>{
                    window.location.reload()
                })
        );
    }


    private updateMentorHourPackages(stepValue: any, IDsOfServicesToDisable: any[], packageType: MentorOnboardingSteps.HOUR_PACKAGES | MentorOnboardingSteps.SERVICE_PACKAGES) {
        stepValue = this.discardRedundantPackages(stepValue, packageType);
        const additionalDataPayloadServiceItems = packageType === MentorOnboardingSteps.HOUR_PACKAGES
            ? this.onboardingHelperService.updateCurrentConsultationPackages(
                this.props.serviceConsultationPackagesList,
                this.props.calculatedPackagePrices.serviceConsultationPackagePrices
            )
            : this.onboardingHelperService.updateCurrentConsultationPackages(
                this.props.serviceApplicationPackagesList,
                this.props.calculatedPackagePrices.serviceApplicationPackagePrices
            );
        const payload = this.onboardingHelperService.convertRawDataToPayload(
            stepValue,
            this.props.mentor.id,
            packageType,
            {mentorFee: this.mentorFee, trialFee: this.props.trialFee},
            additionalDataPayloadServiceItems
        );
        this.setState({isProcessing: true});
        this.subscriptions.push(
            forkJoin([
                setMentorServiceDefinitionsActiveStatusAPI(this.props.authToken, {definitionProperties: IDsOfServicesToDisable}, this.props.mentor.id),
                sendMentorServiceDefinitionsAPI(this.props.authToken, payload)
            ])
                .pipe(
                    catchError(() => {
                        this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                    tap((response: any) => {
                        if (response) {
                            let updatedRawFormValue = deepCloneObject(this.state.rawFormValue);
                            updatedRawFormValue[packageType] = stepValue;
                            this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                            this.setState({
                                isProcessing: false,
                                rawFormValue: updatedRawFormValue
                            });
                        }
                    })
                )
                .subscribe(()=>{
                    window.location.reload()
                })
        );
    }

    private sendMentorFreePackageDataAndSubmitStep(stepValue: any) {
        const freePackageItem = this.props.freeServiceConsultationPackageList?.[0],
            mentorServiceDefinition: MentorServiceDefinitionPayload = {
                name: freePackageItem.name,
                description: '',
                itemPrice: {
                    amount: 0,
                    currency: {
                        code: 'PLN',
                    },
                },
                mentorId: this.props.mentor.id,
                serviceApplicationElementId: null,
                serviceApplicationPackageId: freePackageItem.id,
                serviceConsultationId: null,
                serviceConsultationPackageId: null
            },
            newMaxApplicants = stepValue.maxApplicants;
        const payload = [];

        payload.push(mentorServiceDefinition);
        this.setState({isProcessing: true});

        this.subscriptions.push(
            sendMentorServiceDefinitionsAPI(this.props.authToken, payload)
                .pipe(
                    switchMap(() => changeMentorServiceConfigAPI(this.props.authToken, this.props.mentor.id, newMaxApplicants)),
                    tap((response: MentorAccount) => {
                        if (response) {
                            this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionSuccess');
                            this.setState({ isProcessing: false });
                        }
                    }),
                    catchError(() => {
                        this.alertManagerService?.addAlert('mentorOnboarding.onboarding.alerts.createServiceDefinitionFailure');
                        this.setState({isProcessing: false});
                        return of();
                    }),
                )
                .subscribe(()=>{
                    window.location.reload();
                })
        );
    }


    private attachMentorDataToList() {
        const list = this.props.serviceConsultationsList.map((consultation: any)=>{
            const mentorServiceDefinitionId = this.props.mentor.mentorServiceDefinitions.find((def:any) => {
                const serviceKeys = Object.keys(def).filter(key => key.startsWith('service'));
                for (const serviceKey of serviceKeys) {
                    if (def[serviceKey]?.id === consultation.id){
                        return true;
                    }
                }
                return false;
            })?.id;

            consultation.mentorServiceDefinitionId = mentorServiceDefinitionId;
            return consultation;
        });
        this.setState({serviceApplicationElementsGeneral: list})
    }

    private discardRedundantPackages(stepValue: any, step: MentorOnboardingSteps.HOUR_PACKAGES | MentorOnboardingSteps.SERVICE_PACKAGES) {
        let newStepValue = deepCloneObject(stepValue);
        let idsMarkedAsTrue = Object.keys(stepValue).filter(key=>{
            return stepValue[key];
        });

        this.props.mentor.mentorServiceDefinitions
            .map((el:any) => {
                if (step === MentorOnboardingSteps.HOUR_PACKAGES){
                    return el.serviceConsultationPackage;
                } else {
                    return el.serviceApplicationPackage;
                }
            })
            .filter((el:any)=> !!el)
            .forEach((packageData:any) => {
                if (idsMarkedAsTrue.includes(packageData.id)){
                    delete newStepValue[packageData.id]
                }
            });

        return newStepValue;
    }
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state)
    }),
    {}
)(withTranslation()(PricePackageList));
