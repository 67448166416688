import {injectable} from 'inversify';
import {fixInjectedProperties} from "../ioc";

export interface IFileManagerService {
    download(blob: any, fileName: string): void;
}

@injectable()
class FileManagerService implements IFileManagerService {
    constructor() {
        fixInjectedProperties(this);
    }

    public download(blob: any, fileName: string): void {
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName + `.xlsx`
        document.body.appendChild(link);
        link.click();
        // link.parentNode.removeChild(link);
        link.remove();
    }
}

export default FileManagerService;