import React from "react";

interface IFormInputProps {
    id: string,
    label: string,
    suffix?: string,
    disabled?: boolean,
    initValue?: any,
    onChange?: any,
    number?: boolean

}

interface IFormInputState {
    value: string
}

class FormInput extends React.Component<IFormInputProps, IFormInputState> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: this.props.initValue ?? (this.props.number ? '0' : '')
        }
    }

    render() {
        const suffix = this.props.suffix;
        return <div className={`row`}>
            <div className={`col-xl-6 form-row form-row-edit d-flex flex-row align-items-center ms-auto me-auto`} style={{maxWidth: '650px'}}>
                <label htmlFor={this.props.id} style={{flex: 1}}>{this.props.label}</label>
                <div className={`input-container ${suffix ? 'd-flex flex-row' : ''}`}>
                    <input id={this.props.id} className={`input grey-on-disable ms-2 me-2 ${suffix ? 'text-end' : ''}`}
                           disabled={!!this.props?.disabled} value={this.state.value} onChange={this.handleChange}/>
                    {suffix && <span className={'align-self-center'}>{suffix}</span>}
                </div>
            </div>
        </div>;
    }

    handleChange = (event: any) => {
        let result: string = '';
        if (this.props.number) {
            result = event.target.value.replace(/\D/g, '');
            result = this.trimStartingZeros(result);
            if (result.length == 0){
                result = '0';
            }
        } else {
            result = event.target.value;
        }
        this.setState({value: result});
        if (this.props.onChange){
            this.props.onChange(this.props.id, result)
        }
    }

    trimStartingZeros(value: string): string {
        if (value.length > 1 && value.charAt(0) === '0'){
            return this.trimStartingZeros(value.substring(1))
        } else {
            return value;
        }
    }
}
export default FormInput;