 import {
    FormControlType,
    IFormConfig,
    InputType, ValidationRules
} from 'educat-common-web';

export const mentorOnboardingInformationFormConfig: typeof IFormConfig = (value?: any) => ({
    controlType: 'form',
    class: 'onboarding-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'onboarding_information',
            controlType: 'group',
            class: 'row',
            controls: {
                nationality: {
                    hostClass: 'col-xl-12 form-row',
                    controlType: 'control',
                    defaultValue: (value && value['nationality']) ? value['nationality'] : null,
                    value: (value && value['nationality']) ? value['nationality'] : null,
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'polish', displayValue: 'Polskiej'},
                        {value: 'other', displayValue: 'Innej'},
                    ],
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED}
                    ],
                    label: 'mentorOnboarding.onboarding.onboarding_information.formControls.nationality.label',
                    type: InputType.RADIO,
                },
                businessOwner: {
                    hostClass: 'col-xl-12 form-row',
                    controlType: 'control',
                    defaultValue: (value && value['businessOwner']) ? value['businessOwner'] : null,
                    value: (value && value['businessOwner']) ? value['businessOwner'] : null,
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'true', displayValue: 'Mam działalność'},
                        {value: 'false', displayValue: 'Nie mam działalności'},
                    ],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    label: 'mentorOnboarding.onboarding.onboarding_information.formControls.businessOwner.label',
                    type: InputType.RADIO,
                },
                over26: {
                    hostClass: 'col-xl-12 form-row',
                    controlType: 'control',
                    defaultValue: (value && value['over26']) ? value['over26'] : null,
                    value: (value && value['over26']) ? value['over26'] : null,
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'false', displayValue: 'Poniżej 26 lat'},
                        {value: 'true', displayValue: '26 lat lub więcej'},
                    ],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    label: 'mentorOnboarding.onboarding.onboarding_information.formControls.over26.label',
                    type: InputType.RADIO,
                },
                student: {
                    hostClass: 'col-xl-12 form-row',
                    controlType: 'control',
                    defaultValue: (value && value.student) ? value.student : null,
                    value: (value && value.student) ? value.student : null,
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'true', displayValue: 'Jestem studentem'},
                        {value: 'false', displayValue: 'Nie jestem studentem'},
                    ],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    label: 'mentorOnboarding.onboarding.onboarding_information.formControls.student.label',
                    type: InputType.RADIO,
                },
                // otherEmployment: {
                //     controlType: 'control',
                //     defaultValue: value ? value.longDescription : '',
                //     formControlType: FormControlType.TEXTAREA,
                //     validationRules: [],
                //     placeholder: 'mentorOnboarding.onboarding.onboarding_information.formControls.placeholder',
                //     isLabelHidden: false,
                //     rows: 5,
                //     label: 'mentorOnboarding.onboarding.onboarding_information.formControls.otherEmployment.label',
                //     type: InputType.TEXT,
                //     hostClass: 'col-xl-12 form-row'
                // },
                // label: {
                //     controlType: 'control',
                //     formControlType: FormControlType.LABEL,
                //     label: 'mentorOnboarding.onboarding.onboarding_information.formControls.bankDataLabel',
                //     hostClass: 'col-xl-12 onboarding-info-label',
                // },
                bankAccountNumber: {
                    controlType: 'control',
                    defaultValue: value ? value.bankAccountNumber : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}, {name: ValidationRules.IS_IBAN}],
                    placeholder: 'mentorOnboarding.onboarding.onboarding_information.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'mentorOnboarding.onboarding.onboarding_information.formControls.bankAccountNumber.label',
                    type: InputType.TEXT,
                    showErrorBelow: true,
                    hostClass: 'col-xl-12 form-row bank-account'
                },
                bankData: {
                    controlType: 'control',
                    defaultValue: value ? value.bankData : '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'mentorOnboarding.onboarding.onboarding_information.formControls.placeholder',
                    isLabelHidden: false,
                    rows: 2,
                    label: 'mentorOnboarding.onboarding.onboarding_information.formControls.bankData.label',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-row'
                },
                residency: {
                    hostClass: 'col-xl-12 form-row',
                    controlType: 'control',
                    defaultValue: (value && value['residency']) ? value['residency'] : null,
                    value: (value && value['residency']) ? value['residency'] : null,
                    formControlType: FormControlType.RADIO,
                    options: [
                        {value: 'true', displayValue: 'Tak'},
                        {value: 'false', displayValue: 'Nie'},
                        {value: 'notSure', displayValue: 'Nie wiem'},
                    ],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    label: 'mentorOnboarding.onboarding.onboarding_information.formControls.residency.label',
                    type: InputType.RADIO,
                },

            }
        }
    ]
});
