import { Container } from 'inversify';
import getDecorators, { fixInjectedProperties } from './decorators';
import {AlertManagerService, OnboardingHelperService, FileManagerService} from '../service';
// import FileManagerService from "../service/fileManagerService";


const container = new Container();

container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
container.bind<FileManagerService>('FileManagerService').to(FileManagerService).inSingletonScope();
container.bind<OnboardingHelperService>('OnboardingHelperService').to(OnboardingHelperService).inSingletonScope();
const { lazyInject } = getDecorators(container);

export { container, lazyInject, fixInjectedProperties };
