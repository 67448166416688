import React, {useEffect} from 'react';
import {addAlert, AlertObject, authTokenSelector, handleApiError, Translation} from 'educat-common-web';
import {of, Subscription} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {useDispatch, useSelector} from "react-redux";
import {sendInquiryEmailAPI} from "../../../../../api/sendInquiryEmailAPI";

interface IResendInsuranceEmailButtonProps {
    inquiryId: string;
    setIsLoading: (isLoading: boolean) => void;
    isDisabled: boolean;
}

const ResendInsuranceEmailButton: React.FC<IResendInsuranceEmailButtonProps> = ({inquiryId, setIsLoading, isDisabled}) => {
    const dispatch = useDispatch(),
        authToken = useSelector(authTokenSelector);

    let subscriptions: Subscription[] = [];

    useEffect(() => {
        return() => {
            if (subscriptions) {
                subscriptions.forEach(subscription => {
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                });
            }
        }
    }, []);

    const resendInquiryEmail = () => {
        if (!authToken) return;

        setIsLoading(true);
        return subscriptions.push(
            sendInquiryEmailAPI(authToken as string, inquiryId).pipe(
                switchMap(response => {
                    setIsLoading(false);
                    return of(dispatch(addAlert({message: 'inquiries.inquiryList.viewModal.inquiryEmailSent'})));
                }),
                catchError((error:any) => {
                    setIsLoading(false);
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    return of(dispatch(addAlert(errorObj)));
                })
            ).subscribe()
        )
    }

    return (
        <button className="btn btn-action" onClick={resendInquiryEmail} disabled={isDisabled}>
            <Translation text={'inquiries.inquiryList.viewModal.sendInquiryEmail'}/>
        </button>
    );
};
export default ResendInsuranceEmailButton;
