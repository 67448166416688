import {
    FormControlType,
    IFormConfig,
    InputType,
    ValidationRules,
} from 'educat-common-web';

export const registrationFormConfig: typeof IFormConfig = (value? : any) => ({
    controlType: 'form',
    class: 'onboarding-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    touched: true,
    controls: [
        {
            key: 'registration_name',
            controlType: 'group',
            class: 'row',
            touched: true,
            controls: {
                firstName: {
                    touched: true,
                    controlType: 'control',
                    defaultValue: (value && value['firstName']) ? value['firstName'] : null,
                    value: (value && value['firstName']) ? value['firstName'] : null,
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.firstName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-row form-row-edit',
                    class: 'test'
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: (value && value['lastName']) ? value['lastName'] : null,
                    value: (value && value['lastName']) ? value['lastName'] : null,
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.lastName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-row form-row-edit',
                },
                email: {
                    controlType: 'control',
                    defaultValue: (value && value['user']['login']) ? value['user']['login'] : null,
                    value: (value && value['user']['login']) ? value['user']['login'] : null,
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}}
                    ],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-6 form-row form-row-edit'
                },
                phone: {
                    controlType: 'control',
                    defaultValue: (value && value['phone']) ? value['phone'] : null,
                    value: (value && value['phone']) ? value['phone'] : null,
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'auth.formControls.placeholder',
                    isLabelHidden: false,
                    label: 'auth.formControls.phone',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-row form-row-edit',
                },
            }
        },
    ]
});
