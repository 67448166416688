import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAlertManagerService} from "../../service/alertManagerService";


interface IConnectedTutorsProps {}

interface IExternalTutorsProps {}

interface ITutorsProps extends IConnectedTutorsProps,
    IExternalTutorsProps,
    RouteComponentProps {
}

interface ITutorsState {}


class Tutors extends React.Component<ITutorsProps, ITutorsState> {
    private subscription: Subscription | null = null;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: ITutorsProps) {
        super(props);

        this.state = {};

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentWillUnmount() {
        if (null !== this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    render() {
        return (
            <div>
                <div className="breadcrumbs">
                    <i className="feather icon-home" />
                    <p>Aplikacja na studia</p>
                </div>

                <div>
                    Content
                </div>
            </div>
        );
    }
}

export default withRouter(Tutors);
