import React, {useEffect} from 'react';
import {addAlert, authTokenSelector, AlertObject, handleApiError, Translation} from 'educat-common-web';
import {from, of, Subscription} from "rxjs";
import {useDispatch, useSelector} from "react-redux";
import {saveAs} from "file-saver";

interface IDownloadInsuranceButtonProps {
    inquiryId: string;
    setIsLoading: (isLoading: boolean) => void;
    isDisabled: boolean;
}

const DownloadInsuranceButton: React.FC<IDownloadInsuranceButtonProps> = (props: IDownloadInsuranceButtonProps) => {
    const authToken = useSelector(authTokenSelector),
        dispatch = useDispatch();

    let subscription: Subscription | null = null;

    useEffect(() => {
        return() => {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }, []);


    const downloadInquiryPDF = () => {
        subscription = from(fetchPdfBlob(
            authToken as string,
            `${process.env.REACT_APP_API_URL}/inquiry/${props.inquiryId}/to-pdf`
        )).subscribe();
    };

    const fetchPdfBlob = (authToken: string, url: string) => {
        props.setIsLoading(true);
        return fetch(url, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/pdf',
                'Accept': 'application/pdf',
            },
        }).then(response => response.blob())
            .then(blob => {
                props.setIsLoading(false);
                saveAs(blob, `insurance-${props.inquiryId}.pdf`);
            })
            .catch(error => {
                props.setIsLoading(false);
                const errorObj = handleApiError(error) as typeof AlertObject;
                return of(dispatch(addAlert(errorObj)))
            });
    };

    return (
        <button className="btn btn-action" onClick={downloadInquiryPDF} disabled={props.isDisabled}>
            <Translation text={'inquiries.inquiryList.viewModal.downloadInsurance'}/>
        </button>
    );
};
export default DownloadInsuranceButton;
