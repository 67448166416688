import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from "educat-common-web";

export function sendInquiryEmailAPI(authToken: string | null, inquiryId: string): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return educatAPI.put(
        `inquiries/${inquiryId}/send_insurance`,
        {},
        new RestQueryParams(),
        headers
    );
}