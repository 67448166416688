import {FileType, Form, IFormConfig} from "educat-common-web";
import React from "react";
import {Subscription} from "rxjs";
import {MentorRegistrationSteps} from "../../../../../service/mentorRegistrationService";
import {
    mentorBasicInformationFormConfig
} from "./mentorBasicInformationFormConfig";

interface IConnectedFormStepBasicInformationProps {
}

interface IExternalFormStepBasicInformationProps {
    readonly submitStep?: (stepName: MentorRegistrationSteps, stepValue: any) => void;
    readonly stepData?: any;
    readonly languageList?: { [key: string]: any }[];
    readonly isEdited?: boolean;
    readonly mentor: any;
    readonly onFormUpdate: any;
    readonly updateValidation: any;
}

interface IFormStepBasicInformationProps extends IConnectedFormStepBasicInformationProps, IExternalFormStepBasicInformationProps {
}

interface IFormStepBasicInformationState {
    isStepValid: boolean;
    isProcessing: boolean;
    stepValue: any;
    userImageId: string | null;
    userImageFile: typeof FileType | null;
    stepName: MentorRegistrationSteps;
    formConfig: typeof IFormConfig;
}

class FormBasicInformation extends React.Component<IFormStepBasicInformationProps, IFormStepBasicInformationState> {
    readonly subscriptions: Subscription[] = [];

    constructor(props: IFormStepBasicInformationProps) {
        super(props);

        this.state = {
            isStepValid: false,
            isProcessing: false,
            formConfig: mentorBasicInformationFormConfig(this.props.languageList, this.props.mentor),
            stepValue: null,
            userImageId: null,
            userImageFile: null,
            stepName: MentorRegistrationSteps.BASIC_INFORMATION,
        };
    }

    componentDidMount() {
        if (this.props.stepData) {
            const userImageId = this.props.stepData?.userImageId,
                userImageFile = this.props.stepData?.userImageFile;
            const updatedFormConfig = mentorBasicInformationFormConfig(this.props.languageList, this.props.stepData);
            this.setState({
                formConfig: updatedFormConfig,
                stepValue: this.props.stepData,
                userImageId: userImageId,
                userImageFile: userImageFile
            });
        }
        this.setState({
            stepValue: this.state.stepValue
        });
    }

    componentDidUpdate(
        prevProps: Readonly<IFormStepBasicInformationProps>,
        prevState: Readonly<IFormStepBasicInformationState>,
        snapshot?: any
    ): void {
        if (this.state.stepValue && this.state.userImageId !== prevState.userImageId) {
            let updatedStepValue = Object.assign(this.state.stepValue, {
                userImageId: this.state.userImageId ? this.state.userImageId : null,
                userImageFile: this.state.userImageFile ? this.state.userImageFile : null,
            });
            this.setState({stepValue: updatedStepValue});

        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Form
                config={this.state.formConfig}
                controlName={MentorRegistrationSteps.BASIC_INFORMATION}
                onValueStateChange={this.formChangeHandler}
                onValidationStateChange={this.formValidityChange}
                value={this.state.stepValue}/>
        );
    }

    private formChangeHandler = (controlName: MentorRegistrationSteps, value: any, changeType: string) => {
        // if (changeType !== "init") {
            let updatedStepValue = Object.assign(value, {
                userImageId: this.state.userImageId ? this.state.userImageId : null,
                userImageFile: this.state.userImageFile ? this.state.userImageFile : null,
            });
            this.setState({stepName: controlName, stepValue: updatedStepValue});
            this.props.onFormUpdate(value, 'basic_information');
        // }
    };
    private formValidityChange = (controlName: string, isValid: boolean) => {
        if (this.state.isStepValid !== isValid) {
            this.setState({isStepValid: isValid});
            this.props.updateValidation(isValid);
        }
    };
}

export default FormBasicInformation;
