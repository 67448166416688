import {Observable} from 'rxjs';
import {educatAPI, RestQueryParams} from 'educat-common-web';


export function updateServiceDefinitionPriceAPI(authToken: string, serviceDefinitionId: string, payload: any): Observable<any> {
    return educatAPI.put(
        `service_definitions/update_price/${serviceDefinitionId}`,
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`
        }
    );
}
